export class Reserva {
    constructor(
        public codigob:string,
        public codigouetf:string,
        public nombres: string,
        public paterno: string,
        public materno: string,
        public nivel: string,
        public curso:string,
        public emailh:string,
        public zona:string,
        public direccion:string,
        public padre:string,
        public cedula:string,
        public celular:number,
        public email:string,
        public madre:string,
        public cedulam:string,
        public celularm:number,
        public emailm:string,   
        public verificado?:boolean,     
        public sexo?:string,   
        public lugarnac?:string, 
        public wp?:string,
        public wm?:string, 
        public paralelo?:string, 
        public tutor?:string,
        public _id?: string
    ){}
}
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MaterialService, MateriaService, UserService } from '../../services/service.index';
import { Material } from '../../models/material.model';
import { User } from '../../models/user.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nuevocomunicado',
  templateUrl: './nuevocomunicado.component.html',
  styles: []
})
export class NuevocomunicadoComponent implements OnInit {
    title: string;
    public materiales: Material[] = [];
    public material= new Material('','','');
    constructor(
        public _materiaService: MateriaService,
        public _materialService: MaterialService,
        public _userService: UserService,
        public router: Router
      ) { 
        this.title = "MIS COMUNICADOS";
      }
    
      ngOnInit() {
          this.cargarMaterial();
      }
      cargarMaterial(){
        this._materialService.cargarMaterialsinlimite( )
        .subscribe( (resp: any) => {
          this.materiales = resp.material;
          console.log(this.materiales);
        })
      }
      guardarMaterial( material: Material){
        //this.material.personal = this.personal[0]._id;
        this.material.curso = material.curso;
        this.material.materia = material.materia;
        this._materialService.crearMaterial( this.material )
          .subscribe( resp => {
            this.cargarMaterial();
          });
      }
    
}
import { Component, OnInit } from '@angular/core';

declare function customInitFunction(); 

@Component({
  selector: 'app-ppff',
  templateUrl: './ppff.component.html',
  styles: []
})
export class PpffComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    customInitFunction();
  }

}

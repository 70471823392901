import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { UserService } from '../service.index';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardGuard implements CanActivate, CanLoad {

  constructor(
    private _userService: UserService,
    private router: Router
  ){}


  canActivate() {
    return this._userService.validarToken()
      .pipe(
        tap( valid => {
          if( !valid ) {
            this.router.navigateByUrl('/login');
          }          
        })
      );
    /*if( this._userService.estarLogueado() ){
      //console.log('paso el guard');
      
    }else{
      //console.log('bloqueado por el guard');
      this.router.navigate(['/login']);
      return false;
    }*/
  }
  canLoad(){
    //console.log('canLoad');
    return this._userService.validarToken()
    .pipe(
      tap( valid => {
        if( !valid ) {
          this.router.navigateByUrl('/login');
        }          
      })
    );
  }
  
}

import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({ name: 'desorden' })
export class DesordenPipe implements PipeTransform {

  transform(value: any[], cantidad:number=0): any[] {
      let temp;
      let min = 0;
      let r = Math.floor(Math.random() *(cantidad - min)) +min;
      for(let i=min;i<=cantidad;i++){
          console.log(r);
      }

    let a: any[] ;
    
    a =  value.sort(function(){return Math.random() - 0.5});
    console.log(a);
    return orderBy(a);
  }
}
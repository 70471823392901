import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';

//import { ZoomMtg } from '@zoomus/websdk';
import { UserService } from 'src/app/services/service.index';
import { ActivatedRoute } from '@angular/router';
import { User } from 'src/app/models/user.model';

/*ZoomMtg.setZoomJSLib('https://source.zoom.us/1.7.7/lib', '/av');
ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();*/

@Component({
  selector: 'app-claseenvivostd',
  templateUrl: './claseenvivostd.component.html',
  styles: []
})
export class ClaseenvivostdComponent implements OnInit {

  signatureEndpoint = 'https://tomasfrias.com'
  apiKey = '3gsHUOKaSrmA6Acg4cF8hg';
  meetingNumber = 0;
  role = 0;
  leaveUrl = 'https://tomasfrias.com/plataforma';
  userName : string;
  userEmail : string;
  passWord : number;
  identificador : number;
  public usuario = new User('','','','');

  constructor(
    public httpClient: HttpClient, 
    @Inject(DOCUMENT) document,
    public _userService: UserService,
    public activatedRoute: ActivatedRoute,
  ) {
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
      this.meetingNumber = params['sala'];
      this.passWord = params['clave'];
    });
    this.usuario = this._userService.user;
    this.userName = this.usuario.nombres + ' ' + this.usuario.paterno;
    this.userEmail = this.usuario.email;
  }

  ngOnInit() {
    
  }

  /*getSignature() {
    
    this._userService.enviarZoom( this.role, this.meetingNumber )
        .subscribe( (resp: any) => {
          console.log(resp);
          this.startMeeting(resp);
        })
  }

  startMeeting(signaturee) {

    document.getElementById('zmmtg-root').style.display = 'block'

    ZoomMtg.init({
      leaveUrl: this.leaveUrl,
      isSupportAV: true,
      success: (success) => {
        console.log(success)

        ZoomMtg.join({
          signature: signaturee,
          meetingNumber: this.meetingNumber,
          userName: this.userName,
          apiKey: this.apiKey,
          userEmail: this.userEmail,
          passWord: this.passWord,
          success: (success) => {
            console.log(success)
          },
          error: (error) => {
            console.log(error)
          }
        })

      },
      error: (error) => {
        console.log(error)
      }
    })
  }*/
}

import { Component, OnInit } from '@angular/core';
import { MaterialService, VirtualService } from '../../services/service.index';
import { Material } from '../../models/material.model';
import { Virtual } from '../../models/virtual.model';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-plananual',
  templateUrl: './plananual.component.html',
  styles: []
})
export class PlananualComponent implements OnInit {
  title: string;
  public material= new Material('','','');
  materiale: Material[]=[];
  virtual: Virtual[] = [];  
  identificador: string;
  public vir = new Virtual('','',0);
  uno=1;
  dos=2;
  tres=3;

  constructor(
    public _materialService: MaterialService,
    public _virtualService: VirtualService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
  ) {
    this.material= new Material('','','');
    this.title = "Plan Anual Trimestralizado";
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
    });
   }

  ngOnInit() {
    this.cargarMaterial();
    this.cargarVirtual();
  }
  cargarVirtual(){
    this._virtualService.cargarVirtualSin( this.identificador )
        .subscribe( (resp: any) => {
          this.virtual = resp;          
        })
  }
  cargarMaterial(){
    this._materialService.cargarAulaId( this.identificador )
    .subscribe( (resp: any) => {
      this.material = resp.material;
      this.materiale = resp.material;
    })
  }
  guardarMaterial( material: Material){
    this._materialService.modificarPlan( this.identificador, material )
      .subscribe( resp => {
        this.cargarMaterial();
      });
  }
  guardarTema1( virtual: Virtual ){
    this.vir.material = this.identificador;
    this.vir.titulo = virtual.titulo;
    this.vir.bimestre = this.uno;
    this._virtualService.crearVirtual( this.vir )
      .subscribe( resp => {
        this.cargarVirtual();  
      });
  }
  guardarTema2( virtual: Virtual ){
    this.vir.material = this.identificador;
    this.vir.titulo = virtual.titulo;
    this.vir.bimestre = this.dos;
    this._virtualService.crearVirtual( this.vir )
      .subscribe( resp => {
        this.cargarVirtual();  
      });
  }
  guardarTema3( virtual: Virtual ){
    this.vir.material = this.identificador;
    this.vir.titulo = virtual.titulo;
    this.vir.bimestre = this.tres;
    this._virtualService.crearVirtual( this.vir )
      .subscribe( resp => {
        this.cargarVirtual();
      });
  }
  borrarTema( virtual: Virtual ){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de borrar: '+virtual.titulo,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._virtualService.borrarVirtual( virtual._id )
              .subscribe( material => {
                this.cargarVirtual();
              });
      }
    })
  }
  pdcWork(id:string){
    this.router.navigate(['/institucion/pdc', this.identificador, id]);
  }

}

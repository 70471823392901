import { Component, OnInit } from '@angular/core';
import { Personal } from '../../models/personal.model';
import { PersonalService } from '../../services/service.index';
import { Router, ActivatedRoute } from "@angular/router";

import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista',
  templateUrl: './lista.component.html',
  styles: []
})
export class ListaComponent implements OnInit {
  title: string;
  limite: number = 0;
  desde: number = 0;
  totalRegistros: number = 0;
  cargando: boolean = true;
  personal: Personal[] = [];

  constructor(
    public router: Router,
    public activateRoute: ActivatedRoute,
    public _personalService: PersonalService
  ) { 
    this.title = "LISTA DE DOCENTES";
  }

  ngOnInit() {
    this.cargarPersonal();
  }
  cargarPersonal(){
    this._personalService.cargarPersonal( this.desde )
        .subscribe( (resp: any) => {
          this.totalRegistros = resp.cuantos;
          this.personal = resp.personal;
          this.cargando = false;
        })
  }
  cambiarDesde( valor: number ){
    let desde = this.desde + valor;
    if( desde >= this.totalRegistros ){
      return;
    }
    if( desde < 0 ){
      return;
    }
    this.desde += valor;
    this.cargarPersonal();
  }

  bajaPersonal( personal: Personal ){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de dar de baja a: '+personal.user['nombres'],
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._personalService.bajaPersonal( personal._id )
              .subscribe( personal => {
                this.cargarPersonal();
              });
      }
    })
  }

  altaPersonal( personal: Personal ){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de dar de alta a: '+personal.user['nombres'],
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._personalService.altaPersonal( personal._id )
              .subscribe( personal => {
                this.cargarPersonal();
              });
      }
    })
  }

  passPersonal( id: string ){
    this.router.navigate(['/institucion/pass', id]);
  }
  asignarHorario(personal: Personal){
    this.router.navigate(['/institucion/horario-docente', personal._id]);
  }

  estadistica(personal: Personal){
    this.router.navigate(['/institucion/estadistica', personal._id]);
  }

}

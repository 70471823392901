import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { PPFF_ROUTES } from './ppff.routes';

import { SharedModule } from '../shared/shared.module';

import { PpffComponent } from './ppff.component';



import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PipesModule } from '../pipes/pipes.module';
import { MisHijosComponent } from './mis-hijos/mis-hijos.component';
import { CursoHijoComponent } from './curso-hijo/curso-hijo.component';



@NgModule({
  declarations: [
    PpffComponent,
    MisHijosComponent,
    CursoHijoComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    PPFF_ROUTES,
    PipesModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    PpffComponent
  ]
})
export class PpffModule { }

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Tareaemitida } from "../../models/tareaemitida.model";
import { MaterialService, MateriaService, VirtualService } from '../../services/service.index';
import { Material } from '../../models/material.model';
import { Criterio } from '../../models/criterio.model';
import { Comunicado } from '../../models/comunicado.model';

import Swal from 'sweetalert2';


@Component({
  selector: 'app-trabajo',
  templateUrl: './trabajo.component.html',
  styles: []
})
export class TrabajoComponent implements OnInit {

  limite: number = 0;
  desde: number = 0;
  totalRegistros: number = 0;
  identificador: string;
  identificador2: string;
  tareas: Tareaemitida[]=[];
  tarea: Tareaemitida;
  tare: Tareaemitida;
  title: string;
  material: Material[]=[];
  public criterio : Criterio[] = [];
  public criterio2 = [];
  bimestre:string;
  public comunicado = new Comunicado('','','','');

  constructor(
    public _virtualService: VirtualService,
    public activatedRoute: ActivatedRoute,
    public _materialService: MaterialService,
    public _materiaService: MateriaService,
    public router: Router
  ) {
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
      this.bimestre = params['bim'];
      this.identificador2 = params['id2'];
    });
    this.tare=new Tareaemitida('','','');
    this.tarea=new Tareaemitida('','','');
    this.title = "Tareas";
   }

  ngOnInit() {
    this.cargarTareas(this.identificador);
    this.cargarDatos(this.identificador2);    
  }
  cargarDatos(id: string){
    this._materialService.cargarAulaId( id )
        .subscribe( (resp: any) => {
          this.material = resp.material;          
          this.cargarCriterios();
        })
  }

  cargarTareas( id: string ){
    this._virtualService.cargarTareas( this.desde, id )
    .subscribe( (resp: any) => {
      this.totalRegistros = resp.cuantos;
      this.tareas = resp.tarea;
    })
  }

  cambiarDesde( valor: number ){
    let desde = this.desde + valor;
    if( desde >= this.totalRegistros ){
      return;
    }
    if( desde < 0 ){
      return;
    }
    this.desde += valor;
    this.cargarTareas( this.identificador);
  }

  guardarTarea( tarea: Tareaemitida ){
    this.tarea.titulo = tarea.titulo;
    this.tarea.virtual = this.identificador;
    this.tarea.presentacion = tarea.presentacion+' '+tarea.hora;
    this.tarea.hora = tarea.hora;
    this.tarea.plantilla = tarea.plantilla;
    this.tarea.criterio = tarea.criterio;
    this.tarea.minimo = tarea.minimo;
    //console.log(this.tarea);
    this._virtualService.crearTarea( this.tarea ).subscribe( tarea => {  
      this.cargarTareas(this.identificador);
    });
  }
  seleccion(id:string){
    var obj = new Object();
    this.criterio2 = [];
    this.tare.criterio='';
    for(let i=0;i<this.criterio.length;i++){
      if(this.criterio[i].dimension[0]==id && this.criterio[i].trimestre[0]==this.bimestre ){
        obj = {
          _id : this.criterio[i]._id,
          criterio : this.criterio[i].criterio,
          dimension : this.criterio[i].dimension[0],
          trimestre : this.criterio[i].trimestre[0]
        };
        this.criterio2.push(obj);
      }
    }
  }
  
  cargarCriterios(){
    this._materiaService.cargarCriterio( this.material['curso']['_id'], this.material['materia']['_id'] )
      .subscribe( (resp: any) => {
        this.criterio = resp;
      })
  }

  tareaOffline(tarea: Tareaemitida){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de sacar de línea la tarea: '+tarea.titulo,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._virtualService.offlineTarea( tarea._id )
          .subscribe( tarea => {
            this.cargarTareas(this.identificador);
          });
      }
    })
  }
  tareaOnline(tareaa: Tareaemitida){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de poner en línea la tarea: '+tareaa.titulo,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._virtualService.onlineTarea( tareaa._id )
          .subscribe( tarea => {
            this.guardarComunicado(tareaa.presentacion);
            this.cargarTareas(this.identificador);            
          });
      }
    })
  }
  guardarComunicado(fecha:string){
    let titulo = "Nueva Tarea "+this.material['materia']['nombre'];
    let comunicado = "Se ha publicado una nueva tarea en la materia de: "+this.material['materia']['nombre'];    
    this.comunicado.personal = this.material['personal'];
    this.comunicado.material = this.material['_id'];
    this.comunicado.curso = this.material['curso']['_id'];
    this.comunicado.titulo = titulo;
    this.comunicado.comunicado = comunicado;
    this.comunicado.url = "";
    this.comunicado.prioridad = 4;
    this.comunicado.destino = "stud";
    this.comunicado.inicio = fecha;
    this.comunicado.fin = fecha;
    this._materialService.crearComunicado( this.comunicado )
    .subscribe( resp => {
      this.cargarTareas(this.identificador);
    });     
  }

  borrarTarea( tarea: Tareaemitida ){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de borrar: '+tarea.titulo,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._virtualService.borrarTarea( tarea._id )
          .subscribe( tarea => {
            this.cargarTareas(this.identificador);
          });
      }
    })
  }

  tareaMod( id: string ){
    this.router.navigate(['/institucion/tarea-modificar', id,this.bimestre,this.identificador2]);
  }
  revisionTarea(id: string, nota: number){
    if(nota>=0){
      this.router.navigate(['/institucion/revision-tarea', this.identificador2, this.identificador,id,nota]);
    }else{
      let a=1;
      this.router.navigate(['/institucion/revision-tarea', this.identificador2, this.identificador,id,a]);
    }    
  }

}

import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../services/service.index';

import { User } from '../models/user.model';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent implements OnInit {
  alerta: boolean = false;
  mensaje: string;

  constructor(
    public router: Router,
    public _userService: UserService
  ) { }

  ngOnInit() {
  }

  ingresar( forma: NgForm ){
    if( forma.invalid ){
      return;
    }

    let user = new User(null,null,forma.value.email,forma.value.password);

    this._userService.login(user)
              .subscribe((resp:any) => {
                if(resp.ok){
                  this.alerta = false;
                  this.router.navigate(['/plataforma/dashboard']);
                }else{
                  this.alerta = true;
                  this.mensaje = resp.message;
                }
              });
  }

}

import { Component, OnInit } from '@angular/core';
declare function customInitFunction();
declare function init_plugins();

@Component({
  selector: 'app-estudiante',
  templateUrl: './estudiante.component.html',
  styles: []
})
export class EstudianteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    customInitFunction();
    init_plugins();
  }

}

import { RouterModule, Routes } from "@angular/router";
import { Input } from '@angular/core';

import { Institucion } from "../models/institucion.model";

import { ProfesorComponent } from './profesor.component';


import { LoginGuardGuard } from '../services/service.index';
import { ListaComponent } from 'src/app/profesor/lista/lista.component';
import { CursoComponent } from './curso/curso.component';
import { ListacursoComponent } from './curso/listacurso.component';
import { MateriaComponent } from './materia/materia.component';
import { ListamateriaComponent } from './materia/listamateria.component';
import { TutorComponent } from './lista/tutor.component';
import { MiMateriaComponent } from './lista/mi-materia.component';
import { ListaAulaComponent } from './aula/lista-aula/lista-aula.component';
import { DesarrolloComponent } from './aula/lista-aula/desarrollo.component';
import { MiCursotutorComponent } from './lista/mi-cursotutor.component';
import { ConfiguracionComponent } from './institucion/configuracion/configuracion.component';
import { PassComponent } from './institucion/pass.component';
import { TrabajoComponent } from './trabajo/trabajo.component';
import { ModtareaComponent } from './trabajo/modtarea.component';
import { EvaluacionComponent } from './trabajo/evaluacion.component';
import { ModexamenComponent } from './trabajo/modexamen.component';
import { NominacomplementarioComponent } from './curso/nominacomplementario.component';
import { CursolistacomplementarioComponent } from './curso/cursolistacomplementario.component';
import { ClaseenvivoComponent } from './aula/lista-aula/claseenvivo.component';
import { NuevocomunicadoComponent } from './comunicado/nuevocomunicado.component';
import { RevisiontareaComponent } from './trabajo/revisiontarea.component';
import { CalificartareaComponent } from './trabajo/calificartarea.component';
import { RevisionevaluacionComponent } from './trabajo/revisionevaluacion.component';
import { PlananualComponent } from './lista/plananual.component';
import { PlanclaseComponent } from './lista/planclase.component';
import { GaleriaComponent } from "./trabajo/galeria.component";
import { CuadernoComponent } from "./trabajo/cuaderno.component";
import { TutorialesComponent } from './institucion/tutoriales/tutoriales.component';
import { VertutorialComponent } from './institucion/tutoriales/vertutorial.component';
import { CriterioComponent } from "./trabajo/criterio.component";
import { SerComponent } from "./trabajo/ser.component";
import { HacerComponent } from "./trabajo/hacer.component";
import { SaberComponent } from "./trabajo/saber.component";
import { DecidirComponent } from "./trabajo/decidir.component";
import { NuevodesarrolloComponent } from "./aula/lista-aula/nuevodesarrollo.component";
import { IngresoclaseComponent } from "./lista/ingresoclase.component";
import { HorarioComponent } from "./lista/horario.component";
import { CargarCuadernoComponent } from './trabajo/cuadernopedagogico/cargar-cuaderno.component';
import { EstadisticaComponent } from "./lista/estadistica.component";
import { EstadisdescComponent } from "./lista/estadisdesc.component";



const profesorRoutes: Routes = [
    {
        path: '', 
        component: ProfesorComponent,  
        canActivate: [ LoginGuardGuard ],    
        canLoad: [LoginGuardGuard],                       
        children: [
            //{path: ':nombre', component: PrincipalComponent}, 
            {path: 'lista-docentes', component: ListaComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'ingreso-clase', component: IngresoclaseComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'horario-docente/:id', component: HorarioComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'tutoriales-docentes', component: TutorialesComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'ver-tutoriales', component: VertutorialComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'crear-curso', component: CursoComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'lista-cursos', component: ListacursoComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'crear-materia', component: MateriaComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'lista-materias', component: ListamateriaComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'mis-tutorias', component: TutorComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'mis-materias', component: MiMateriaComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'mis-temas/:id', component: ListaAulaComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'aula-virtual/:id/:material', component: DesarrolloComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'nueva-aula-virtual/:id/:material', component: NuevodesarrolloComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'mi-curso-tutoria/:id', component: MiCursotutorComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'mi-institucion', component: ConfiguracionComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'pass/:id', component: PassComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'tarea/:id/:bim/:id2', component: TrabajoComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'tarea-modificar/:id/:bim/:clase', component: ModtareaComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'evaluacion-programacion/:id/:bim/:id2', component: EvaluacionComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'evaluacion-modificar/:id/:virtual/:id2', component: ModexamenComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'nomina-cursos-uetf', component: NominacomplementarioComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'curso-lista-complement/:id', component: CursolistacomplementarioComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'clase-en-vivo-profesor/:id/:sala/:clave', component: ClaseenvivoComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'comunicado-nuevo', component: NuevocomunicadoComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'revision-tarea/:id/:virtual/:tarea/:nota', component: RevisiontareaComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'revision-evaluacion/:id/:virtual/:tarea', component: RevisionevaluacionComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'calificar-tarea/:idrev/:id/:virtual', component: CalificartareaComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'plan-anual/:id', component: PlananualComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'pdc/:id/:virtual', component: PlanclaseComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'galeria', component: GaleriaComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'calificaciones/:id', component: CuadernoComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'criterio/:id', component: CriterioComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'ser/:id', component: SerComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'hacer/:id', component: HacerComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'saber/:id', component: SaberComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'decidir/:id', component: DecidirComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'cargar-cuaderno-pedagogico/:curso/:materia/:material', component: CargarCuadernoComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'estadistica/:id', component: EstadisticaComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'descripcion-estadistica/:id', component: EstadisdescComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: '', redirectTo: '/lista-docentes', pathMatch: 'full'}
            
        ]
    }
];

export const PROFESOR_ROUTES = RouterModule.forChild( profesorRoutes );
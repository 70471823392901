export class Comunicado {
    constructor(
        public personal: string,
        public comunicado: string, 
        public material?: string,
        public curso?: string,   
        public created?: string,
        public updated?: string,
        public titulo?: string,
        public url?: string,
        public disponible?: boolean,   
        public destino?: string,
        public prioridad?: number,
        public inicio?:  string,
        public fin?:  string,  
        public _id?: string
    ){}
}

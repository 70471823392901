import { Component, OnInit } from '@angular/core';
import { UserService, PersonalService } from '../../../services/service.index';
import { User } from '../../../models/user.model';
import { Router } from '@angular/router';
import { Personal } from '../../../models/personal.model';

@Component({
  selector: 'app-listappff',
  templateUrl: './listappff.component.html',
  styles: []
})
export class ListappffComponent implements OnInit {

  title: string;
  usuarios: User[] = [];
  limite: number = 0;
  desde: number = 0;
  totalRegistros: number = 0;
  cargando: boolean = true;
  public persona = new Personal('', '', '', '');

  public hijo: User = new User('','','','');
  public generador: string;
  public usuario = new User('','','','');
  public padre: User;
  public personal = new Personal('','','','');

  constructor(
    public _userService: UserService,
    public _personalService: PersonalService,
    public router: Router
  ) {
    this.title = "Listas generales U.E.T.F.";
    this.generador = Math.random().toString(36).substr(2, 6);
    this.hijo = new User('','',this.generador+'@tomasfrias.com',this.generador);
   }

   ngOnInit() {
    this.cargarUsuarios();
    this.usuario = this._userService.user;
  }

  cargarUsuarios(){
    this._userService.cargarUserioppff( this.desde )
        .subscribe( (resp: any) => {
          this.totalRegistros = resp.cuantos;
          this.usuarios = resp.usuarios;
          //console.log(this.totalRegistros);
          this.cargando = false;
          //console.log(this.usuarios);
        })
  }

  cambiarDesde( valor: number ){
    let desde = this.desde + valor;
    if( desde >= this.totalRegistros ){
      return;
    }
    if( desde < 0 ){
      return;
    }
    this.desde += valor;
    this.cargarUsuarios();
  }

  buscarUser( termino: string ){
    if(termino.length <= 0){
      this.cargarUsuarios();
      return;
    }
    this.cargando = true;
    this._userService.buscarUser( termino )
    .subscribe((user: User[] ) => {
          this.usuarios = user;
          this.cargando = false;
        })
  }
  verHijo( id: string ){
    this._personalService.cargarPersonadesdeuser( id )
        .subscribe( (resp: any) => {
          this.persona = resp.personal;
          this.router.navigate(['/plataforma/lista-dependencia-hijo', this.persona[0]._id, id]);
        })
  }
  guardarPadre( hijo: User ){
    this.hijo.nombres = hijo.nombres;
    this.hijo.paterno = hijo.paterno;
    this.hijo.materno = hijo.materno;
    this.hijo.cedula = hijo.cedula;
    this.hijo.celular = hijo.celular;
    this.hijo.zona = hijo.zona;
    this.hijo.calle = hijo.calle;
    this.hijo.sexo = hijo.sexo;
    this.hijo.estadocivil = hijo.estadocivil;
    this.hijo.ocupacion = hijo.ocupacion;
    this.hijo.instruccion = hijo.instruccion;
    this.hijo.parentezco = hijo.parentezco;
    this.hijo.email = hijo.email;
    this.hijo.password = hijo.password;
    this.hijo.institucion = this.usuario.institucion['_id'];    
    this._userService.crearUserInstitucion( this.hijo ).subscribe( resp => {    
      this.padre = resp;
      this.personal.user = this.padre._id;
      this.personal.institucion = this.padre.institucion;
      this.personal.role = "1";
      this._personalService.crearPersonal( this.personal )
      .subscribe( resp => {
        this.verHijo(this.padre._id);
      });
    });
  }
}

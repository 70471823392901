import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/service.index';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {
  public usuario = new User('','','','');

  constructor(
    public _userService: UserService
  ) { }

  ngOnInit() {
    this.usuario = this._userService.user;
  }

}

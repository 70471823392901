import { Component, OnInit } from '@angular/core';
import { Material } from '../../models/material.model';
import { UserService, MaterialService } from '../../services/service.index';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-estudiante-materia',
  templateUrl: './estudiante-materia.component.html',
  styles: []
})
export class EstudianteMateriaComponent implements OnInit {
  curso :string;
  material: Material[]=[];
 

  constructor(
    public _userService: UserService,
    public _materialService: MaterialService,
    public router: Router
  ) { 
    let t = this._userService.personal[0].gestiones.length - 1;
    //if(this._userService.personal[0].gestiones.length > 0) {}
    this.curso =  this._userService.personal[0].gestiones[t].cursos;
  }

  ngOnInit() {
    this.cargarMaterias();
    //this.mensaje();
  }

  cargarMaterias(){
    this._materialService.cargarMaterialEstudiante( this.curso )
        .subscribe( (resp: any) => {          
          this.material = resp.material;
        })
  }

  cargarTemas( id: string, materia:string ){
    this.router.navigate(['/estudiante/contenidos-estudiante', id, materia]);
  }
  /*cargarTemas( id: string ){
    this.router.navigate(['/estudiante/temas-disponibles', id]);
  }*/
  
  mensaje(){
    Swal.fire({
      title: 'Distinguido ppff',
      text: 'Le recordamos que si no tiene sus tres mensualidades canceladas en el banco BISA hasta el 17 de abril. Sus hijos o dependientes, no podrán acceder a la plataforma SAVTLE a partir del lunes 19 del  presente. Evite que sus hijos pasen por esta situación incómoda     CETF R.L.',
      icon: 'warning',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Entendido!'
    })
  }

}

export class Ventanilla {
    constructor(
        public institucion?: string,
        public registradox?: string,
        public tipocorrespondencia?:string,
        public tipodocumento?:string,
        public fechacorrespondencia?:string,
        public documento?:string,
        public referencia?:string,
        public destinatario?:string,
        public remitente?:string,
        public fecharegistro?:string,
        public respuesta?:string,
        public prioridad?:number,
        public cerrado?:boolean,
        public _id?: string
    ){}
}
import { Component, OnInit } from '@angular/core';
import { Tutorial } from '../../../models/tutorial.model';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MaterialService, UserService } from '../../../services/service.index';
import { Personal } from '../../../models/personal.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tutoriales',
  templateUrl: './tutoriales.component.html',
  styles: []
})
export class TutorialesComponent implements OnInit {
  public tutorial = new Tutorial('','','','');
  personal: Personal;
  title: string;

  constructor(
    public _materialService: MaterialService,
    public _userService: UserService,
  ) {
    this.title = "TUTORIALES"
    this.personal = this._userService.personal[0];
   }

  ngOnInit() {
    //console.log(this.personal);
  }
  config2: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '3rem',
    placeholder: 'Escriba el comunicado aqui...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['insertVideo'],
      ['insertImage'],
      ['backgroundColor'],
      ['toggleEditorMode']
    ],
  };
  guardarTutorial( tutorial: Tutorial){
    this.tutorial.personal = this.personal._id;
    this.tutorial.titulo = tutorial.titulo;
    this.tutorial.comunicado = tutorial.comunicado;
    this.tutorial.url = tutorial.url;
    this.tutorial.destino = tutorial.destino;
    this.tutorial.inicio = tutorial.inicio;
    if(this.tutorial.comunicado.indexOf('<img') >= 0){
      Swal.fire('No se ha podido guardar', 'Imagenes o videos cargados, borre la imagen y/o video. No se puede publicar multimedia en los comunicados', 'error');
    }else{
      this._materialService.crearTutorial( this.tutorial )
      .subscribe( resp => {
        console.log('');
      }); 
    }
  }

}

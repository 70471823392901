import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from '../../config/config';
import { Material } from "../../models/material.model";

import { map, take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { User } from '../../models/user.model';
import { Personal } from '../../models/personal.model';
import { Comunicado } from '../../models/comunicado.model';
import { Tutorial } from '../../models/tutorial.model';

@Injectable({
  providedIn: 'root'
})
export class MaterialService {

  token:string;
  user: User;
  material: Material;
  personal: Personal;

  constructor(
    public http: HttpClient,
  ) { 
    this.cargarStorage();
  }

  cargarStorage(){
    if( localStorage.getItem('token') ){
      this.token = localStorage.getItem('token');
      this.user = JSON.parse( localStorage.getItem('usuario') );
      this.personal = JSON.parse( localStorage.getItem('personal') );
    }else{
      this.token = '';
    }
  }

  crearMaterial( material: Material ){
    let url = URL_SERVICIOS + '/material';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    
    return this.http.post(url, material, httpOptions).pipe(map( (resp: any) => {
        Swal.fire('¡Muy bien!', 'Asignaste un curso a tu materia', 'success');
        return resp.material;
      }));
  }
  crearComunicado( comunicado: Comunicado ){
    let url = URL_SERVICIOS + '/comunicado';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    
    return this.http.post(url, comunicado, httpOptions).pipe(map( (resp: any) => {
        Swal.fire('¡Muy bien!', 'Has creado un comunicado', 'success');
        return resp.comunicado;
      }));
  }
  crearTutorial( tutorial: Tutorial ){
    let url = URL_SERVICIOS + '/tutorialNoespecifico';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    
    return this.http.post(url, tutorial, httpOptions).pipe(map( (resp: any) => {
        Swal.fire('¡Muy bien!', 'Has creado un tutorial', 'success');
        return resp.tutorial;
      }));
  }
  cargarComunicado( material: string ){
    this.cargarStorage();
    let url = URL_SERVICIOS + '/comunicado/'+material;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }
  cargarComunicadoP( personal: string ){
    this.cargarStorage();
    let url = URL_SERVICIOS + '/comunicadoP/'+personal;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }
  cargarTutorialProfe(){
    this.cargarStorage();
    let url = URL_SERVICIOS + '/tutorialprofe';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }

  cargarTutorialEstudiante(){
    this.cargarStorage();
    let url = URL_SERVICIOS + '/tutorialestudiante';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }

  cargarComunicadoCur( curso: string ){
    this.cargarStorage();
    let url = URL_SERVICIOS + '/comunicadoCurso/'+curso;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }

  offlineComunicado( id: string ){
    let url = URL_SERVICIOS + '/comunicadoOffline/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.delete( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Comunicado Offline', 'El comunicado ya no es visible', 'success');
      return true;
    }));
  }

  cargarMaterial( ){
    this.cargarStorage();
    //console.log(this.personal[0]._id);
    let personal = this.personal[0]._id;
    //let limite = 5;
    let url = URL_SERVICIOS + '/material/'+personal;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }

  cargarMaterialEstadistica( id ){
    //this.cargarStorage();
    //console.log(this.personal[0]._id);
    let personal = id;
    //let limite = 5;
    let url = URL_SERVICIOS + '/material/'+personal;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }
  cargarMaterialsinlimite(){
    this.cargarStorage();
    //console.log(this.personal[0]._id);
    let personal = this.personal[0]._id;
    let url = URL_SERVICIOS + '/materialSinlimite/'+personal;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }

  cargarMaterialEstudiante( curso: string){
    this.cargarStorage();
    let url = URL_SERVICIOS + '/materiales/'+curso;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }

  cargarMaterialDocentes( curso: string){
    this.cargarStorage();
    let url = URL_SERVICIOS + '/materialesdocentes/'+curso;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }
  /*descargaCuaderno(filename:string){
    let url = URL_SERVICIOS + '/descargarCuaderno/'+filename;
    return this.http.get(url).pipe(map((resp: any) => resp));
  }*/

  cargarAula(id:string){
    let url = URL_SERVICIOS + '/institucion/mis-temas'+id;
    return this.http.get(url).pipe(map((resp: any) => resp.virtual));
  }
  cargarAulaId(id:string){
    this.cargarStorage();
    let url = URL_SERVICIOS + '/materialid/'+id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }
  cargarAulaIdd(id:string){
    this.cargarStorage();
    let url = URL_SERVICIOS + '/materialidd/'+id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }

  borrarMaterial( id: string ){
    let url = URL_SERVICIOS + '/material/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.delete( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Se ha eliminado', 'El curso y materia se ha eliminado', 'success');
      return true;
    }));
  }

  modificarPlan( id: string, datos: Material ){
    this.cargarStorage();
    let url = URL_SERVICIOS + '/material/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.put( url, datos, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Muy bien!!!', 'Se ha realizado el cambio', 'success');
      return true;
    }));
  }


}

export class Consulta{
    constructor(
        public _id?:string,
        public personal?:string,
        public titulo?:string,
        public descripcion?:string,
        public created?:string,
        public updated?:string,       
        public disponible?:boolean
    ){}
}
export class Materia {
    constructor(
        public institucion: string,       
        public nombre: string,
        public nivel:  string,
        public inicial?: boolean,   
        public primaria?: boolean,
        public secundaria?: boolean,
        public logo?: string,
        public _id?: string
    ){}
}

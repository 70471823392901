import { Component, OnInit } from '@angular/core';
//import { CursoService } from '../../services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import { Virtual } from '../../../models/virtual.model';
import { MaterialService, VirtualService } from '../../../services/service.index';
//import { Curso } from '../../models/curso.model';
import Swal from 'sweetalert2';
import { Material } from '../../../models/material.model';
import { Comunicado } from '../../../models/comunicado.model';
//import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AngularEditorConfig } from '@kolkov/angular-editor';
//import { Galeria } from '../../../models/galeria.model';

@Component({
  selector: 'app-lista-aula',
  templateUrl: './lista-aula.component.html',
  styleUrls: []
})
export class ListaAulaComponent implements OnInit {

  virtual: Virtual[] = [];  
  material: Material[]=[];
  mate = new Material('','','');
  material2 = new Material('','','');
  title: string;
  identificador : string;
  public vir = new Virtual('','',0);
  limite: number = 0;
  desde: number = 0;
  totalRegistros: number = 0;
  public comunicado = new Comunicado('','','','');
  public comunicados: Comunicado[] = [];
  //public Editor = ClassicEditor;
  //public galeria= new Galeria();
  //public bandera: boolean = false;
  //public imagenSubir: File;
  //public imagenTemp: any;
  


  constructor(
    public _virtualService: VirtualService,
    public activatedRoute: ActivatedRoute,
    public _materialService: MaterialService,
    //public _galeriaService: GaleriaService,
    public router: Router
  ) { 
    this.title = "Mis Temas";
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
    });
  }
  /*editorConfig = {
    toolbar: {
      items: [
        'bold',
        'italic',
        'underline',
        'bulletedList',
        'numberedList',
        '|',
        'indent',
        'outdent',
        '|',
        'blockQuote',
        'insertTable',
        'undo',
        'redo'
      ]
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells'
      ]
    },
    // This value must be kept in sync with the language defined in webpack.config.js.
    language: 'en'
  };  */

  config2: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '3rem',
    placeholder: 'Escriba el comunicado aqui...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['insertVideo'],
      ['insertImage'],
      ['backgroundColor'],
      ['toggleEditorMode']
    ],
  };


  ngOnInit() {
    this.cargarVirtual();
    this.cargarDatos();
    //this.cargarGaleria();
  }
 
  evento(e){
    if(e.indexOf('class="image"') >= 0 || e.indexOf('class=\"media\"') >= 0){
      Swal.fire('Ups!', 'Se ha detectado que esta intentando subir una imagen o video de manera inapropiada, por favor revise el tutorial', 'error');
    }
  }

  cargarVirtual(){
    this._virtualService.cargarVirtualSin( this.identificador )
        .subscribe( (resp: any) => {
          this.virtual = resp;  
          //console.log(this.virtual);     
        })
  }
  cargarDatos(){
    this._materialService.cargarAulaIdd( this.identificador )
        .subscribe( (resp: any) => {
          this.material = resp.material[0];
          this.cargarComunicados(this.material['_id']);
        })
  }
  modEnlace( v: Material){
    console.log(v);
    this.material2.zoom = (v.zoom).trim();
    this.material2.curso = this.material['curso']._id;
    this.material2.personal = this.material['personal'];
    this.material2.materia = this.material['materia']._id;
    this._materialService.modificarPlan(this.identificador, this.material2).subscribe( resp => {
      this.cargarDatos();
    });
  }

  guardarTema( virtual: Virtual ){
    this.vir.material = this.identificador;
    this.vir.titulo = virtual.titulo;
    this.vir.bimestre = virtual.bimestre;
    this._virtualService.crearVirtual( this.vir )
      .subscribe( resp => {
        this.cargarVirtual();  
      });
  }
  cargarComunicados(id:string){
    this._materialService.cargarComunicado(id)
        .subscribe( (resp: any) => {          
          this.comunicados=resp.comunicado;
        })
  }
  guardarComunicado( comunicado: Comunicado){
    this.comunicado.personal = this.material['personal'];
    this.comunicado.material = this.material['_id'];
    this.comunicado.curso = this.material['curso']['_id'];
    this.comunicado.titulo = comunicado.titulo;
    this.comunicado.comunicado = comunicado.comunicado;
    this.comunicado.url = "";
    this.comunicado.prioridad = comunicado.prioridad;
    this.comunicado.destino = "stud";
    this.comunicado.inicio = comunicado.inicio;
    this.comunicado.fin = comunicado.fin;
    if(this.comunicado.comunicado.indexOf('<img') >= 0){
      Swal.fire('No se ha podido guardar', 'Imagenes o videos cargados, borre la imagen y/o video. No se puede publicar multimedia en los comunicados', 'error');
    }else{
      this._materialService.crearComunicado( this.comunicado )
      .subscribe( resp => {
        this.cargarVirtual();
        this.cargarDatos();
      }); 
    }
  }

  aulaWork( id: string ){
    //this.router.navigate(['/institucion/nueva-aula-virtual', id,this.identificador]);
    this.router.navigate(['/institucion/aula-virtual', id,this.identificador]);
  }

  tarea( id: string, bim: number ){
    this.router.navigate(['/institucion/tarea', id,bim,this.identificador]);
  }

  evaluacion( id: string, bim: number ){
    this.router.navigate(['/institucion/evaluacion-programacion', id, bim, this.identificador]);
  }

  comunicadoOffline(comunicado: Comunicado){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de sacar de línea el comunicado: '+comunicado.comunicado,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._materialService.offlineComunicado( comunicado._id )
              .subscribe( material => {
                this.cargarVirtual();
                this.cargarDatos();
              });
      }
    })
  }

  aulaOffline(virtual: Virtual){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de sacar de línea al tema: '+virtual.titulo,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._virtualService.offlineVirtual( virtual._id )
              .subscribe( material => {
                this.cargarVirtual();
              });
      }
    })
  }
  aulaOnline(virtual: Virtual){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de poner en línea al tema: '+virtual.titulo,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._virtualService.onlineVirtual( virtual._id )
              .subscribe( material => {
                this.cargarVirtual();
              });
      }
    })
  }

  borrarTema( virtual: Virtual ){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de borrar: '+virtual.titulo,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._virtualService.borrarVirtual( virtual._id )
              .subscribe( material => {
                this.cargarVirtual();
              });
      }
    })
  }

}

export class Institucion {
    constructor(
        public nombre: string,
        public alias: string,
        public direccion: string,
        public user?: string,
        public icono?: string,
        public telf?: number,
        public cel?: number,
        public nit?: string,
        public latitud?: number,
        public longitud?: number,
        public pais?: string,
        public gestion?: string,
        public gestion_nueva?: string,
        public red?: string,
        public turno?: number,
        public distrito?: string,
        public municipio?: string,
        public departamento?: string,
        public sie?: string,
        public dirdistrital?: string,
        public dirdepartamental?: string,
        public comunidad?: string,
        public justificacion?: string,
        public objgeneral?: string,
        public objspefic?: string,
        public diagsituacional?: string,
        public apdcurricular?: string,
        public objpsp?: string,
        public objespecificopsp?: string,
        public problemapsp?: string,
        public pspactual?: string,
        public globalpsp?: string,
        public b1des?: string,
        public b2des?: string,
        public b3des?: string,
        public b4des?: string,
        public b1inicial?: string,
        public b2inicial?: string,
        public b3inicial?: string,
        public b4inicial?: string,
        public b1primaria?: string,
        public b2primaria?:string,
        public b3primaria?: string,
        public b4primaria?:string,
        public b1secundaria?: string,
        public b2secundaria?: string,
        public b3secundaria?: string,
        public b4secundaria?: string,
        public activo?: string,
        public disponible?: string,
        public beca?: string,
        public pension?: string,
        public otros?:string,
        public imgbanner1?: string,
        public imgbanner2?: string,
        public imgbanner3?: string,
        public imgbanner4?: string,
        public imgbanner5?:string,
        public imgbanner6?: string,
        public mision?: string,
        public vision?: string,
        public imgvision?: string,
        public imgmision?:string,
        public logros?: string,
        public novedades?: string,
        public noticias?: string,
        public _id?: string
){}
}
import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { Programacion } from "../../models/programacion.model";
import { MaterialService, MateriaService, VirtualService } from '../../services/service.index';
import { Criterio } from '../../models/criterio.model';
import { Material } from '../../models/material.model';

import Swal from 'sweetalert2';


@Component({
  selector: 'app-evaluacion',
  templateUrl: './evaluacion.component.html',
  styles: []
})
export class EvaluacionComponent implements OnInit {
  identificador: string;
  programaciones2: Programacion[] = [];
  programaciones = new Programacion('');
  programacion: Programacion;
  public criterio : Criterio[] = [];
  public criterio2 = [];
  bimestre:string;
  material: Material[]=[];
  identificador2: string;

  constructor(
    public _virtualService: VirtualService,
    public activatedRoute: ActivatedRoute,
    public _materialService: MaterialService,
    public _materiaService: MateriaService,
    public router: Router
  ) {
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
      this.bimestre = params['bim'];
      this.identificador2 = params['id2'];
    });
    //this.programaciones=new Programacion('');
    this.programacion=new Programacion('');
   }

  ngOnInit() {
    this.cargarEvaluacion();
    this.cargarDatos(this.identificador2);  
  }
  cargarDatos(id: string){
    this._materialService.cargarAulaId( id )
        .subscribe( (resp: any) => {
          this.material = resp.material;    
          this.cargarCriterios();
        })
  }

  cargarEvaluacion(){
    this._virtualService.cargarEvaluacionVirtual( this.identificador )
        .subscribe( (resp: any) => {
          this.programaciones2 = resp;
        })
  }
  cargarCriterios(){
    this._materiaService.cargarCriterio( this.material['curso']['_id'], this.material['materia']['_id'] )
      .subscribe( (resp: any) => {
        this.criterio = resp;
      })
  }

  guardarEvaluacion( programa: Programacion ){
    this.programacion.titulo = programa.titulo;
    this.programacion.instruccion = programa.instruccion;
    this.programacion.tipo = programa.tipo;
    this.programacion.inicio = programa.inicio;
    this.programacion.fin = programa.fin;
    this.programacion.criterio = programa.criterio;
    this.programacion.cant_tiempo = programa.cant_tiempo;
    this.programacion.cant_preguntas = programa.cant_preguntas;
    this.programacion.aleatorio = programa.aleatorio;
    this._virtualService.agregarProgramacion(this.identificador, this.programacion).subscribe( resp => {
      this.cargarEvaluacion();
    });
  }

  seleccion(id:string){
    var obj = new Object();
    this.criterio2 = [];
    //this.tare.criterio='';
    for(let i=0;i<this.criterio.length;i++){
      if(this.criterio[i].dimension[0]==id && this.criterio[i].trimestre[0]==this.bimestre ){
        obj = {
          _id : this.criterio[i]._id,
          criterio : this.criterio[i].criterio,
          dimension : this.criterio[i].dimension[0],
          trimestre : this.criterio[i].trimestre[0]
        };
        this.criterio2.push(obj);
      }
    }
  }

  evaluacionOffline(programa: Programacion){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de sacar de línea la evaluación: '+programa.titulo,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._virtualService.offlineEvaluacion( programa._id )
          .subscribe( virtual => {
            this.cargarEvaluacion();
          });
      }
    })
  }
  evaluacionOnline(programa: Programacion){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de poner en línea la evalución: '+programa.titulo,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._virtualService.onlineEvaluacion( programa._id )
          .subscribe( virtual => {
            this.cargarEvaluacion();
          });
      }
    })
  }

  borrarEvaluacion( programa: Programacion ){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de borrar: '+programa.titulo,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._virtualService.borrarVirtualEvaluacion( programa._id )
          .subscribe( virtual => {
            this.cargarEvaluacion();
          });
      }
    })
  }

  EvaluacionMod( id: string ){
    this.router.navigate(['/institucion/evaluacion-modificar', id,this.identificador,this.identificador2]);
  }

  EvaluacionVer(id: string){
    //this.router.navigate(['/institucion/revision-tarea', this.identificador2, this.identificador,id]);
  }

}

import { Component, OnInit } from '@angular/core';
import { CursoService } from '../../services/service.index';
import { Router } from '@angular/router';
import { Curso } from '../../models/curso.model';

@Component({
  selector: 'app-listacurso',
  templateUrl: './listacurso.component.html',
  styles: []
})
export class ListacursoComponent implements OnInit {
  title: string;
  limite: number = 0;
  desde: number = 0;
  totalRegistros: number = 0;
  cargando: boolean = true;
  cursos: Curso[] = [];

  constructor(
    public router: Router,
    public _cursoService: CursoService
  ) { 
    this.title = "LISTA DE CURSOS";
  }

  ngOnInit() {
    this.cargarCurso();
    
  }
  cargarCurso(){
    this._cursoService.cargarCursos( this.desde )
        .subscribe( (resp: any) => {
          this.totalRegistros = resp.cuantos;
          this.cursos = resp.curso;
          this.cargando = false;
        })
  }
  cambiarDesde( valor: number ){
    let desde = this.desde + valor;
    if( desde >= this.totalRegistros ){
      return;
    }
    if( desde < 0 ){
      return;
    }
    this.desde += valor;
    this.cargarCurso();
  }


}

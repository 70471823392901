import { Component, OnInit } from '@angular/core';

declare function customInitFunction();
declare function init_plugins();

@Component({
  selector: 'app-profesor',
  templateUrl: './profesor.component.html',
  styles: []
})
export class ProfesorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    customInitFunction();
    init_plugins();
  }

}

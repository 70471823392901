import { RouterModule, Routes } from "@angular/router";
import { Input } from '@angular/core';

import { Institucion } from "../models/institucion.model";

import { UsuarioComponent } from './usuario.component';

import { PerfilComponent } from './perfil/perfil.component';
import { LoginGuardGuard } from '../services/service.index';
import { ProfileComponent } from './profile/profile.component';



const userRoutes: Routes = [
    {
        path: '', 
        component: UsuarioComponent,  
        canActivate: [ LoginGuardGuard ],  
        canLoad: [ LoginGuardGuard ],                             
        children: [
            //{path: ':nombre', component: PrincipalComponent}, 
            {path: 'user-perfil', component: PerfilComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'useruarioperfil', component: ProfileComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: '', redirectTo: '/user-perfil', pathMatch: 'full'}
            
        ]
    }
];

export const USER_ROUTES = RouterModule.forChild( userRoutes );
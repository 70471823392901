import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configurar',
  templateUrl: './configurar.component.html',
  styles: [
  ]
})
export class ConfigurarComponent implements OnInit {
  title: string;

  constructor() {
    this.title = "CORRESPONDECIA";
   }

  ngOnInit(): void {
  }

}

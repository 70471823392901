import { NgModule } from '@angular/core';
import { ImagenPipe } from './imagen.pipe';
import { ReversaPipe } from './reversa.pipe';
import { SafePipe } from "./safe.pipe";
import { SortByPipe } from "./ordenacion.pipe";
import { TruncarPipe } from './truncar.pipe';
import { DesordenPipe } from './desorden.pipe';



@NgModule({
  declarations: [
    ImagenPipe,
    SafePipe,
    ReversaPipe,
    SortByPipe,
    TruncarPipe,
    DesordenPipe
  ],
  imports: [
  ],
  exports: [
    ImagenPipe,
    SafePipe,
    ReversaPipe,
    SortByPipe,
    TruncarPipe,
    DesordenPipe
  ]
})
export class PipesModule { }

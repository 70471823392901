import { Component, OnInit } from '@angular/core';
import { CursoService, UserService } from '../../services/service.index';
import { Router } from '@angular/router';
import { Curso } from '../../models/curso.model';
import { Personal } from '../../models/personal.model';

import Swal from 'sweetalert2';


@Component({
  selector: 'app-tutor',
  templateUrl: './tutor.component.html',
  styles: []
})
export class TutorComponent implements OnInit {
  title: string;
  limite: number = 0;
  desde: number = 0;
  totalRegistros: number = 0;
  cargando: boolean = true;
  cursos: Curso[] = [];
  tutor: Curso[] = [];
  personal: Personal
  constructor(
    public _cursoService: CursoService,
    public _userService: UserService,
    public router: Router
  ) { 
    this.title = "Mis tutorias";
  }

  ngOnInit() {
    this.cargarCurso();
    this.cargarCursoTutor();
    this.personal = this._userService.personal;
  }
  cargarCursoTutor(){
    this._cursoService.cargarCursoSinSin()
        .subscribe( curso => {
          this.tutor = curso;
        })
  }
  cargarCurso(){
    this._cursoService.cargarCursos( this.desde )
        .subscribe( (resp: any) => {
          this.totalRegistros = resp.cuantos;
          this.cursos = resp.curso;
          //console.log(this.cursos);
          this.cargando = false;
          /*if( this.cursos[0].tutor1 === this.personal[0]._id ){
            this.exi = true;
          }else{
            if( this.cursos[0].tutor2 ){
              if( this.cursos[0].tutor2 === this.personal[0]._id ){
                this.exi = true;                
              }
            }else{
              this.exi = false;
            }
          }*/
        })
  }
  cambiarDesde( valor: number ){
    let desde = this.desde + valor;
    if( desde >= this.totalRegistros ){
      return;
    }
    if( desde < 0 ){
      return;
    }
    this.desde += valor;
    this.cargarCurso();
  }

  /*QuitarTutor( curso: Curso ){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de ya no ser tutor de: '+curso.nombre,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._personalService.bajaPersonal( personal._id )
              .subscribe( personal => {
                this.cargarPersonal();
              });
      }
    })
  }*/

  AsignarTutor( curso: Curso ){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de ser tutor de: '+curso.nombre,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._cursoService.asignarTutor( curso._id, this.personal[0]._id )
              .subscribe( curso => {
                this.cargarCurso();
                this.cargarCursoTutor();
              });
      }
    })
  }

  verLista(id: string){
    this.router.navigate(['/institucion/mi-curso-tutoria', id]);    
  }

}

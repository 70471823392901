import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Institucion } from '../../models/institucion.model';
import { InstitucionService, UserService, PersonalService } from '../../services/service.index';
import { User } from '../../models/user.model';
import { Personal } from "../../models/personal.model";
import { ActivatedRoute } from '@angular/router';
//import { Curso } from '../../models/curso.model';
import Swal from 'sweetalert2';
import { Ng2ImgMaxService } from 'ng2-img-max';


declare function init_plugins();

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styles: []
})
export class PerfilComponent implements OnInit {
  title:string;
  public institucion: Institucion[];
  public usuario = new User('','','','');
  public personal = new Personal('','','','');
  public per = new Personal('','','','');
  public inst;
  public imagenSubir: File;
  public imagenTemp: any;
  public hijo: User = new User('','','','');
  public boton: boolean = false;
  public estudiante: User;
  //public cursos: Curso[];
  limite: number = 0;
  desde: number = 0;
  totalRegistros: number = 0;
  public alumno: Personal = new Personal('','','','');

  uploadResponse: any;
  error: string;
  

  constructor(
    public _institucionService: InstitucionService,
    public activateRoute: ActivatedRoute,
    public _userService: UserService,
    public _personalService: PersonalService,
    private ng2ImgMax: Ng2ImgMaxService
    //public _cursoService: CursoService
  ) { 
    this.title = "CONFIGURACIÓN DE CUENTA";
    this.institucion = [];
  }

  ngOnInit() {
    init_plugins();
    this._institucionService.cargarInstitucionSin().subscribe((institucion: Institucion[]) =>  this.institucion = institucion );   
    this.usuario = this._userService.user;
    this.verificaInst();
    this.per =  this._userService.personal;
    //console.log(this.per);
    /*this.activateRoute.params.subscribe( params => {
      let id = params['id'];
    }); */
  }

  actualizaUser( usuario: User ){
    this.usuario.nombres = usuario.nombres;
    this.usuario.paterno = usuario.paterno;
    this.usuario.materno = usuario.materno;
    this.usuario.cedula = usuario.cedula;
    this.usuario.celular = usuario.celular;
    this.usuario.zona = usuario.zona;
    this.usuario.calle = usuario.calle;
    this.usuario.sexo = usuario.sexo;
    this.usuario.nacimiento = usuario.nacimiento;
    this.usuario.username = usuario.username;
    this._userService.actualizarUser( this.usuario )
      .subscribe();
  }

  guardarPersonal( personal: Personal ){
    this.personal.user = this.usuario._id;
    this.personal.institucion = personal.institucion;
    this.personal.role = personal.role;
    this._personalService.crearPersonal( this.personal )
      .subscribe( resp => {
        this.usuario.institucion = personal.institucion;
        this._userService.actualizarUser( this.usuario )
          .subscribe( resp => {
            this.verificaInst();
            this._userService.logout();
          });
      });
  }
  verificaInst(){
    if(this.usuario.institucion === '' || this.usuario.institucion ){
      this.inst = false;
    }else{
      this.inst = true;
    }
  }
  seleccionImage( archivo: File ){
    if(!archivo){
      this.imagenSubir = null;
      return;
    }
    if( archivo.type.indexOf('image') < 0 ){
      Swal.fire('Solo imagenes', 'El archivo seleccionado no es una imagen', 'error');
      this.imagenSubir = null;
      return;
    }
    this.ng2ImgMax.resizeImage(archivo, 100, 100).subscribe(
    //this.ng2ImgMax.compressImage(archivo, 0.075).subscribe(
      result => {
        this.imagenSubir = result;
        let reader = new FileReader();
        let urlImagenTemp = reader.readAsDataURL( result );
        reader.onloadend = () => this.imagenTemp = reader.result;
      },
      error => {
        console.log('Oh no!', error);
      }
    )    
  }

  cambiarImagen(){
    //console.log(this.imagenSubir);
    //this._userService.CambiarImagen(this.imagenSubir, this.usuario._id );
    try{
      const formData =  new FormData();
      formData.append( 'archivo', this.imagenSubir, this.imagenSubir.name );
      this._personalService.modificarImagen( this.usuario._id,formData ).subscribe( 
        (resp:any) => {
          this.uploadResponse = resp;
          if(typeof(this.uploadResponse)!=='number' && this.uploadResponse!==undefined){
            Swal.fire('Muy bien', 'Se ha logrado subir la imagen sin inconveniente', 'success');
            this._userService.actualizarLS(resp.profileImageURL,resp.usuario._id);
          }     
        }
      );
    }
    catch(error){
      console.log(error);
    }
    
  }

  /*guardarHijo( hijo: User ){
    this.hijo.nombres = hijo.nombres;
    this.hijo.paterno = hijo.paterno;
    this.hijo.materno = hijo.materno;
    this.hijo.cedula = hijo.cedula;
    this.hijo.celular = hijo.celular;
    this.hijo.zona = hijo.zona;
    this.hijo.calle = hijo.calle;
    this.hijo.sexo = hijo.sexo;
    this.hijo.nacimiento = hijo.nacimiento;
    this.hijo.email = hijo.email;
    this.hijo.password = hijo.password;
    this.hijo.institucion = this.usuario.institucion['_id'];
    this._userService.crearUserInstitucion( this.hijo ).subscribe( resp => {      
      this.boton = true;
      this.estudiante = resp;
      this._userService.agregarHijo( this.estudiante._id )
      .subscribe(resp => {
        this.cargarCurso();
      })
    });
  }*/
  /*cargarCurso(){
    this._cursoService.cargarCursos( this.desde )
        .subscribe( (resp: any) => {
          this.totalRegistros = resp.cuantos;
          this.cursos = resp.curso;
        })
  }*/

  /*registroCurso(id){
    this.alumno.user = this.estudiante._id;
    this.alumno.institucion = this.estudiante.institucion;
    this.alumno.role = '2';
    this.alumno.gestiones = {'cursos':id};
    this._personalService.crearAlumnno( this.alumno )
      .subscribe( resp => {
        location.reload();
      });
  }

  cambiarDesde( valor: number ){
    let desde = this.desde + valor;
    if( desde >= this.totalRegistros ){
      return;
    }
    if( desde < 0 ){
      return;
    }
    this.desde += valor;
    //this.cargarCurso();
  }*/

  

}

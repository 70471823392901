export class Curso {
    constructor(
        public institucion: string,       
        public nombre?: string,
        public numero?:  number,
        public nivel?: string,   
        public turno?: number,
        public paralelo?: string,
        public tutor1?: string,
        public tutor2?: string,
        public tutor3?: string,
        public clave?:number,
        public enlace?:string,
        public _id?: string
    ){}
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from '../../../config/config';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-modificar-cuaderno',
  templateUrl: './modificar-cuaderno.component.html',
  styles: [
  ]
})
export class ModificarCuadernoComponent implements OnInit {
  identificador: string;
  public documentoSubir: File;
  progress: number = 0;
  trimestre: number;
  public btn : boolean = false;
  carga : boolean;

  constructor(
    public activatedRoute: ActivatedRoute,
    private http:HttpClient
  ) {
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
    });
   }

  ngOnInit(): void {
  }

  excelRead(e){
    if(!e){
      e = null;
      return;
    }
    if( e.target.files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
      Swal.fire('Error en la selección de archivo', 'El archivo que tiene que subir a plataforma, debe ser un archivo excel', 'error');
      e = null;
      return;
    }
    this.documentoSubir = e.target.files[0];
    let fileReaded: any;
    fileReaded = e.target.files[0];
    this.btn = true;
  }

  resguardar(){
    this.carga = true;
    Swal.fire({
      title: '¿Estas Seguro?',
      html: '<p>Estas a punto validar tus calificaciones</p>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this.subirDocumento();        
      }else{
        this.carga = false;
      }
    })
  }
  async subirDocumento(){
    this.progress=0;
    this.trimestre = 1;
    try{
      const url = URL_SERVICIOS + '/uploadCuadernoPedagogico/' + this.identificador+'/'+this.trimestre;  
      const formData =  new FormData();
      formData.append( 'archivo', this.documentoSubir, this.documentoSubir.name );
      await this.http.put(url,formData, {
        reportProgress: true,
        observe: 'events'
      }).subscribe(event => {
        if(event.type === HttpEventType.UploadProgress){
          this.progress=Math.round(event.loaded / event.total * 100);
        }else if(event.type === HttpEventType.Response){
          if(this.progress === 100){
            this.progress=0;            
            Swal.fire('Documento Cargado', 'Se ha subido el documento', 'success'); 
            this.carga = false;
          }          
        }
      });
      
    } catch(error){
      console.log(error);
      return false;
    }
  }

}

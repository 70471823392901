import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user.model';
import { UserService, PersonalService } from '../../services/service.index';
import { Personal } from '../../models/personal.model';

import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mis-hijos',
  templateUrl: './mis-hijos.component.html',
  styles: []
})
export class MisHijosComponent implements OnInit {
  title: string;
  public hijo: User = new User('','','','');
  public usuario = new User('','','','');
  public per = new Personal('','','','');
  public persona = new Personal('', '', '', '');
  public estudiante: User;

  constructor(
    public _userService: UserService,
    public _personalService: PersonalService,
    public router: Router
  ) {
    this.title = "NOMINA DE MIS HIJOS REGISTRADOS";
   }

  ngOnInit() {
    this.usuario = this._userService.user;
    this.per =  this._userService.personal[0];
    this.cargarPersona(this.per._id);
  }

  guardarHijos( hijo: User ){
    this.hijo.nombres = hijo.nombres;
    this.hijo.paterno = hijo.paterno;
    this.hijo.materno = hijo.materno;
    this.hijo.cedula = hijo.cedula;
    this.hijo.celular = hijo.celular;
    this.hijo.zona = hijo.zona;
    this.hijo.calle = hijo.calle;
    this.hijo.sexo = hijo.sexo;
    this.hijo.nacimiento = hijo.nacimiento;
    this.hijo.email = hijo.email;
    this.hijo.password = hijo.password;
    this.hijo.institucion = this.usuario.institucion['_id'];    
    this._userService.crearUserInstitucion( this.hijo ).subscribe( resp => {    
      this.estudiante = resp;
      this._userService.agregarHijo( this.estudiante._id )
      .subscribe(resp => {
        location.reload();
      })
    });
  }

  cargarPersona( id: string ){
    this._personalService.cargarPersona( id )
        .subscribe( (resp: any) => {
          this.persona = resp.personal;
        })
  }

  borrarHijo( id: string, hijo: string ){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de borrar un registro',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._personalService.borrarHijo( this.per._id, id, hijo )
              .subscribe( material => {
                this.cargarPersona(this.per._id);
              });
      }
    })
  }

  miHijo( id: string ){
    this.router.navigate(['/ppff/registro-hijo', id]);
  }
  

}

import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from '../../config/config';
//import { Observable} from 'rxjs';
import { HttpClient, HttpHeaders, HttpEvent, HttpErrorResponse, HttpEventType, HttpRequest } from '@angular/common/http';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';
//import { Tareapresentada } from '../../models/tareapresentada.model';
//import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SubirArchivoService {
  progress: number = 0;
  //progressObserver:Observable<any>;
  constructor(private http:HttpClient) {
    
  }

  async subirImagen(archivo: File, tipo: string, id: string){
    this.progress=0;
    try{
      const url = URL_SERVICIOS + '/upload/' + tipo + '/' + id;  
      const formData =  new FormData();
      formData.append( 'archivo', archivo, archivo.name );
      await this.http.put(url,formData, {
        reportProgress: true,
        observe: 'events'
      }).subscribe(event => {
        //console.log(event);
        if(event.type === HttpEventType.UploadProgress){
        }else if(event.type === HttpEventType.Response){
          console.log(event);
        }
      });
      
    } catch(error){
      console.log(error);
      return false;
    }
  }
  async subirArchivo2( archivo: File, tipo: string, id: string ){   
    try{
      const formData =  new FormData();

      formData.append( 'archivo', archivo, archivo.name );
      //let url = URL_SERVICIOS + '/uploadusruario2/' + tipo + '/' + id;  
      const url = URL_SERVICIOS + '/uploadusruario2';   
    }
    catch(error){
      console.log(error);
    }     
  }

  subirArchivo( archivo: File,  id: string, id2: string ){
    this.progress=0;
    return new Promise( ( resolve, reject ) => {
      const formData =  new FormData();
      const xhr = new XMLHttpRequest();
      formData.append( 'archivo', archivo, archivo.name );

      const url = URL_SERVICIOS + '/uploadImgEval/' + id + '/'+id2;  
      //const url = URL_SERVICIOS + '/uploadusruario2'+ '/' + id;  

      xhr.onreadystatechange = () =>{
        if( xhr.readyState === 4 ){
          if( xhr.status === 200 ){
            //console.log('imagen subida');
            //resolve(e);
            resolve(JSON.parse(xhr.response));
          }else{
            //console.log('fallo la subida');
            reject(xhr.response);
          }
        }
      };

      xhr.upload.onprogress = (event) => {
        this.progress = Math.round(event.loaded / event.total * 100);
      };
      xhr.upload.addEventListener  ('progress',function(event){
        let porcetaje = Math.round(event.loaded / event.total * 100);
      });
      xhr.open('PUT', url, true);
      
      xhr.send( formData );
      
    });
    
  }
  subirArchivo3( archivo: any,  id: string, id2: string ){
    //let url = URL_SERVICIOS + '/uploadusruario2/' + id;
    const url = URL_SERVICIOS + '/uploadImgEval/' + id + '/'+id2;  
    
    return this.http.put( url, archivo, {
      reportProgress: true, 
      observe: 'events'
    }).pipe(map( (resp) => {
      switch(resp.type){
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * resp.loaded / resp.total);
          return progress;
        case HttpEventType.Response:
          return resp.body;
        default: `se ha producido un evento: ${resp.type}`;
      }  
    }));
  }
  subirImagenesTarea( archivo: any,  id: string): Observable<HttpEvent<any>>{
    //console.log(archivo);
    const formData: FormData =  new FormData();
    formData.append( 'archivo', archivo, archivo.name );
    const url = URL_SERVICIOS + '/uploadImgsTareas/' + id;
    const req = new HttpRequest('PUT', url, formData, {
      reportProgress: true,
      responseType: 'json'
    });  
    return this.http.request(req);
    /*
    return this.http.put( url, archivo, {
      reportProgress: true, 
      observe: 'events'
    }).pipe(map( (resp) => {
      switch(resp.type){
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * resp.loaded / resp.total);
          return progress;
        case HttpEventType.Response:
          return resp.body;
        default: `se ha producido un evento: ${resp.type}`;
      }  
    }));*/
  }
  subirDocTarea( archivo: any,  id: string): Observable<HttpEvent<any>>{
   // console.log(archivo);
    const formData: FormData =  new FormData();
    formData.append( 'archivo', archivo, archivo.name );
    const url = URL_SERVICIOS + '/uploadDocsTareas/' + id;
    const req = new HttpRequest('PUT', url, formData, {
      reportProgress: true,
      responseType: 'json'
    });  
    return this.http.request(req);
  }
  
  // ya no uso para subir imagen a la tarea
  // ya no uso para subir documento a la tarea
  subirArchivoSubtitulo( archivo: File, tipo: string, virtual: string ,id: string , mas: string){
    this.progress=0;
    return new Promise( ( resolve, reject ) => {
      let formData =  new FormData();
      let xhr = new XMLHttpRequest();
  
      formData.append( 'archivo', archivo, archivo.name );
      xhr.onprogress = function (e) {
        xhr.onreadystatechange = function(){
          if( xhr.readyState === 4 ){
            if( xhr.status === 200 ){
              resolve(e);
            }else{
              reject(xhr.response);
            }
          }
        };
      }
  
      let url = URL_SERVICIOS + '/uploadsubtitulo/' + tipo + '/' + virtual + '/' + id + '/' + mas;
      xhr.upload.onprogress = (event) => {
        this.progress = Math.round(event.loaded / event.total * 100);
        console.log(this.progress);
      };
      xhr.upload.addEventListener  ('progress',function(event){
        let porcetaje = Math.round(event.loaded / event.total * 100);
        console.log(porcetaje);
      });

      xhr.open('PUT', url, true);
      xhr.send( formData );
    });
    
  }

  
}


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MaterialService, PersonalService } from '../../services/service.index';
import { Material } from '../../models/material.model';
import { Personal } from '../../models/personal.model';


@Component({
  selector: 'app-estadistica',
  templateUrl: './estadistica.component.html',
  styles: [
  ]
})
export class EstadisticaComponent implements OnInit {
  public materiales: Material[] = [];
  personal: Personal[] = [];
  identificador: string;
  title : string;
  dtOptions: DataTables.Settings = {};
  cargando: boolean = true;
  

  constructor(
    public activatedRoute: ActivatedRoute,
    public _materialService: MaterialService,
    public _personalService: PersonalService,
    public router: Router
  ) { 
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
    });
    this.title = "ESTADISTICAS";
    this.dtOptions = {
      pagingType: 'simple',
      pageLength: 10,
      language: {
        url: '//cdn.datatables.net/plug-ins/1.12.0/i18n/es-ES.json'
      },
      order: [[0, 'asc']]
    };
  }

  ngOnInit(): void {
    this.cargarMaterial();
  }

  cargarMaterial(){
    this._materialService.cargarMaterialEstadistica( this.identificador)
    .subscribe( (resp: any) => {
      //this.totalRegistros = resp.cuantos;
      this.materiales = resp.material;
      this.cargarPersonal();
      this.cargando = false;

    })
  }
  cargarPersonal(){
    this._personalService.cargarProfe(this.identificador)
        .subscribe( (resp: any) => {
          this.personal = resp.personal;
        })
  }
  verEstadistica( id: string ){
    this.router.navigate(['/institucion/descripcion-estadistica', id]);
  }

}

import { Component, OnInit } from '@angular/core';
import { Virtual } from '../../../models/virtual.model';
import { Enlace } from '../../../models/enlace.model';
import { Subtitulo } from "../../../models/subtitulo.model";
import { Consulta } from "../../../models/consulta.model";
import { VirtualService, SubirArchivoService, UserService, GaleriaService, RelojService, XsegundoService, MaterialService } from '../../../services/service.index';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalUploadService } from '../../../components/modal-upload/modal-upload.service';
import { URL_SERVICIOS } from '../../../config/config';

import Swal from 'sweetalert2';
//import { FormGroup, FormControl } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';


import { User } from '../../../models/user.model';
import { Personal } from '../../../models/personal.model';

import { Galeria } from '../../../models/galeria.model';
import { Material } from '../../../models/material.model';

@Component({
  selector: 'app-nuevodesarrollo',
  templateUrl: './nuevodesarrollo.component.html',
  styles: [
  ]
})
export class NuevodesarrolloComponent implements OnInit {
  identificador: string;
  virtual: Virtual;  
  video: Enlace;
  title: string;
  subtitulo:Subtitulo;
  consulta:Consulta;
  virtua:Virtual;
  public documentoSubir: File;
  public documentoTemp: any;
  direccion1: string;
  direccion2: string;
  direccion3: string;
  direccion4: string;
  validacion: string;
  msbapAudioUrl: string;  
  personal: Personal;
  public isDisabled = true;
  uno:boolean=false;
  dos:boolean=false;
  tres:boolean=false;
  
  limite: number = 0;
  desde: number = 0;
  totalRegistros: number = 0;
  
  msbapDisplayTitle: boolean = false; 
  msbapDisplayVolumeControls: boolean = true;  
  public bandera: boolean = false;
  public bandera2: boolean = false;
  subt1 : boolean = false;
  ver1 : boolean = false;

  datos$: Observable<RelojService>;
  hora: number;
  minutos: string;
  dia: string;
  fecha: string;
  ampm: string;
  segundos: string;
  public progress: number = 0;
  public usuario = new User('','','','');
  public galeria= new Galeria();
  material :  Material[]=[];
  sub : Subtitulo[] = [];
  identificador2 : string;
  //apiLoaded = false;
  page: number = 1;
  totalPages: number;
  isLoaded: boolean = false;


  constructor(
    public _virtualService: VirtualService,
    public activatedRoute: ActivatedRoute,
    public _modalUploadService: ModalUploadService,
    public _subirArchivoService: SubirArchivoService,
    public _userService: UserService,
    public _galeriaService: GaleriaService,
    private segundo: XsegundoService,
    public _materialService: MaterialService    
  ) { 
    this.title = "Tema Virtual";
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
      this.identificador2 = params['material'];
    });
    this.subtitulo=new Subtitulo('','','','',0,'');
    this.virtual=new Virtual('','',0);
    this.video = new Enlace();
    this.usuario = this._userService.user;
    this.personal = this._userService.personal[0];
    
    this.consulta = new Consulta('','','','','','');
    
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '5rem',
    placeholder: 'Escriba el texto aqui...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['insertVideo'],
      ['insertImage'],
      ['backgroundColor'],
      ['toggleEditorMode']
    ],
    customClasses: [
      {
        name: "Negrita",
        class: "quote",
        tag: "strong",
      },
      {
        name: 'Mas negrita',
        class: 'redText',
        tag: "b",
      },
      {
        name: "Títulos",
        class: "titleText",
        tag: "h3",
      },
    ]
  };

  config2: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '3rem',
    placeholder: 'Escriba la consulta aqui...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['insertVideo'],
      ['insertImage']
      ],
  };

  

  ngOnInit() {
    this.cargarVirtual();
    this._modalUploadService.notificacion
      .subscribe( resp => this.cargarVirtual() );
    this.cargarGaleria();
    this.elReloj();
    this.cargarDatos();
    //this.youtube();
  }
  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }
  
  ver11(){
    this.ver1 ? this.ver1=false : this.ver1=true;
  }
  
  

  elReloj(){
    this.datos$=this.segundo.getInfoReloj();
    this.datos$.subscribe(x => {
      this.hora = x.hora;
      this.minutos = x.minutos;
      this.dia = x.diadesemana;
      this.fecha = x.diaymes;
      this.ampm = x.ampm;
      this.segundos = x.segundo
    });
  }
  evento(e){
    if(e.indexOf('<img') >= 0 ){
      Swal.fire('Ups!', 'Se ha detectado que esta intentando subir una imagen o video de manera inapropiada, por favor revise el tutorial', 'error');
    }
  }
  verImagen(e){
    if(e.indexOf('http') >= 0 ){
      return true;
    }else{
      return false;
    }
  }


 
  mostrarModal( tema: string, id: string ){
    this._modalUploadService.mostrarModal( 'tema', tema, id );
    this.cargarVirtual();
  }
  mostrarModal2( tema: string, id: string ){
    this._modalUploadService.mostrarModal( 'tema2', tema, id );
    this.cargarVirtual();
  }
  mostrarModal3( tema: string, id: string ){
    this._modalUploadService.mostrarModal( 'tema3', tema, id );
    this.cargarVirtual();
  }
  cargarGaleria(){
    this._galeriaService.cargarGaleria()    
    .subscribe( (resp: any) => {
      if(resp.length===0){
        this.bandera = false;
      }else{
        this.bandera = true;
        this.galeria = resp[0];
      }
    })
  }
  eliminarSub(id: string, tema: string){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de borrar el subtitulo ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._virtualService.borrarSubtitulo( id, tema )
              .subscribe( material => {
                this.cargarVirtual();
              });
      }
    })
  
  }
  eliminarFoto(id: string, tema: string){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de borrar el subtitulo ',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._virtualService.borrarSubtitulo( id, tema )
              .subscribe( material => {
                this.cargarVirtual();
              });
      }
    })
  
  }
  /*youtube(){
    if(!this.apiLoaded){
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }

    this.miLista.push({
                  'user' : this.personas[i].user['_id'],
                  'personal': this.personas[i]._id,
                  'paterno' : this.personas[i].user['paterno'],
                  'materno' : this.personas[i].user['materno'],
                  'nombres' : this.personas[i].user['nombres'],
                  'email' : this.personas[i].user['email'],
                  'cedula' : this.personas[i].user['cedula'],
                  'zona' : this.personas[i].user['zona'],
                  'calle' : this.personas[i].user['calle'],
                  'padre' : this.personas[i].user['padre'],
                  'celpadre' : this.personas[i].user['celpadre'],
                  'madre' : this.personas[i].user['madre'],
                  'celmadre' : this.personas[i].user['celmadre'],
                  'estado' : this.personas[i].estado
                });
    
  }*/

  cargarVirtual(){
    this.sub = [];
    this._virtualService.cargarTemaVirtual( this.identificador )
        .subscribe( (resp: any) => {
          this.virtual = resp[0];
          for(let i = 0; i < this.virtual.contenido.length; i++){
            this.sub.push({
              'url' : this.virtual.contenido[i]['url'],
              'img3' : 'https://drive.google.com/file/d/'+this.virtual.contenido[i]['img3']+'/preview',
              '_id' : this.virtual.contenido[i]['_id']
            });
          }          
          this.direccion1 = URL_SERVICIOS+'/imagen/tema/'+this.virtual.doc1;
          this.direccion2 = URL_SERVICIOS+'/imagen/tema/'+this.virtual.doc2;
          this.direccion3 = URL_SERVICIOS+'/imagen/tema/'+this.virtual.doc3;
          this.direccion4 = URL_SERVICIOS+'/imagen/tema/'+this.virtual.video;
          this.msbapAudioUrl = URL_SERVICIOS+'/imagen/tema/'+this.virtual.audio;
          if(this.virtual.grabacion1!==''){
            this.uno=true;
          }
          if(this.virtual.grabacion2!==''){
            this.dos=true;
          }
          if(this.virtual.grabacion3!==''){
            this.tres=true;
          }
          this.bandera2 = true;
        })
  }

  

  seleccionDocumento( archivo: File ){
    if(!archivo){
      this.documentoSubir = null;
      return;
    }
    if( archivo.type.indexOf('image') === 0 ){
      Swal.fire('Solo documentos', 'El archivo seleccionado es una imagen', 'error');
      this.documentoSubir = null;
      return;
    }

    this.documentoSubir = archivo;

  }

  seleccionVideo( archivo: File ){
    if(!archivo){
      this.documentoSubir = null;
      return;
    }
    if( archivo.type.indexOf('image') === 0 ){
      Swal.fire('Solo documentos', 'El archivo seleccionado es una imagen', 'error');
      this.documentoSubir = null;
      return;
    }
    if( archivo.type.indexOf('video') === 0 ){
      if( archivo.size < 31000000 ){
        this.documentoSubir = archivo;
        return;
      }else{
        Swal.fire('Video muy grande', 'El video debe ser menor a 30 Mb', 'error');
        this.documentoSubir = null;
        return;
      }
      
    }

    this.documentoSubir = null;

  }

  seleccionAudio( archivo: File ){
    if(!archivo){
      this.documentoSubir = null;
      return;
    }
    if( archivo.type.indexOf('image') === 0 ){
      Swal.fire('Solo documentos', 'El archivo seleccionado es una imagen', 'error');
      this.documentoSubir = null;
      return;
    }
    if( archivo.type.indexOf('audio') === 0 ){
      if( archivo.size < 15000000 ){
        this.documentoSubir = archivo;
        return;
      }else{
        Swal.fire('Video muy grande', 'El audio debe ser menor a 15 Mb', 'error');
        this.documentoSubir = null;
        return;
      }
      
    }

    this.documentoSubir = null;

  }
  subirVideo(){
    this.progress=0;
    let valor = '4';
    let vacio='vacio';
    this._subirArchivoService.subirArchivoSubtitulo(this.documentoSubir, 'tema', this.virtual._id,valor,vacio)
      .then( (resp:any) => {
        //console.log(resp);
        this.progress = Math.round(resp.loaded / resp.total * 100);
        //console.log(this.progress);
        if(resp.loaded == resp.total){
          this.tiempo();
        }  
        this._modalUploadService.notificacion.emit(resp);

        //this.cargarVirtual();
      })
      .catch( err => {
        console.log('Error en la carga... ');
      });
  }
  tiempo(){
    setTimeout(() => {
      this.cargarVirtual();
      Swal.fire('Muy Bien', 'Subiste un video', 'success');    
    },3000);
  }
  subirAudio(){
    let valor = '5';
    let vacio='vacio';
    this._subirArchivoService.subirArchivoSubtitulo(this.documentoSubir, 'tema', this.virtual._id,valor,vacio)
      .then( resp => {
        this._modalUploadService.notificacion.emit(resp);
        this.cargarVirtual();
      })
      .catch( err => {
        console.log('Error en la carga... ');
      });
  }
  subirDocumento1(){
    let valor = '1';
    let vacio='vacio';
    this._subirArchivoService.subirArchivoSubtitulo(this.documentoSubir, 'tema', this.virtual._id,valor,vacio)
      .then( resp => {
        this._modalUploadService.notificacion.emit(resp);
        this.cargarVirtual();
      })
      .catch( err => {
        console.log('Error en la carga... ');
      });
  }
  subirDocumento2(){
    let valor = '2';
    let vacio='vacio';
    this._subirArchivoService.subirArchivoSubtitulo(this.documentoSubir, 'tema', this.virtual._id,valor,vacio)
      .then( resp => {
        this._modalUploadService.notificacion.emit(resp);
        this.cargarVirtual();
      })
      .catch( err => {
        console.log('Error en la carga... ');
      });
  }
  subirDocumento3(){
    let valor = '3';
    let vacio='vacio';
    this._subirArchivoService.subirArchivoSubtitulo(this.documentoSubir, 'tema', this.virtual._id,valor,vacio)
      .then( resp => {
        this._modalUploadService.notificacion.emit(resp);
        this.cargarVirtual();
      })
      .catch( err => {
        console.log('Error en la carga... ');
      });
  }

 
  actualizaTema( subtitulo: Subtitulo ){
    //this.subtitulo.subtitulo = subtitulo.subtitulo;
    if(subtitulo.img1){
      let codigo = subtitulo.img1.split('/');
      this.subtitulo.img1 = codigo[5];
    }
    
    this.subtitulo.url = subtitulo.url;
    //this.subtitulo.plantilla = subtitulo.plantilla;
    this._virtualService.agregarSubtituloModificado(this.identificador, this.subtitulo).subscribe( resp => {      
      this.cargarVirtual();
      this.subtitulo=new Subtitulo('','','','',0,'');
    });
  }
  cargarDatos(){
    this._materialService.cargarAulaIdd( this.identificador2 )
        .subscribe( (resp: any) => {
          this.material = resp.material[0];
          //console.log(this.material);
        })
  }

  modEnlace( v: Virtual){
    this.video.zoom = v.zoom;
    this._virtualService.zoomaVirtual(this.identificador, this.video).subscribe( resp => {
      this.cargarVirtual();
    });
  }
  AgreGrabacion1( v: Virtual){
    this.video.grabacion1 = v.grabacion1;
    this._virtualService.zoomaVirtual(this.identificador, this.video).subscribe( resp => {
      this.cargarVirtual();
    });
  }
  AgreGrabacion2( v: Virtual){
    this.video.grabacion2 = v.grabacion2;
    this._virtualService.zoomaVirtual(this.identificador, this.video).subscribe( resp => {
      this.cargarVirtual();
    });
  }
  AgreGrabacion3( v: Virtual){
    this.video.grabacion3 = v.grabacion3;
    this._virtualService.zoomaVirtual(this.identificador, this.video).subscribe( resp => {
      this.cargarVirtual();
    });
  }

  actualizaSubtitulo( subtitulo: Subtitulo, id: string, subt: string ){
    this.subtitulo.subtitulo = subtitulo.subtitulo;
    this.subtitulo.concepto = subtitulo.concepto;
    this.subtitulo.url = subtitulo.url;
    this.subtitulo.img1 = subtitulo.img1;
    //this.subtitulo.concepto2 = subtitulo.concepto2;
    //this.subtitulo.img2 = subtitulo.img2;
    //this.subtitulo.url2 = subtitulo.url2;
    //if(this.subtitulo.concepto2){
      if(this.subtitulo.img1){
        if(this.subtitulo.concepto.indexOf('base64') >= 0 ){
          Swal.fire('Ups!', 'Se ha detectado que esta intentando subir una imagen o video de manera inapropiada, por favor revise el tutorial', 'error');
        }else{
          if(this.subtitulo.img1.indexOf('youtu.be') >= 0){
            this.subtitulo.url = subtitulo.img1;
          }
          this._virtualService.updateSubtitulo(id,subt,this.subtitulo)
          .subscribe( (resp: any) => {
            this.cargarVirtual();
          });
        }
      }else{
        if(this.subtitulo.concepto.indexOf('<img') >= 0 ){
          Swal.fire('Ups!', 'Se ha detectado que esta intentando subir una imagen o video de manera inapropiada, por favor revise el tutorial', 'error');
        }else{
          this._virtualService.updateSubtitulo(id,subt,this.subtitulo)
          .subscribe( (resp: any) => {
            this.cargarVirtual();
          });
        }
      }      
      
    /*}else{      
      if(this.subtitulo.concepto.indexOf('<img') >= 0 ){
        Swal.fire('Ups!', 'Se ha detectado que esta intentando subir una imagen o video de manera inapropiada, por favor revise el tutorial', 'error');
      }else{
        console.log(this.subtitulo.img1);
        if(this.subtitulo.img1){
          console.log("hola");
          if(this.subtitulo.img1.indexOf('youtu.be') >= 0){
            this.subtitulo.url = subtitulo.img1;
          }
          this._virtualService.updateSubtitulo(id,subt,this.subtitulo)
          .subscribe( (resp: any) => {
            this.cargarVirtual();
          });
        }else{
          this._virtualService.updateSubtitulo(id,subt,this.subtitulo)
          .subscribe( (resp: any) => {
            this.cargarVirtual();
          });
        }        
      }
    }*/
    
    
  }
  agreagaConsulta( consulta: Consulta, id: string ){
    this.consulta.descripcion = consulta.descripcion;
    this.consulta.personal = this.personal._id;
    //console.log(this.consulta);
    console.log(this.consulta);
    console.log(id);
    this._virtualService.agregarConsulta(id,this.consulta)
      .subscribe( (resp: any) => {
        this.cargarVirtual();
      });
  }
 

}


export class Personal {
    constructor(
        public user: string,
        public institucion: string,
        public role: string,
        public gestion: string,
        public hijo?: string,
        public listacursos?: string,
        public created?: string,
        public gestiones?: any,
        public estado?: boolean,
        public cosas1?: string,
        public cosas2?: string,
        public _id?: string
    ){}
}

 
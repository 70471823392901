import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'truncar'
})
export class TruncarPipe implements PipeTransform {

    transform(value: string, limit: number): string {
        return value.length < limit
          ? value
          : value.slice(0, limit) + '...';
      }

}
export class Virtual {
    constructor(
        public material: string,    
        public titulo: string,
        public bimestre:  number,
        public unidad?: string, 
        public imgtema?: string,   
        public contenido?: string,
        public programacion?: string,
        public evaluacion?: string,
        public disponible?: boolean,
        public doc1?:string,
        public doc2?:string,
        public doc3?:string,
        public created?: string,
        public video?:string,
        public audio?:string,
        public foro?:string,
        public metodologica?: string,
        public recurso?: string,
        public criterio?: string,
        public producto?: string,
        public bibliografia?: string,
        public sesion?: number,
        public tematica?: string,
        public objhol?: string,
        public zoom?: string,
        public grabacion1?: string,
        public grabacion2?: string,
        public grabacion3?: string,
        public _id?: string
    ){}
}

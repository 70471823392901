import { RouterModule, Routes } from "@angular/router";
import { Input } from '@angular/core';

import { Institucion } from "../models/institucion.model";

import { PagesComponent } from './pages.component';

import { PrincipalComponent } from './institucion/principal/principal.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ListaComponent } from './lista/lista.component';
import { LoginGuardGuard } from '../services/service.index';
import { CrearComponent } from './institucion/crear/crear.component';
import { ListaInstitucionesComponent } from './institucion/lista-instituciones/lista-instituciones.component';
import { PersonaComponent } from './institucion/persona/persona.component';
import { ModificarComponent } from './institucion/modificar/modificar.component';
import { ListappffComponent } from './institucion/secretaria/listappff.component';
import { PadrehijoComponent } from './institucion/secretaria/padrehijo.component';
import { CursoshabilitadosComponent } from './institucion/secretaria/cursoshabilitados.component';
import { ListaestudiantesporcursoComponent } from './institucion/secretaria/listaestudiantesporcurso.component';
import { ZoomComponent } from './lista/zoom.component';
import { CorreccionComponent } from "./institucion/secretaria/correccion.component";
import { CursosComponent } from "./institucion/cuadernoPedagogico/cursos.component";
import { CursoscomplementarioComponent } from "./institucion/secretaria/cursoscomplementario.component";
import { ElcursoComponent } from "./institucion/cuadernoPedagogico/elcurso.component";
import { RegistroOficialComponent } from "./institucion/secretaria/registro-oficial.component";
import { VerCursoComponent } from "./institucion/secretaria/ver-curso.component";
import { BoletinComponent } from "./institucion/secretaria/boletin.component";
import { ListaVentanillaComponent } from "./institucion/ventanilla/lista-ventanilla.component";
import { ConfigurarComponent } from "./institucion/ventanilla/configurar.component";
import { MateriaDocenteComponent } from "./institucion/cuadernoPedagogico/materia-docente.component";
import { ModificarCuadernoComponent } from "./institucion/cuadernoPedagogico/modificar-cuaderno.component";



const pagesRoutes: Routes = [    
    {
        path: '', 
        component: PagesComponent,  
        canActivate: [ LoginGuardGuard ],
        canLoad: [ LoginGuardGuard ],                                
        children: [
            //{path: ':nombre', component: PrincipalComponent}, 
            {path: 'dashboard', component: DashboardComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            //{path: 'listas', component: ListaComponent},
            {path: 'listas', component: ZoomComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'crear-institucion', component: CrearComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'lista-instituciones', component: ListaInstitucionesComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'lista-personas', component: PersonaComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'modificar-institucion/:id', component: ModificarComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'lista-padre-familia', component: ListappffComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'lista-dependencia-hijo/:id/:user', component: PadrehijoComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'registro-curso-estudiante/:id', component: CursoshabilitadosComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'correcion-datos/:id', component: CorreccionComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'cursos-complementarios', component: CursoscomplementarioComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'lista-curso-complementario/:id', component: ListaestudiantesporcursoComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'listas-cursos', component: CursosComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'curso/:curso', component: ElcursoComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'listas-oficiales', component: RegistroOficialComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'ver-curso/:id', component: VerCursoComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'boletines/:id/:codigo/:curso', component: BoletinComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'ventanilla', component: ListaVentanillaComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'correspondencia-modificar/:id', component: ConfigurarComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'materia-docente/:id', component: MateriaDocenteComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'modificar-cuaderno/:id', component: ModificarCuadernoComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: '', redirectTo: '/plataforma/dashboard', pathMatch: 'full'},
            
        ]
    }
];

export const PAGES_ROUTES = RouterModule.forChild( pagesRoutes );
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { USER_ROUTES } from './usuario.routes';

import { SharedModule } from '../shared/shared.module';

import { UsuarioComponent } from './usuario.component';



import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PipesModule } from '../pipes/pipes.module';
import { PerfilComponent } from './perfil/perfil.component';
import { ProfileComponent } from './profile/profile.component';
import { NgxDocViewerModule } from "ngx-doc-viewer";



@NgModule({
  declarations: [
    UsuarioComponent,
    PerfilComponent,
    ProfileComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    USER_ROUTES,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDocViewerModule
  ],
  exports: [
    UsuarioComponent
  ]
})
export class UsuarioModule { }

import { Injectable } from '@angular/core';
import { User } from '../../models/user.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from '../../config/config';

import { map, take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Personal } from '../../models/personal.model';
import { SubirArchivoService } from '../service.index';
import { Virtual } from "../../models/virtual.model";
import { Subtitulo } from "../../models/subtitulo.model";
import { Consulta } from "../../models/consulta.model";
import { Enlace } from "../../models/enlace.model";
import { Tareaemitida } from "../../models/tareaemitida.model";
import { Programacion } from '../../models/programacion.model';
import { Evaluacion } from '../../models/evaluacion.model';
import { Preguntarea } from '../../models/preguntarea.model';
import { Tareapresentada } from '../../models/tareapresentada.model';
import { Respuesta } from '../../models/respuesta.model';
import { Calificaciontarea } from '../../models/calificaciontarea.model';


@Injectable({
  providedIn: 'root'
})
export class VirtualService {
  token: string;
  user: User;
  persona: Personal;
  virtual: Virtual;
  tarea: Tareaemitida;

  constructor(
    public http: HttpClient,
    public router: Router,
    public _subirArchivoService: SubirArchivoService
  ) {
    this.cargarStorage();
   }

  cargarStorage(){
    if( localStorage.getItem('token') ){
      this.token = localStorage.getItem('token');
      this.user = JSON.parse( localStorage.getItem('usuario') );
      this.persona = JSON.parse( localStorage.getItem('personal') );
    }else{
      this.token = '';
    }
  }

  cargarVirtualSin(material){
    let url = URL_SERVICIOS + '/virtualM/'+material;
    
    return this.http.get( url )
    .pipe(map((resp:any) => {
      return resp.virtual;
    }));
    
  }

  cargarVirtualSinStud(material){
    let url = URL_SERVICIOS + '/virtualMStud/'+material;
    
    return this.http.get( url )
    .pipe(map((resp:any) => {
      return resp.virtual;
    }));
    
  }

  cargarTemaVirtual(id){
    let url = URL_SERVICIOS + '/temaVirtual/'+id;
    
    return this.http.get( url )
    .pipe(map((resp:any) => {
      return resp.virtual;
    }));
    
  }

  cargarEvaluacionVirtual(id){
    let url = URL_SERVICIOS + '/temaVirtualEvaluacion/'+id;
    
    return this.http.get( url )
    .pipe(map((resp:any) => {
      return resp.virtual;
    }));
    
  }
  cargarpreguntas(id){
    let url = URL_SERVICIOS + '/preguntasEvaluacion/'+id;
    
    return this.http.get( url )
    .pipe(map((resp:any) => {
      return resp.virtual;
    }));
    
  }
  
  crearVirtual( virtual: Virtual ){
    let url = URL_SERVICIOS + '/virtual';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    
    return this.http.post(url, virtual, httpOptions).pipe(map( (resp: any) => {
        Swal.fire('Creando Tema Virtual', 'Creaste un nuevo tema virtual', 'success');
        return resp.virtual;
      }));
  }

  agregarSubtitulo( id:string, subtitulo : Subtitulo ){
    let url = URL_SERVICIOS + '/agregaSubtitulo/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.put(url, subtitulo, httpOptions).pipe(map( (resp: any) => {
      let usuarioDB: User = resp.usuario;
      Swal.fire('Subtitulo Agregado', subtitulo.subtitulo, 'success');
      return resp.virtual;
    }));
  }
  agregarSubtituloModificado( id:string, subtitulo : Subtitulo ){
    let url = URL_SERVICIOS + '/agregaSubtituloModificado/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.put(url, subtitulo, httpOptions).pipe(map( (resp: any) => {
      let usuarioDB: User = resp.usuario;
      Swal.fire('Contenido Agregado', subtitulo.subtitulo, 'success');
      return resp.virtual;
    }));
  }

  agregarPregTarea( id:string, pregunta : Preguntarea ){
    let url = URL_SERVICIOS + '/agregaPregTarea/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.put(url, pregunta, httpOptions).pipe(map( (resp: any) => {
      //let usuarioDB: User = resp.usuario;
      Swal.fire('Cambios realizados', 'Muy bien, se ha realizado los cambios', 'success');
      //console.log(resp);
      return resp.tarea;
    }));
  }
  ModTarea( id:string, tarea : Tareaemitida ){
    let url = URL_SERVICIOS + '/virtuatareaMod/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.put(url, tarea, httpOptions).pipe(map( (resp: any) => {
      //let usuarioDB: User = resp.usuario;
      Swal.fire('Cambios realizados', 'Muy bien, se ha realizado los cambios', 'success');
      //console.log(resp);
      return resp.tarea;
    }));
  }
  agregarRespuesta( respuesta : Tareapresentada ){
    let url = URL_SERVICIOS + '/respuestarea';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    
    return this.http.post(url, respuesta, httpOptions).pipe(map( (resp: any) => {
        //Swal.fire('Tarea Guardada', 'Se guardo la tarea con exito', 'success');
        return resp.tarea;
      }));
  }

  modificarRespuesta( respuesta : Tareapresentada, id:string ){
    let url = URL_SERVICIOS + '/respuestareamodificar/' + id;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    
    return this.http.put(url, respuesta, httpOptions).pipe(map( (resp: any) => {
        
        return resp.tarea;
      }));
  }

  presentarTarea(id:string){
    let url = URL_SERVICIOS + '/entregarTarea/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.put( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Tarea entregada', 'La tarea ha sido presentada al docente satisfactoriamente', 'success');
      return resp.tarea;
    }));
  }

  agregarPreguntas( id:string, respuesta : Respuesta ){
    let url = URL_SERVICIOS + '/agregaPreguntasaleatorias/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.put(url, respuesta, httpOptions).pipe(map( (resp: any) => {
      Swal.fire('Evaluación Generada', 'Se ha asignado las preguntas', 'success');
      return resp.virtual;
    }));
  }
  guardarRespuestas( id:string, evaluacion: string, subtitulo : Respuesta  ){
    let url = URL_SERVICIOS + '/agrearespuesta/' + id + '/'+ evaluacion;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.put(url, subtitulo, httpOptions).pipe(map( (resp: any) => {
      //let usuarioDB: User = resp.usuario;
      Swal.fire('Resultados enviados', 'Espere los resultados', 'success');
      return resp.virtual;
    }));
  }
  guardarIntentos( id:string, evaluacion: string, numero : string  ){
    let url = URL_SERVICIOS + '/agregarintento/' + id + '/'+ evaluacion + '/' + numero;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.put( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Ups!!! se termino el tiempo', 'Se ha registrado un intento, solo se permite dos intentos', 'info');
      return true;
    }));
  }

  agregarCalificacion( id:string, respuesta : Tareapresentada ){
    let url = URL_SERVICIOS + '/agregaCalificacion/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.put(url, respuesta, httpOptions).pipe(map( (resp: any) => {
      Swal.fire('Calificación asignada', 'Se ha asignado la calificación', 'success');
      return resp.virtual;
    }));
  }
  generarCalificacion( respuesta : any ){
    let url = URL_SERVICIOS + '/calificarTarea';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.post(url, respuesta, httpOptions).pipe(map( (resp: any) => {
      Swal.fire('Muy bien', 'Se ha procedido a generar las calificaciones', 'success');
      return resp.ok;
    }));
  }

  consultarGenerado(tarea){
    let url = URL_SERVICIOS + '/calificarTarea/'+tarea;
    
    return this.http.get( url )
    .pipe(map((resp:any) => {
      return resp.calificacion;
    }));    
  }

  consultarCalificaciones(curso,materia){
    let url = URL_SERVICIOS + '/calificacionTrimestral/'+curso+'/'+materia;
    
    return this.http.get( url )
    .pipe(map((resp:any) => {
      return resp.calificaciones;
    }));    
  }

  consultarCalificaciones2(curso,materia, trimestre){
    let url = URL_SERVICIOS + '/calificacionTrimestral2/'+curso+'/'+materia+'/'+trimestre;
    
    return this.http.get( url )
    .pipe(map((resp:any) => {
      return resp.calificaciones;
    }));    
  }

  consultarCentralizadorTrimestral(curso,trimestre){
    let url = URL_SERVICIOS + '/centralizadorTrimestral/'+curso+'/'+trimestre;
    
    return this.http.get( url )
    .pipe(map((resp:any) => {
      return resp.calificaciones;
    }));    
  }

  consultarBoletines(id,codigo){
    let url = URL_SERVICIOS + '/calificacionBoletin/'+id+'/'+codigo;
    
    return this.http.get( url )
    .pipe(map((resp:any) => {
      return resp.calificaciones;
    }));    
  }
  

  consultarGeneradoNota(tarea:string,trimestre:number,dimension:string){
    let url = URL_SERVICIOS + '/calificacionCriterio/'+tarea+'/'+trimestre+'/'+dimension;
    
    return this.http.get( url )
    .pipe(map((resp:any) => {
      console.log(resp);
      return resp.calificacion;
    }));    
  }

  guardarCalificaciones( respuesta : any ){
    let url = URL_SERVICIOS + '/reguardarCalificacionesTrimestrales';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.post(url, respuesta, httpOptions).pipe(map( (resp: any) => {
      Swal.fire('Muy bien', 'Se ha guardado la información', 'success');
      return resp.ok;
    }));
  }

  agregarProgramacion( id:string, programacion : Programacion ){
    let url = URL_SERVICIOS + '/agregaProgamacion/' + id;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    //console.log(tarea);
    return this.http.post(url, programacion, httpOptions).pipe(map( (resp: any) => {
        Swal.fire('Creando Evaluación', 'Creaste una nueva evaluación virtual', 'success');
        return resp.virtual;
      }));


    /*let url = URL_SERVICIOS + '/agregaProgamacion/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.put(url, programacion, httpOptions).pipe(map( (resp: any) => {
      let usuarioDB: User = resp.usuario;
      Swal.fire('Programación de evaluación agregada', programacion.titulo, 'success');
      return resp.virtual;
    }));*/
  }
  agregarPregunta( id:string, preguntas : Evaluacion ){
    let url = URL_SERVICIOS + '/agregaPregunta/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.put(url, preguntas, httpOptions).pipe(map( (resp: any) => {
      //let usuarioDB: User = resp.usuario;
      Swal.fire('Preguntas a evaluación agregada', preguntas.pregunta, 'success');
      return resp.virtual.preguntas[resp.virtual.preguntas.length-1];
    }));
  }

  updateSubtitulo( id:string, subt: string, subtitulo : Subtitulo  ){
    let url = URL_SERVICIOS + '/updateSubtitulo/' + id + '/'+ subt;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.put(url, subtitulo, httpOptions).pipe(map( (resp: any) => {
      let usuarioDB: User = resp.usuario;
      Swal.fire('Subtitulo Modificado', subtitulo.subtitulo, 'success');
      return resp.virtual;
    }));
  }
  agregarConsulta( id:string, consulta : Consulta ){
    let url = URL_SERVICIOS + '/agregaConsulta/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.put(url, consulta, httpOptions).pipe(map( (resp: any) => {
      //let usuarioDB: User = resp.usuario;
      Swal.fire('Pregunta Agregada', consulta.descripcion, 'success');
      return resp.virtual;
    }));
  }
  modificarVirtual( id:string, consulta : Virtual ){
    let url = URL_SERVICIOS + '/virtualupadte/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.put(url, consulta, httpOptions).pipe(map( (resp: any) => {
      //let usuarioDB: User = resp.usuario;
      Swal.fire('Muy bien', 'Se ha guardado los cambios', 'success');
      return resp.virtual;
    }));
  }
  zoomaVirtual( id:string, consulta : Enlace ){
    let url = URL_SERVICIOS + '/virtualupadte/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.put(url, consulta, httpOptions).pipe(map( (resp: any) => {
      //let usuarioDB: User = resp.usuario;
      Swal.fire('Muy bien', 'Se ha guardado los cambios', 'success');
      return resp.virtual;
    }));
  }

  borrarVirtual( id: string ){
    let url = URL_SERVICIOS + '/virtual/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.delete( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Se ha eliminado', 'El tema virtual', 'success');
      return true;
    }));
  }

  borrarTarea( id: string ){
    let url = URL_SERVICIOS + '/tarea/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.delete( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Se ha eliminado', 'La tarea virtual', 'success');
      return true;
    }));
  }

  borrarSubtitulo( id: string, subt: string ){
    let url = URL_SERVICIOS + '/borrarSubtitulo/' + id + '/' + subt;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.delete( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Se ha eliminado', 'El subtitulo', 'success');
      return true;
    }));
  }

  borrarVirtualSubtitulo( id: string, subt: string ){
    let url = URL_SERVICIOS + '/virtualSub/' + id +'/'+subt;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.delete( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Se ha eliminado', 'El tema virtual', 'success');
      return true;
    }));
  }

  onlineVirtual( id: string ){
    let url = URL_SERVICIOS + '/virtualOnline/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.delete( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Tema Online', 'El tema virtual esta en linea', 'success');
      return true;
    }));
  }

  offlineVirtual( id: string ){
    let url = URL_SERVICIOS + '/virtualOffline/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.delete( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Tema Offline', 'El tema virtual esta fuera de linea', 'success');
      return true;
    }));
  }

  onlineTarea( id: string ){
    let url = URL_SERVICIOS + '/tareaOnline/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.delete( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Tema Online', 'La tarea virtual esta en linea', 'success');
      return true;
    }));
  }

  offlineTarea( id: string ){
    let url = URL_SERVICIOS + '/tareaOffline/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.delete( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Tarea Offline', 'La tarea virtual esta fuera de linea', 'success');
      return true;
    }));
  }
  offlineTareaaa( id: string ){
    let url = URL_SERVICIOS + '/tareaOfflineee/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.delete( url, httpOptions ).pipe(map( (resp: any) => {
      //Swal.fire('Tarea Offline', 'La tarea virtual esta fuera de linea', 'success');
      return true;
    }));
  }
  offlineEvaluacion( id: string ){
    let url = URL_SERVICIOS + '/evaluacionOffline/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.delete( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Evaluación Offline', 'La evaluación virtual esta fuera de linea', 'success');
      return true;
    }));
  }
  onlineEvaluacion( id: string){
    let url = URL_SERVICIOS + '/evaluacionOnline/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.delete( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Tema Online', 'La tarea virtual esta en linea', 'success');
      return true;
    }));
  }

  borrarVirtualEvaluacion( id: string ){
    let url = URL_SERVICIOS + '/evaluacion/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.delete( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Se ha eliminado', 'La evaluación virtual', 'success');
      return true;
    }));
  }

  cargarTareas( desde: number = 0, virtual: string ){
    let limite = 5;
    let url = URL_SERVICIOS + '/tareaemitida/'+virtual+'?limite='+limite+'&desde='+desde;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }

  cargarLaTarea( tarea: string ){
    let url = URL_SERVICIOS + '/tareaVer/'+tarea;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }

  cargarTareaPresentada( personal: string, tarea: string ){
    let url = URL_SERVICIOS + '/tareaspresentadas/'+personal+'/'+tarea;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }
  unaTareaPresentada( id: string ){
    let url = URL_SERVICIOS + '/unatareapresentada/'+id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }
  verTareapresentada( virtual: string, tarea: string ){
    let url = URL_SERVICIOS + '/verpresentadas/'+virtual+'/'+tarea;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }

  crearTarea( tarea: Tareaemitida ){
    let url = URL_SERVICIOS + '/virtualtarea';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    //console.log(tarea);
    return this.http.post(url, tarea, httpOptions).pipe(map( (resp: any) => {
        Swal.fire('Creando Tarea', 'Creaste una nueva tarea virtual', 'success');
        return resp.tarea;
      }));
  }

  unaOportunidad( id: string ){
    let url = URL_SERVICIOS + '/oportunidad/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.delete( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Solicitud completada', 'Se ha asignado una oportunidad al estudiante', 'success');
      return true;
    }));
  }

  borrarTareaEnviada( id: string ){
    let url = URL_SERVICIOS + '/borrartareaEnviada/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.delete( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Se ha eliminado', 'La tarea seleccionada', 'success');
      return true;
    }));
  }

  cargarHorario(id){
    let url = URL_SERVICIOS + '/miHorario/'+id;
    
    return this.http.get( url )
    .pipe(map((resp:any) => {
      return resp.mihorario;
    }));
    
  }


}

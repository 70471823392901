import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CursoService, PersonalService } from '../../../services/service.index';
import { Listas } from '../../../models/listas.model';
import { Personal } from '../../../models/personal.model';
import { Curso } from '../../../models/curso.model';

@Component({
  selector: 'app-ver-curso',
  templateUrl: './ver-curso.component.html',
  styles: [
  ]
})
export class VerCursoComponent implements OnInit {
  public identificador : string;
  personas: Personal[] = []; 
  miLista2: Listas[] = [];
  cargando: boolean = true;
  dtOptions: DataTables.Settings = {};
  curso= new Curso('','',0,'');
  

  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public _personalService: PersonalService,
    public _cursoService: CursoService
  ) { 
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
    });
    this.dtOptions = {
      pagingType: 'simple',
      pageLength: 50,
      language: {
        url: '//cdn.datatables.net/plug-ins/1.12.0/i18n/es-ES.json'
      },
      order: [[1, 'asc']]
    };
  }

  ngOnInit(): void {
    this.cargarEstudiantesCurso("2023");
    this.cargarCurso(this.identificador);
  }

  cargarCurso(id: string){
    this._cursoService.cargarId(id)
      .subscribe((resp:any) => {
        this.curso = resp;
      })
  }
  cargarEstudiantesCurso(gestion:string){
    this._personalService.cargarEstudianteCursoComplementariolistas( this.identificador, gestion )
        .subscribe( (resp: any) => {
          this.personas = resp.personal;       
          //console.log( this.personas);   
          for(let i=0;i<this.personas.length;i++){            
            if(this.personas[i].gestiones.length===3){
              if(this.personas[i].gestiones[2].cursos===this.identificador ){
                this.miLista2.push({
                  'user' : this.personas[i].user['_id'],
                  'personal': this.personas[i]._id,
                  'paterno' : this.personas[i].user['paterno'],
                  'materno' : this.personas[i].user['materno'],
                  'nombres' : this.personas[i].user['nombres'],
                  'email' : this.personas[i].user['email'],
                  'cedula' : this.personas[i].user['cedula'],
                  'zona' : this.personas[i].user['zona'],
                  'calle' : this.personas[i].user['calle'],
                  'padre' : this.personas[i].user['padre'],
                  'celpadre' : this.personas[i].user['celpadre'],
                  'madre' : this.personas[i].user['madre'],
                  'celmadre' : this.personas[i].user['celmadre'],
                  'estado' : this.personas[i].estado
                });
              }              
            }else if(this.personas[i].gestiones.length===2){
              if(this.personas[i].gestiones[1].cursos===this.identificador){
                this.miLista2.push({
                  'user' : this.personas[i].user['_id'],
                  'personal': this.personas[i]._id,
                  'paterno' : this.personas[i].user['paterno'],
                  'materno' : this.personas[i].user['materno'],
                  'nombres' : this.personas[i].user['nombres'],
                  'email' : this.personas[i].user['email'],
                  'cedula' : this.personas[i].user['cedula'],
                  'zona' : this.personas[i].user['zona'],
                  'calle' : this.personas[i].user['calle'],
                  'padre' : this.personas[i].user['padre'],
                  'celpadre' : this.personas[i].user['celpadre'],
                  'madre' : this.personas[i].user['madre'],
                  'celmadre' : this.personas[i].user['celmadre'],
                  'estado' : this.personas[i].estado
                });
              }              
            }else{
              if(this.personas[i].gestiones[0].cursos===this.identificador){
                this.miLista2.push({
                  'user' : this.personas[i].user['_id'],
                  'personal': this.personas[i]._id,
                  'paterno' : this.personas[i].user['paterno'],
                  'materno' : this.personas[i].user['materno'],
                  'nombres' : this.personas[i].user['nombres'],
                  'email' : this.personas[i].user['email'],
                  'cedula' : this.personas[i].user['cedula'],
                  'zona' : this.personas[i].user['zona'],
                  'calle' : this.personas[i].user['calle'],
                  'padre' : this.personas[i].user['padre'],
                  'celpadre' : this.personas[i].user['celpadre'],
                  'madre' : this.personas[i].user['madre'],
                  'celmadre' : this.personas[i].user['celmadre'],
                  'estado' : this.personas[i].estado
                });
              } 
            }         
          }
          this.cargando = false;
        })
  }

  verBoletin(id: string, codigo: string) {
    this.router.navigate(['/plataforma/boletines', id, codigo, this.identificador]);    
  }

}

import { Injectable } from '@angular/core';
import { User } from '../../models/user.model';
import { Reserva } from '../../models/reserva.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from '../../config/config';

import { map, take, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Personal } from '../../models/personal.model';
import { SubirArchivoService } from '../service.index';
import { Observable, of, pipe } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  id: string;
  user: User;
  token: string;
  menu: any[] = [];
  personal: Personal;

  constructor(
    public http: HttpClient,
    public router: Router,
    public _subirArchivoService: SubirArchivoService
  ) { 
    this.cargarStorage();
  }
  
  validarToken(): Observable<boolean>{
    const url = URL_SERVICIOS + '/revalidarToken';
    const headers = new HttpHeaders()
    .set('Authorization', localStorage.getItem('token') || '');
    return this.http.get(url, {headers}).pipe(
      map( (resp: any) => {
        localStorage.setItem('token', resp.token! );
        return resp.ok;
      }),catchError(err => of(false))
    );
  }  
  cargarStorage(){
    if( localStorage.getItem('token') ){
      this.token = localStorage.getItem('token');
      this.user = JSON.parse( localStorage.getItem('usuario') );
      this.id = localStorage.getItem('id');
      this.menu = JSON.parse( localStorage.getItem('menu') );
      this.personal = JSON.parse( localStorage.getItem('personal') );
    }else{
      this.token = '';
      this.user = null;
      this.id = '';
      this.menu = [];
      this.personal = null;
    }
  }

  

  guardarStorage( id: string, token: string, user: User, menu: any, personal: Personal ){
    localStorage.setItem('id', id);
    localStorage.setItem('token', token);
    localStorage.setItem('usuario', JSON.stringify(user));
    localStorage.setItem('menu', JSON.stringify(menu));
    localStorage.setItem('personal', JSON.stringify(personal));

    
    this.user = user;
    this.token = token;
    this.id = id;
    this.menu = menu;
    this.personal = personal;
  }

  login( user: User ){
    localStorage.clear();
    let url = URL_SERVICIOS + '/login';
    return this.http.post(url, user)
          .pipe(
            map( (resp:any) => {
              this.guardarStorage( resp.id, resp.token, resp.usuario, resp.menu, resp.personal );            
              return resp;
          }),catchError(err => {
            return of(err.error.err);
          })
      );
  }

  
  logout(){
    this.user = null;
    this.token = '';
    this.id = '';
    this.menu = [];
    this.personal = null;

    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    localStorage.removeItem('id');
    localStorage.removeItem('menu');
    localStorage.removeItem('personal');
    

    this.router.navigate(['/index']);
  }

  crearUser( user: User){
    let url = URL_SERVICIOS + '/user-clientes';

    return this.http.post(url, user).pipe(map( (resp: any) => {
        Swal.fire('Usuario creado', user.email, 'success');
        return resp.usuario;
      }));
  }

  crearReserva( reserva: Reserva){
    let url = URL_SERVICIOS + '/reserva';

    return this.http.post(url, reserva).pipe(map( (resp: any) => {
        Swal.fire('Registro realizado', 'Usted debe apersonarse a instalaciones de la Unidad Educativa Tomas Frias para realizar la firma del contrato y entregar la boleta del deposito al banco bisa', 'success');
        return resp.reserva;
      }));
  }

  cargarReserva( desde: number = 0 ){
    let limite = 10;
    let url = URL_SERVICIOS + '/reservas?limite='+limite+'&desde='+desde;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }
  cargarReservaT(){
    let url = URL_SERVICIOS + '/reservatotal';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }
  cargarReservaNivel(){
    let url = URL_SERVICIOS + '/reservanivel';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }
  cargarReservaInicial(){
    let url = URL_SERVICIOS + '/reservainicial';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }
  cargarReservaPrim(){
    let url = URL_SERVICIOS + '/reservaprim';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }
  cargarReservaSec(){
    let url = URL_SERVICIOS + '/reservaSec';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }

  borrarEstudiante( id: string ){
    let url = URL_SERVICIOS + '/reserva/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.delete( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Se ha eliminado', 'el registro seleccionado', 'success');
      return true;
    }));
  }

  crearUserInstitucion( user: User){
    let url = URL_SERVICIOS + '/user-clientes-institucion';

    return this.http.post(url, user).pipe(map( (resp: any) => {
        Swal.fire('Usuario creado', user.paterno, 'success');
        return resp.usuario;
      }));
  }

  cargarUser( desde: number = 0 ){
    let limite = 5;
    let url = URL_SERVICIOS + '/user?limite='+limite+'&desde='+desde;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }

  cargarUserioppff( desde: number = 0 ){
    let limite = 5;
    let url = URL_SERVICIOS + '/useriospadre?limite='+limite+'&desde='+desde;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }

  buscarUser( termino: string ){

    let url = URL_SERVICIOS + '/coleccion/user/' + termino;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.get(url, httpOptions).pipe(map( (resp: any) => {
      return resp.user;
    }));
  }

  actualizarUser( user: User ){
    let url = URL_SERVICIOS + '/user/' + user._id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.put(url, user, httpOptions).pipe(map( (resp: any) => {
      let usuarioDB: User = resp.usuario;
      this.guardarStorage(usuarioDB._id, this.token, usuarioDB, this.menu, this.personal);
      Swal.fire('Datos del usuario actualizados', usuarioDB.username, 'success');
      return true;
    }));
  }
  enviarZoom( role: number, meetingNumber: number ){
    let url = URL_SERVICIOS + '/zoom/' + role + '/' + meetingNumber;;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.post( url, httpOptions ).pipe(map( (resp: any) => {
      return resp.signature;
    }));
  }
  actualizarUserPass( user: User ){
    let url = URL_SERVICIOS + '/userPass/' + user._id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.put(url, user, httpOptions).pipe(map( (resp: any) => {
      let usuarioDB: User = resp.usuario;
      this.guardarStorage(usuarioDB._id, this.token, usuarioDB, this.menu, this.personal);
      Swal.fire('Password Modificado', usuarioDB.username, 'success');
      return true;
    }));
  }
  actualizarLS(imagen:string, id: string){
    this.user.profileImageURL = imagen;
    this.guardarStorage(id, this.token, this.user, this.menu, this.personal)
  }
  /*------IMPORTANTE ------------- dar de baja esta funcion
  CambiarImagen( archivo: File, id: string ){
    
    this._subirArchivoService.subirArchivo( archivo, id )
      .then( (resp: any) => {
        this.user.profileImageURL = resp.usuario.profileImageURL;
        Swal.fire('Imagen actualizada', this.user.nombres, 'success');
        this.guardarStorage(id, this.token, this.user, this.menu, this.personal)
      })
      .catch( resp => {
        console.log( resp );
      })
  }
  AgregarImagen( archivo: File, id: string ){
    this._subirArchivoService.subirArchivo( archivo,  id )
      .then( (resp: any) => {
        console.log(resp);
        Swal.fire('Muy Bien', 'Subiste una imagen a tu galeria', 'success');
        return resp;        
      })
      .catch( resp => {
        console.log( resp );
      })
  }
  ///////////////////////*/

  agregarHijo( hijo: string ){
    let url = URL_SERVICIOS + '/agregaHijo/' + this.personal[0]._id + '/' + hijo;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.put( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Hij@ cread@', 'Agregaste hij@ a tu registro', 'success');
      return resp.personal;
    }));
  }

  agregarHijoDependiente( hijo: string, padre: string ){
    let url = URL_SERVICIOS + '/agregaHijo/' + padre + '/' + hijo;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.put( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Hij@ cread@', 'Agregaste un estudiante al registro', 'success');
      return resp.personal;
    }));
  }

  cargarUsuario( id: string ){
    let url = URL_SERVICIOS + '/unUser/'+id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }

  cargarUnaReserva( id: string ){
    let url = URL_SERVICIOS + '/reservaest/'+id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }
  verficar( id: string ){
    let url = URL_SERVICIOS + '/reservaverifica/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.delete( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Actualización completa', 'Se ha completado la solicitud con exito', 'success');
      return true;
    }));
  }
}

import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { MaterialService, MateriaService } from '../../services/service.index';
import { Materia } from "../../models/materia.model";
import { Material } from "../../models/material.model";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contenido-estudiante',
  templateUrl: './contenido-estudiante.component.html',
  styles: []
})
export class ContenidoEstudianteComponent implements OnInit {
  identificador: string;
  materia: string;
  lamateria = new Materia('','','');
  material: Material[]=[];
  constructor(
    public _materiaService: MateriaService,
    public _materialService: MaterialService,
    public activatedRoute: ActivatedRoute,
    public router: Router
  ) {
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
      this.materia = params['materia'];
    });
  }

  ngOnInit() {
    this.cargarMateria();
  }

  cargarMateria(){
    this._materiaService.cargarMateriaId( this.materia )
        .subscribe( (resp: any) => {          
          this.lamateria = resp[0];
          this.cargarMaterial();
        })
  }
  cargarMaterial(){
    this._materialService.cargarAulaIdd( this.identificador )
        .subscribe( (resp: any) => {          
          this.material = resp.material[0];
        })
  }

  cargarTemas(){
    this.router.navigate(['/estudiante/temas-disponibles', this.identificador]);
  }
  cargarVivo(){
    if(this.material['zoom']===''){
      Swal.fire('Ups!', 'El profesor aun no agrego enlace, contactese con el profesor de materia', 'question');
    }else{
      window.open(this.material['zoom']);
    }
  }

}

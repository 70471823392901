import { Component, OnInit } from '@angular/core';
import { MaterialService, MateriaService, VirtualService } from '../../services/service.index';
import { ActivatedRoute } from '@angular/router';

import { Material } from '../../models/material.model';
import { Criterio } from '../../models/criterio.model';
import { Criteriocalificacion } from '../../models/criteriocalificacion.model';
import { Elemento } from '../../models/elemento.model';

@Component({
  selector: 'app-saber',
  templateUrl: './saber.component.html',
  styles: []
})
export class SaberComponent implements OnInit {
  title: string;
  identificador: string;
  materiale: Material[]=[];
  public material= new Material('','','');
  public cadena: string;
  public criterio2 : Criterio[] = [];
  public criteriocalificacion : Criteriocalificacion[] = [];
  public criteriocalificacion2 : Criteriocalificacion[] = [];
  public elemento : Elemento[] = [];
  memoria : string = '';
  cargando2: boolean;
  public ct=['ser','hacer','saber','decidir'];
  

  constructor(
    public _materialService: MaterialService,
    public activatedRoute: ActivatedRoute,
    public _materiaService: MateriaService,
    public _virtualService: VirtualService
  ) { 
    this.material= new Material('','','');
    this.title = "Calificaciones correspondiente a la dimensión del Saber";
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
    });
  }

  ngOnInit() {
    this.cargarMaterial();
    this.cargarNotas(this.identificador,'hacer',1);
  }

  cargarMaterial(){
    this._materialService.cargarAulaId( this.identificador )
    .subscribe( (resp: any) => {
      this.material = resp.material;
      this.materiale = resp.material;
      this.cargarCriterios();
    })
  }

  cargarCriterios(){
    this._materiaService.cargarCriterio( this.material['curso']['_id'], this.material['materia']['_id'] )
      .subscribe( (resp: any) => {
        this.criterio2 = resp;
      })
  }
  cargarNotas(id:string,dimension:string,trimestre:number){
    this.elemento =[];
    this.criteriocalificacion = [];
    console.log(trimestre);
    console.log(dimension);
    console.time();
    this.cargando2 = false;
    if(this.memoria !== id ){
      this.cadena = String(id);
      this._virtualService.consultarGeneradoNota( id, trimestre, dimension)
      .subscribe( (resp: any) => {
        console.log(resp);
        if(resp.length > 0){
          this.criteriocalificacion2 = resp;
          this.criteriocalificacion = resp;
          this.elemento = resp[0].tareatitulo;
          this.memoria = id;
          this.cargando2 = true;
          console.log(this.criteriocalificacion2);
          console.timeEnd();
        }else{
          this.elemento =[];
          this.criteriocalificacion = [];
          this.memoria = id;
          this.cargando2 = true;
        }        
      })
    }
    this.cargando2 = true;
  }

}

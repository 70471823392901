import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ESTUDIANTE_ROUTES } from './estudiante.routes';
import { SharedModule } from '../shared/shared.module';
import { PipesModule } from '../pipes/pipes.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EstudianteComponent } from './estudiante.component';
import { HttpClientModule } from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { MatVideoModule } from 'mat-video';
import { NgxCircularPlayerModule } from 'ngx-circular-player';
import { EstudianteMateriaComponent } from './estudiante-materia/estudiante-materia.component';
import { MateriaTemaComponent } from './estudiante-materia/materia-tema.component';
import { PasarTemaComponent } from './estudiante-materia/pasar-tema.component';
import { ClaseenvivostdComponent } from './estudiante-materia/claseenvivostd.component';
import { HacertareaComponent } from './estudiante-materia/hacertarea.component';
import { HacerevaluacionComponent } from './estudiante-materia/hacerevaluacion.component';
import { ContenidoEstudianteComponent } from './estudiante-materia/contenido-estudiante.component';
import {YouTubePlayerModule} from '@angular/youtube-player';

//import { PdfViewerModule } from 'ng2-pdf-viewer';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';



@NgModule({
  declarations: [
    EstudianteComponent,
    EstudianteMateriaComponent,
    MateriaTemaComponent,
    PasarTemaComponent,
    ClaseenvivostdComponent,
    HacertareaComponent,
    HacerevaluacionComponent,
    ContenidoEstudianteComponent    
],
exports: [
    EstudianteComponent
    
],
imports: [
    SharedModule,
    ESTUDIANTE_ROUTES,
    PipesModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    HttpClientModule, 
    AngularEditorModule,
    NgxDocViewerModule,
    MatVideoModule,
    NgxCircularPlayerModule,
    YouTubePlayerModule,
    //PdfViewerModule,
    MatProgressSpinnerModule
]
})
export class EstudianteModule { }

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import Swal from 'sweetalert2';
import { Institucion } from '../../../models/institucion.model';
import { InstitucionService } from '../../../services/service.index';
import { Router } from '@angular/router';


@Component({
  selector: 'app-crear',
  templateUrl: './crear.component.html',
  styles: []
})
export class CrearComponent implements OnInit {

  forma2: FormGroup;

  constructor(
    public _institucionService: InstitucionService,
    public router: Router
  ) { }

  ngOnInit() {
    this.forma2 = new FormGroup({
      nombre: new FormControl(null, Validators.required),
      alias: new FormControl(null, Validators.required),
      direccion: new FormControl(null, Validators.required)
    });
  }

  registrarInstitucion(){
    let institucion = new Institucion(
      this.forma2.value.nombre,
      this.forma2.value.alias,
      this.forma2.value.direccion
    );

    this._institucionService.crearInstitucion( institucion )
                    .subscribe( resp => this.router.navigate(['/plataforma/lista-instituciones']) );
  }

}

export class Respuesta {
    constructor(
        public personal?: string,              
        public pregunta1?: string,
        public respuesta1?:  string,
        public pregunta2?: string,
        public respuesta2?:  string,
        public pregunta3?: string,
        public respuesta3?:  string,
        public pregunta4?: string,
        public respuesta4?:  string,
        public pregunta5?: string,
        public respuesta5?:  string,
        public pregunta6?: string,
        public respuesta6?:  string,
        public pregunta7?: string,
        public respuesta7?:  string,
        public pregunta8?: string,
        public respuesta8?:  string,
        public pregunta9?: string,
        public respuesta9?:  string,
        public pregunta10?: string,
        public respuesta10?:  string,
        public intentos?: number,
        public enviado?: boolean, 
        public opa1?: string,
        public opa2?: string,
        public opa3?: string,
        public opa4?: string,
        public opa5?: string,
        public opa6?: string,
        public opa7?: string,
        public opa8?: string,
        public opa9?: string,
        public opa10?: string,
        public opb1?: string,
        public opb2?: string,
        public opb3?: string,
        public opb4?: string,
        public opb5?: string,
        public opb6?: string,
        public opb7?: string,
        public opb8?: string,
        public opb9?: string,
        public opb10?: string,
        public opc1?: string,
        public opc2?: string,
        public opc3?: string,
        public opc4?: string,
        public opc5?: string,
        public opc6?: string,
        public opc7?: string,
        public opc8?: string,
        public opc9?: string,
        public opc10?: string,
        public opd1?: string,
        public opd2?: string,
        public opd3?: string,
        public opd4?: string,
        public opd5?: string,
        public opd6?: string,
        public opd7?: string,
        public opd8?: string,
        public opd9?: string,
        public opd10?: string,
        public r1?: string,
        public r2?: string,
        public r3?: string,
        public r4?: string,
        public r5?: string,
        public r6?: string,
        public r7?: string,
        public r8?: string,
        public r9?: string,
        public r10?: string,
        public p1?: string,
        public p2?: string,
        public p3?: string,
        public p4?: string,
        public p5?: string,
        public p6?: string,
        public p7?: string,
        public p8?: string,
        public p9?: string,
        public p10?: string,
        public corregido?: string,   
        public puntos?: number,
        public created?: string,
        public _id?: string
    ){}
}


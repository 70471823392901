import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/service.index';
import { User } from "../../../models/user.model";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-persona',
  templateUrl: './persona.component.html',
  styles: []
})
export class PersonaComponent implements OnInit {
  title: string;
  usuarios: User[] = [];
  limite: number = 0;
  desde: number = 0;
  totalRegistros: number = 0;
  cargando: boolean = true;

  constructor(
    public _userService: UserService
  ) {
    this.title = "Lista de personas registradas";
   }

  ngOnInit() {
    this.cargarUsuarios();
  }

  cargarUsuarios(){
    this._userService.cargarUser( this.desde )
        .subscribe( (resp: any) => {
          this.totalRegistros = resp.cuantos;
          this.usuarios = resp.usuarios;
          //console.log(this.totalRegistros);
          this.cargando = false;
          //console.log(this.usuarios);
        })
  }

  cambiarDesde( valor: number ){
    let desde = this.desde + valor;
    if( desde >= this.totalRegistros ){
      return;
    }
    if( desde < 0 ){
      return;
    }
    this.desde += valor;
    this.cargarUsuarios();
  }

  buscarUser( termino: string ){
    if(termino.length <= 0){
      this.cargarUsuarios();
      return;
    }
    this.cargando = true;
    this._userService.buscarUser( termino )
    .subscribe((user: User[] ) => {
          this.usuarios = user;
          this.cargando = false;
        })
  }

  borrarInstitucion( user: User ){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de dar de baja a: '+user.nombres,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      /*if (result.value) {
        this._institucionService.borrarInstitucion( institucion._id )
              .subscribe( baja => {
                this.cargarInstitucion();
              });
      }*/
    })
  }

  ModificarUser( user: User ){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de dar de baja a: '+user.nombres,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      /*if (result.value) {
        this._institucionService.borrarInstitucion( institucion._id )
              .subscribe( baja => {
                this.cargarInstitucion();
              });
      }*/
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { Tutorial } from '../../../models/tutorial.model';
import { Personal } from '../../../models/personal.model';
import { MaterialService, UserService } from '../../../services/service.index';

@Component({
  selector: 'app-vertutorial',
  templateUrl: './vertutorial.component.html',
  styles: []
})
export class VertutorialComponent implements OnInit {
  public tutorial: Tutorial[] = [];
  personal: Personal;

  constructor(
    public _userService: UserService,
    public _materialService: MaterialService,
  ) { 
    this.personal = this._userService.personal[0]; 
  }

  ngOnInit() {
    this.cargarTutorial();
    //console.log(this.personal);
  }
  cargarTutorial(){
    if(this.personal.role === 'PROFE_ROLE'){
      this._materialService.cargarTutorialProfe()
        .subscribe( (resp: any) => {          
          this.tutorial=resp.tutorial;
        })
    }
    if(this.personal.role === 'STUDENT_ROLE'){
      this._materialService.cargarTutorialEstudiante()
        .subscribe( (resp: any) => {          
          this.tutorial=resp.tutorial;
        })
    }
    
  }

}

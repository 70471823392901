import { Component, OnInit } from '@angular/core';
import { MaterialService, PersonalService, VirtualService } from '../../services/service.index';
import { Material } from '../../models/material.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Personal } from '../../models/personal.model';
import { Tareapresentada } from '../../models/tareapresentada.model';

@Component({
  selector: 'app-revisionevaluacion',
  templateUrl: './revisionevaluacion.component.html',
  styles: []
})
export class RevisionevaluacionComponent implements OnInit {
  title:string;
  identificador: string;
  material: Material[]=[];
  personas: Personal[] = [];
  clase: string;
  tarea: string;
  respuestas: Tareapresentada[]=[];

  constructor(
    public _materialService: MaterialService,
    public activatedRoute: ActivatedRoute,
    public _personalService: PersonalService,
    public _virtualService: VirtualService,
    public router: Router
  ) { 
    this.title = "EVALUACIONES";
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
      this.clase = params['virtual'];
      this.tarea = params['tarea'];
    });
  }

  ngOnInit() {
    this.cargarDatos(this.identificador);
  }
  cargarDatos(id: string){
    this._materialService.cargarAulaId( id )
        .subscribe( (resp: any) => {
          this.material = resp.material;
          //this.cargarEstudiantesCurso(this.material['curso']._id);
          this.cargarTareaPresentada( this.clase, this.tarea );
        })
  }

  cargarEstudiantesCurso(id:string){
    this._personalService.cargarEstudianteCursoComplementario( id )
        .subscribe( (resp: any) => {
          this.personas = resp.personal;
        })
  }
  cargarTareaPresentada( virtual: string, tarea: string ){
    this._virtualService.verTareapresentada( virtual, tarea )
    .subscribe( (resp: any) => {
      this.respuestas=resp.tarea;
    })    
  }
  calificarEvaluacion( idrev:string ){
    this.router.navigate(['/institucion/calificar-tarea', idrev, this.tarea, this.clase]);
  }

}

import { RouterModule, Routes } from "@angular/router";
import { Input } from '@angular/core';

import { EstudianteComponent } from './estudiante.component';

import { LoginGuardGuard } from '../services/service.index';
import { EstudianteMateriaComponent } from './estudiante-materia/estudiante-materia.component';
import { MateriaTemaComponent } from './estudiante-materia/materia-tema.component';
import { PasarTemaComponent } from './estudiante-materia/pasar-tema.component';
import { ClaseenvivostdComponent } from './estudiante-materia/claseenvivostd.component';
import { HacertareaComponent } from './estudiante-materia/hacertarea.component';
import { HacerevaluacionComponent } from './estudiante-materia/hacerevaluacion.component';
import { ContenidoEstudianteComponent } from "./estudiante-materia/contenido-estudiante.component";



const estudianteRoutes: Routes = [
    {
        path: '', 
        component: EstudianteComponent,  
        canActivate: [ LoginGuardGuard ],     
        canLoad: [ LoginGuardGuard ],                          
        children: [
            {path: 'mis-materias', component: EstudianteMateriaComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'temas-disponibles/:id', component: MateriaTemaComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'tema-virtual/:id', component: PasarTemaComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'clase-zoom/:sala/:clave', component: ClaseenvivostdComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'realizar-tarea/:id/:virtual', component: HacertareaComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'realizar-evaluacion/:id/:virtual', component: HacerevaluacionComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'contenidos-estudiante/:id/:materia', component: ContenidoEstudianteComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: '', redirectTo: '/dashboard', pathMatch: 'full'}
            
        ]
    }
];

export const ESTUDIANTE_ROUTES = RouterModule.forChild( estudianteRoutes );
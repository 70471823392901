export class Programacion {
    constructor(
        public titulo: string,       
        public disponible?: boolean,
        public instruccion?:  string,
        public tipo?: string,   
        public inicio?: string,
        public preguntas?: string,
        public respuestas?: string,
        public fin?: string,
        public cronometro?: boolean,
        public cant_tiempo?: number,
        public cant_preguntas?: number,
        public aleatorio?: boolean,
        public criterio?: string,
        public virtual?: string,
        public _id?: string
    ){}
}


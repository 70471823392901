import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CursoService, VirtualService } from '../../../services/service.index';
import { Boletin } from '../../../models/boletin.model';
import { Curso } from '../../../models/curso.model';
import { Anual } from '../../../models/anual.model';

@Component({
  selector: 'app-boletin',
  templateUrl: './boletin.component.html',
  styles: [
  ]
})
export class BoletinComponent implements OnInit {
  public identificador: string;
  public codigo: string;
  public curso: string;
  public boletin: Boletin[] = [];
  public anual: Anual[] = [];
  public visisble : boolean;
  micurso= new Curso('','',0,'');

  constructor(
    public activatedRoute: ActivatedRoute,
    public _virtualService: VirtualService,
    public _cursoService: CursoService
  ) {
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
      this.codigo = params['codigo'];
      this.curso = params['curso'];
    });
   }

  ngOnInit(): void {
    this.consultarBoletines(this.identificador, this.codigo);
    this.cargarCurso(this.curso);
    
  }
  cargarCurso(id: string){
    this._cursoService.cargarId(id)
      .subscribe((resp:any) => {
        this.micurso = resp;
      })
  }

  consultarBoletines(id: string, codigo: string){
    this.visisble = false;    
    this._virtualService.consultarBoletines( id, codigo )
      .subscribe( (resp: any) => {
        this.boletin = resp;
        console.log(this.boletin);
        let pregunta = 0;
        for(let i=0;i<this.boletin.length;i++){
          if(this.boletin[i].trimestre[0] === 1){
            pregunta = 1;
          }
        }
        for(let i=0;i<this.boletin.length;i++){           
          let a = 0;
          if(pregunta === 1){
            if(this.boletin[i].trimestre[0] === 1){            
              a=this.boletin[i].promedio;
              this.anual.push({
                'estudiante' : this.boletin[i].estudiante,
                'curso': this.boletin[i].curso,
                'materia' : this.boletin[i].materia,
                'trimestre1' : this.boletin[i].promedio,
                'trimestre2' : null,
                'trimestre3' : null,
                'promedio' : Math.round(a/3)
              });
              /* for(let j=i;j<this.boletin.length;j++){    
                if(this.boletin[i].materia === this.boletin[j].materia&&this.boletin[j].trimestre[0] === 2){
                  a = a + this.boletin[j].promedio;
                  for(let k=j;k<this.boletin.length;k++){
                    if(this.boletin[i].materia === this.boletin[j].materia&&this.boletin[j].materia === this.boletin[k].materia&&this.boletin[k].trimestre[0] === 3){
                      a = a + this.boletin[k].promedio;
                      if(a!==0){
                        this.anual.push({
                          'estudiante' : this.boletin[i].estudiante,
                          'curso': this.boletin[i].curso,
                          'materia' : this.boletin[i].materia,
                          'trimestre1' : this.boletin[i].promedio,
                          'trimestre2' : this.boletin[j].promedio,
                          'trimestre3' : this.boletin[k].promedio,
                          'promedio' : Math.round(a/3)
                        });
                      }
                    }
                  }
                }
                              
              }  */ 
            }
          }
          else{
            a=0;
            for(let j=i;j<this.boletin.length;j++){    
              if(this.boletin[i].materia === this.boletin[j].materia&&this.boletin[j].trimestre[0] === 2){
                a = a + this.boletin[j].promedio;
                for(let k=j;k<this.boletin.length;k++){
                  if(this.boletin[i].materia === this.boletin[j].materia&&this.boletin[j].materia === this.boletin[k].materia&&this.boletin[k].trimestre[0] === 3){
                    a = a + this.boletin[k].promedio;
                    if(a!==0){
                      this.anual.push({
                        'estudiante' : this.boletin[i].estudiante,
                        'curso': this.boletin[i].curso,
                        'materia' : this.boletin[i].materia,
                        'trimestre1' : 0,
                        'trimestre2' : this.boletin[j].promedio,
                        'trimestre3' : this.boletin[k].promedio,
                        'promedio' : 0
                      });
                    }
                  }
                }
              }                
            }             
          }            
        }
        //console.log(this.anual);
        this.visisble = true;
      })
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { URL_SERVICIOS } from '../config/config';

@Pipe({
  name: 'imagen'
})
export class ImagenPipe implements PipeTransform {

  transform(img: string, tipo: string = 'user' ): any {
    let url = URL_SERVICIOS + '/imagen';
    if(!img){
      return url +'/df/dsfsdf';
    }
    switch(tipo){
      case 'user':
      url += '/userRedimension/' + img;
      break;
      case 'institucion':
      url += '/institucionRedimension/' + img;
      break;
      case 'tema':
      url += '/temaRedimension/' + img;
      break;
      case 'galeria':
      url += '/galeriaRedimension/' + img;
      break;
      case 'tarea':
      url += '/tareaRedimension/' + img;
      break;
      case 'evaluacion':
      url += '/evaluacionRedimension/' + img;
      break;
      default:
      console.log('tipo raro');
      url +'/df/dsfsdf';
    }
    return url;
  }

}

import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalUploadService {

  public tipo: string;
  public id: string;
  public tema: string;
  public oculto: string = 'oculto';
  public notificacion = new EventEmitter<any>();

  constructor() { 
  }

  ocultarModal(){
    this.oculto = 'oculto';
    this.id = null;
    this.tipo = null;
    this.tema = null;
  }
  mostrarModal( tipo: string, tema:string ,id: string){
    this.oculto = '';
    this.id = id;
    this.tema = tema;
    this.tipo = tipo;
  }
}


export { LoginGuardGuard } from './guards/login-guard.guard';

export{ UserService } from './user/user.service';

export { SidebarService } from './shared/sidebar.service';

export { InstitucionService } from './institucion/institucion.service';

export { PersonalService } from './personal/personal.service';

export { CursoService } from './curso/curso.service';

export { MateriaService } from './materia/materia.service';

export { SubirArchivoService } from './subir-archivo/subir-archivo.service';

export { MaterialService } from './material/material.service';

export { VirtualService } from './virtual/virtual.service';

export { GaleriaService } from './galeria/galeria.service';

export { RelojService, XsegundoService } from './reloj/reloj.service';

export { VentanillaService } from './ventanilla/ventanilla.service';

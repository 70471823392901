import { Component, OnInit } from '@angular/core';
import { Institucion } from '../../../models/institucion.model';
import { InstitucionService } from '../../../services/service.index';
import { Router, ActivatedRoute } from "@angular/router";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-instituciones',
  templateUrl: './lista-instituciones.component.html',
  styles: []
})
export class ListaInstitucionesComponent implements OnInit {
  title: string;
  institucion: Institucion[] = [];
  limite: number = 0;
  desde: number = 0;
  totalRegistros: number = 0;
  cargando: boolean = true;

  constructor(
    public _institucionService: InstitucionService,
    public router: Router,
    public activateRoute: ActivatedRoute
  ) {
    this.title = "INSTITUCIONES";
    activateRoute.params.subscribe( params => {
      let id = params['id'];
    });
   }

  ngOnInit() {
    this.cargarInstitucion();
  }

  cargarInstitucion(){
    this._institucionService.cargarInstitucion( this.desde )
        .subscribe( (resp: any) => {
          this.totalRegistros = resp.cuantos;
          this.institucion = resp.instituciones;
          this.cargando = false;
        })
  }

  cambiarDesde( valor: number ){
    let desde = this.desde + valor;
    if( desde >= this.totalRegistros ){
      return;
    }
    if( desde < 0 ){
      return;
    }
    this.desde += valor;
    this.cargarInstitucion();
  }

  buscarInstitucion( termino: string ){
    if(termino.length <= 0){
      this.cargarInstitucion();
      return;
    }
    this.cargando = true;
    this._institucionService.buscarInstitucion( termino )
    .subscribe((institucion: Institucion[] ) => {
          this.institucion = institucion;
          this.cargando = false;
        })
  }

  borrarInstitucion( institucion: Institucion ){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de dar de baja a: '+institucion.nombre,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._institucionService.borrarInstitucion( institucion._id )
              .subscribe( baja => {
                this.cargarInstitucion();
              });
      }
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Curso } from '../../models/curso.model';
import { CursoService, UserService, MateriaService, MaterialService, PersonalService } from '../../services/service.index';
import { User } from '../../models/user.model';
import { Materia } from '../../models/materia.model';
import { Material } from '../../models/material.model';
import { Personal } from '../../models/personal.model';
import { URL_SERVICIOS } from '../../config/config';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';
import { Comunicado } from '../../models/comunicado.model';

@Component({
  selector: 'app-mi-materia',
  templateUrl: './mi-materia.component.html',
  styles: []
})
export class MiMateriaComponent implements OnInit {
  title: string;
  public cursos: Curso[];
  public materias: Materia[];
  public usuario = new User('','','','');
  public material= new Material('','','');
  public materiales: Material[] = [];
  public personal = new Personal('','','','');
  public cuaderno: string = 'https://drive.google.com/drive/folders/1EU8jag3lnMYKxdOg8apHyu8FRmTUoPBA?usp=sharing';
  //limite: number = 0;
  //desde: number = 0;
  //totalRegistros: number = 0;
  

  constructor(
    public _cursoService: CursoService,
    public _userService: UserService,
    public _materiaService: MateriaService,
    public _materialService: MaterialService,
    public _personalService: PersonalService,
    public router: Router
  ) { 
    this.title = "Mis materias y cursos";
    this.cursos = [];
    this.materias = [];
  }

  ngOnInit() {
    this.usuario = this._userService.user;
    
    this._cursoService.cargarCursoSin(this.usuario.institucion['_id']).subscribe((cursos:Curso[]) => {
      this.cursos = cursos 
    }); 
    this._materiaService.cargarMateriaSin(this.usuario.institucion['_id']).subscribe((materias:Materia[]) => {
      this.materias = materias 
    }); 
    this.personal = this._userService.personal;
    this.cargarMaterial();
  }

  guardarMaterial( material: Material){
    this.material.personal = this.personal[0]._id;
    this.material.curso = material.curso;
    this.material.materia = material.materia;
    this._materialService.crearMaterial( this.material )
      .subscribe( resp => {
        this.cargarMaterial();
      });
  }


  cargarMaterial(){
    this._materialService.cargarMaterial( )
    .subscribe( (resp: any) => {
      //this.totalRegistros = resp.cuantos;
      this.materiales = resp.material;
      console.log(this.materiales);
    })
  }

  /*cambiarDesde( valor: number ){
    let desde = this.desde + valor;
    if( desde >= this.totalRegistros ){
      return;
    }
    if( desde < 0 ){
      return;
    }
    this.desde += valor;
    this.cargarMaterial();
  }*/

  cargarAula( id: string ){
    this.router.navigate(['/institucion/mis-temas', id]);
  }

  cargarPlanAnual( id: string ){
    this.router.navigate(['/institucion/plan-anual', id]);
  }

  cargarCalificaciones( id: string ){
    this.router.navigate(['/institucion/calificaciones', id]);
  }
  cargarCriterio( id: string ){
    this.router.navigate(['/institucion/criterio', id]);
  }
  cargarSer( id: string ){
    this.router.navigate(['/institucion/ser', id]);
  }
  cargarHacer( id: string ){
    this.router.navigate(['/institucion/hacer', id]);
  }
  cargarSaber( id: string ){
    this.router.navigate(['/institucion/saber', id]);
  }
  cargarDecidir( id: string ){
    this.router.navigate(['/institucion/decidir', id]);
  }

  cargarAulaVivo( id: string, sala: number, clave: number ){
    let url= "http://localhost:4200/#/institucion/clase-en-vivo-profesor/"+id+"/"+sala+"/"+clave;
    window.open(url, "nuevo", "directories=no, location=no, menubar=no, scrollbars=yes, statusbar=no, tittlebar=no");
    //this.router.navigate(['/institucion/clase-en-vivo-profesor', id]);
  }

  cargarCuadernoPedagogico( curso: string, materia: string, material: string ){
    this.router.navigate(['/institucion/cargar-cuaderno-pedagogico', curso, materia, material]);
  }

  borrarSeleccion( material: Material ){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de borrar: '+material.curso['nombre'],
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._materialService.borrarMaterial( material._id )
              .subscribe( material => {
                this.cargarMaterial();
              });
      }
    })
  }

  verLista(id: string){
    this.router.navigate(['/institucion/curso-lista-complement', id]);    
  }

  cargarCuaderno(){
    window.open(this.cuaderno);
  }
  

}

import { Component, OnInit } from '@angular/core';
import { Personal } from '../../models/personal.model';
import { Listas } from '../../models/listas.model';
import { ActivatedRoute } from '@angular/router';
import { PersonalService } from '../../services/service.index';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cursolistacomplementario',
  templateUrl: './cursolistacomplementario.component.html',
  styles: []
})
export class CursolistacomplementarioComponent implements OnInit {

  identificador: string;
  title: string;
  personas: Personal[] = []; 
  cargando: boolean = true;
  miLista: Listas[] = [];

  constructor(
    public activatedRoute: ActivatedRoute,
    public _personalService: PersonalService
  ) { 
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
    });
    this.title = "Mis estudiantes";
  }

  ngOnInit() {
    this.cargarEstudiantesCurso();
  }

  cargarEstudiantesCurso(){
    this._personalService.cargarEstudianteCursoComplementario( this.identificador )
        .subscribe( (resp: any) => {
          this.personas = resp.personal;
          //console.log(this.personas);
          for(let i=0;i<this.personas.length;i++){
            //if(this.personas[i].gestiones.length===3){
              for(let j=0;j<this.personas[i].gestiones.length;j++){
                if(this.personas[i].gestiones[j].cursos===this.identificador && this.personas[i].gestiones[j].gestion==='2024'&& this.personas[i].estado === true){
                  this.miLista.push({
                  'user' : this.personas[i].user['_id'],
                  'personal': this.personas[i]._id,
                  'paterno' : this.personas[i].user['paterno'],
                  'materno' : this.personas[i].user['materno'],
                  'nombres' : this.personas[i].user['nombres'],
                  'email' : this.personas[i].user['email'],
                  'cedula' : this.personas[i].user['cedula'],
                  'zona' : this.personas[i].user['zona'],
                  'calle' : this.personas[i].user['calle'],
                  'padre' : this.personas[i].user['padre'],
                  'celpadre' : this.personas[i].user['celpadre'],
                  'madre' : this.personas[i].user['madre'],
                  'celmadre' : this.personas[i].user['celmadre'],
                  'estado' : this.personas[i].estado
                });
              }              
            }
            /*else if(this.personas[i].gestiones.length===2){
              if(this.personas[i].gestiones[1].cursos===this.identificador && this.personas[i].estado === true){
                this.miLista.push({
                  'user' : this.personas[i].user['_id'],
                  'personal': this.personas[i]._id,
                  'paterno' : this.personas[i].user['paterno'],
                  'materno' : this.personas[i].user['materno'],
                  'nombres' : this.personas[i].user['nombres'],
                  'email' : this.personas[i].user['email'],
                  'cedula' : this.personas[i].user['cedula'],
                  'zona' : this.personas[i].user['zona'],
                  'calle' : this.personas[i].user['calle'],
                  'padre' : this.personas[i].user['padre'],
                  'celpadre' : this.personas[i].user['celpadre'],
                  'madre' : this.personas[i].user['madre'],
                  'celmadre' : this.personas[i].user['celmadre'],
                  'estado' : this.personas[i].estado
                });
              }              
            }
            else{
              if(this.personas[i].gestiones[0].cursos===this.identificador && this.personas[i].estado === true){
                this.miLista.push({
                  'user' : this.personas[i].user['_id'],
                  'personal': this.personas[i]._id,
                  'paterno' : this.personas[i].user['paterno'],
                  'materno' : this.personas[i].user['materno'],
                  'nombres' : this.personas[i].user['nombres'],
                  'email' : this.personas[i].user['email'],
                  'cedula' : this.personas[i].user['cedula'],
                  'zona' : this.personas[i].user['zona'],
                  'calle' : this.personas[i].user['calle'],
                  'padre' : this.personas[i].user['padre'],
                  'celpadre' : this.personas[i].user['celpadre'],
                  'madre' : this.personas[i].user['madre'],
                  'celmadre' : this.personas[i].user['celmadre'],
                  'estado' : this.personas[i].estado
                });
              } 
            }*/             
            /*this.miLista.push({
              'user' : this.personas[i].user['_id'],
              'personal': this.personas[i]._id,
              'paterno' : this.personas[i].user['paterno'],
              'materno' : this.personas[i].user['materno'],
              'nombres' : this.personas[i].user['nombres'],
              'email' : this.personas[i].user['email'],
              'cedula' : this.personas[i].user['cedula'],
              'zona' : this.personas[i].user['zona'],
              'calle' : this.personas[i].user['calle'],
              'padre' : this.personas[i].user['padre'],
              'celpadre' : this.personas[i].user['celpadre'],
              'madre' : this.personas[i].user['madre'],
              'celmadre' : this.personas[i].user['celmadre'],
              'estado' : this.personas[i].estado
            });*/
          }
          this.cargando = false;
        })
  }


  bajaPersonal( personal: Personal ){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de dar de baja a: '+personal.user['nombres'],
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._personalService.bajaPersonal( personal._id )
              .subscribe( personal => {
                this.cargarEstudiantesCurso();
              });
      }
    })
  }

  altaPersonal( personal: Personal ){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de dar de alta a: '+personal.user['nombres'],
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._personalService.altaPersonal( personal._id )
              .subscribe( personal => {
                this.cargarEstudiantesCurso();
              });
      }
    })
  }

}



import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Material } from '../../../models/material.model';
import { MaterialService } from '../../../services/service.index';

@Component({
  selector: 'app-materia-docente',
  templateUrl: './materia-docente.component.html',
  styles: [
  ]
})
export class MateriaDocenteComponent implements OnInit {
  identificador: string;
  material: Material[]=[];
  material2: Material[]=[];
  title: string;
  dtOptions: DataTables.Settings = {};
  visible : boolean;

  constructor(
    public activatedRoute: ActivatedRoute,
    public _materialService: MaterialService,
    public router: Router
  ) { 
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
    });
    this.title = "LISTA DE MATERIAS Y DOCENTES DEL CURSO";
    this.dtOptions = {
      pagingType: 'simple',
      pageLength: 20,
      language: {
        url: '//cdn.datatables.net/plug-ins/1.12.0/i18n/es-ES.json'
      },
      order: [[1, 'asc']]
    };
  }

  ngOnInit(): void {
    this.cargarMaterias();
  }
  cargarMaterias(){
    this.visible = false;
    this._materialService.cargarMaterialDocentes( this.identificador )
        .subscribe( (resp: any) => {          
          this.material = resp.material;
          //console.log( this.material );
          this.cargarDatos(this.material[0]._id);
        })
  }

  cargarDatos(id){
    this._materialService.cargarAulaIdd( id)
      .subscribe( (resp: any) => {
        this.material2 = resp.material[0];
        this.visible = true;
      })
  }

  modCuaderno(id: string){
    this.router.navigate(['/plataforma/modificar-cuaderno', id]);
  }

  /*descargar( enlace ): void{
    console.log('descargando...'+ enlace);
    let a = enlace.split('/',4);
    console.log(a[3]);
    this._materialService.descargaCuaderno(a[3])
      .subscribe((resp: any) => {
        console.log(resp);
      })
  }*/

}

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import '../../../vendor/jitsi/external_api.js';
import { User } from '../../models/user.model.js';
import { UserService } from '../../services/service.index.js';


declare var JitsiMeetExternalAPI: any;

@Component({
  selector: 'app-lista',
  templateUrl: './lista.component.html',
  styles: []
})
export class ListaComponent implements OnInit {

  public domain:string = "meet.jit.si";
  	public options: any;
  	public api: any;
    public usuario = new User('','','','');
  constructor(
    private _route:ActivatedRoute,
    public _userService: UserService
  ) { 
    this.usuario = this._userService.user;
  }

  ngAfterViewInit(){
     
		this.options = {
      roomName: "DocentesUETFReunion",
			width: 720,
      height: 400,
      parentNode: document.querySelector('#meet'),
      configOverwrite: { startWithAudioMuted: true, startWithVideoMuted: true , constraints: {
             video: {
                 height: {
                     ideal: 360,
                     max: 720,
                     min: 240
                 }
             }
         },remoteVideoMenu:{
          disableKick: true
         }},
      interfaceConfigOverwrite: { TOOLBAR_BUTTONS: [
        'microphone', 'camera', 'closedcaptions', 'desktop', 'embedmeeting', 'fullscreen',
        'fodeviceselection', 'hangup', 'profile', 'recording',
        'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
        'videoquality', 'filmstrip', 'feedback', 'stats', 'shortcuts',
        'tileview', 'videobackgroundblur', 'download', 'help', 'mute-everyone', 'security'
    ],
    JITSI_WATERMARK_LINK: "https://tomasfrias.com" },
      userInfo: {
        email: this.usuario.email,
        displayName: 'Prof. '+this.usuario.nombres+' '+this.usuario.paterno
      }
		}

		this.api = new JitsiMeetExternalAPI(this.domain, this.options);
    }


  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { Institucion } from '../../models/institucion.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from '../../config/config';

import { map, take } from 'rxjs/operators';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class InstitucionService {
  institucion: Institucion;
  token: string;

  constructor(
    public http: HttpClient,
  ) {
    this.cargarStorage();
   }
  
  cargarStorage(){
    if( localStorage.getItem('token') ){
      this.token = localStorage.getItem('token');
    }else{
      this.token = '';
    }
  }
  crearInstitucion( institucion: Institucion){
    let url = URL_SERVICIOS + '/institucion';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    

    return this.http.post(url, institucion, httpOptions).pipe(map( (resp: any) => {
        Swal.fire('Institución creada', institucion.nombre, 'success');
        return resp.institucion;
      }));
  }

  cargarInstitucion( desde: number = 0 ){
    let limite = 5;
    let url = URL_SERVICIOS + '/instituciones?limite='+limite+'&desde='+desde;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }

  cargarInstitucionSin(){
    let url = URL_SERVICIOS + '/institucions';
    
    return this.http.get( url )
    .pipe(map((resp:any) => {
      return resp.instituciones;
    }));
    
  }
  
  buscarInstitucion( termino: string ){

    let url = URL_SERVICIOS + '/coleccion/institucion/' + termino;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.get(url, httpOptions).pipe(map( (resp: any) => {
      return resp.institucion;
    }));
  }

  borrarInstitucion( id: string ){
    let url = URL_SERVICIOS + '/institucion/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.delete( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Institución dada de baja', 'La institución ha sido dada de baja', 'success');
      return true;
    }));
  }

  miInstitucion( id: string ){
    let url = URL_SERVICIOS + '/institucion/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.get(url, httpOptions).pipe(map( (resp: any) => {
      return resp.institucion;
    }));
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Horario } from 'src/app/models/horario.model';
import { VirtualService } from 'src/app/services/service.index';

@Component({
  selector: 'app-horario',
  templateUrl: './horario.component.html',
  styles: [
  ]
})
export class HorarioComponent implements OnInit {
  public identificador: string;
  horario : Horario;
  public bandera : boolean = false;


  constructor(
    public activatedRoute: ActivatedRoute,
    public _virtualService: VirtualService
  ) {
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
    });
   }

  ngOnInit(): void {
    this.cargarHorario(this.identificador);
  }
  cargarHorario( id: string ){
    this._virtualService.cargarHorario( id )
    .subscribe( (resp: any) => {
      if(resp.length === 0){
        this.bandera = false;
      }else{
        this.horario = resp;
        this.bandera = true;
      }
    })
  }
  crearHorario(){
    
  }

}

import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { SubirArchivoService } from '../../services/service.index';
import { ModalUploadService } from './modal-upload.service';

@Component({
  selector: 'app-modal-upload',
  templateUrl: './modal-upload.component.html',
  styles: []
})
export class ModalUploadComponent implements OnInit {
  public imagenSubir: File;
  public imagenTemp: any;

  constructor(
    public _subirArchivoService: SubirArchivoService,
    public _modalUploadService: ModalUploadService
  ) { 
  }

  ngOnInit() {
  }

  subirImagen(){
    let vacio='vacio';
    this._subirArchivoService.subirArchivoSubtitulo(this.imagenSubir, this._modalUploadService.tipo, this._modalUploadService.tema, this._modalUploadService.id,vacio)
      .then( resp => {
        this._modalUploadService.notificacion.emit(resp);
        this.cerrarModal();
      })
      .catch( err => {
        console.log('Error en la carga... ');
      });
  }

  cerrarModal(){
    this.imagenTemp=null;
    this.imagenSubir=null;
    this._modalUploadService.ocultarModal();
  }

  seleccionImage( archivo: File ){
    if(!archivo){
      this.imagenSubir = null;
      return;
    }
    if( archivo.type.indexOf('image') < 0 ){
      Swal.fire('Solo imagenes', 'El archivo seleccionado no es una imagen', 'error');
      this.imagenSubir = null;
      return;
    }

    this.imagenSubir = archivo;

    let reader = new FileReader();
    let urlImagenTemp = reader.readAsDataURL( archivo );

    reader.onloadend = () => this.imagenTemp = reader.result;
    /*reader.onloadend = function(){
      this.imagenTemp = reader.result;
    }*/
  }

  /*cambiarImagen(){
    this._userService.CambiarImagen( this.imagenSubir, this.usuario._id );
  }*/

}

export class Material {
    constructor(
        public personal: string,       
        public curso: string,
        public materia:  string,
        public psp?: string,
        public pabholistico?: string,
        public pabbim1temorientadora?: string,
        public pabbim2temorientadora?: string,
        public pabbim3temorientadora?: string,
        public pabbim1holistico?: string,
        public pabbim2holistico?: string,
        public pabbim3holistico?: string,
        public pabbim1actglobalizante?: string,
        public pabbim2actglobalizante?: string,
        public pabbim3actglobalizante?: string,
        public zoom?: string,
        public _id?: string
    ){}
}

export class Subtitulo{
    constructor(
        public _id?:string,
        public subtitulo?:string,
        public concepto?:string,
        //public concepto2?:string,
        //public url2?:string,
        public audiovisual?:string,
        public resaltar?:number,
        public url?:string,
        public img1?:string,
        public img2?:string,
        public img3?:string,
        public img5?:string,
        public img1g?:boolean,
        public img2g?:boolean,
        public img3g?:boolean,        
        public disponible?:boolean,
        public plantilla?:number
    ){}
}
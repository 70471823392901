import { NgModule } from '@angular/core';



import { PROFESOR_ROUTES } from './profesor.routes';

import { SharedModule } from '../shared/shared.module';

import { ProfesorComponent } from './profesor.component';



import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { PipesModule } from '../pipes/pipes.module';
import { ListaComponent } from './lista/lista.component';
import { CursoComponent } from './curso/curso.component';
import { MateriaComponent } from './materia/materia.component';
import { ListacursoComponent } from './curso/listacurso.component';
import { ListamateriaComponent } from './materia/listamateria.component';
import { TutorComponent } from './lista/tutor.component';
import { MiMateriaComponent } from './lista/mi-materia.component';
import { ListaAulaComponent } from './aula/lista-aula/lista-aula.component';
import { DesarrolloComponent } from './aula/lista-aula/desarrollo.component';
import { ModalUploadComponent } from '../components/modal-upload/modal-upload.component';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule} from '@angular/common/http';
import { MiCursotutorComponent } from './lista/mi-cursotutor.component';
import { ConfiguracionComponent } from './institucion/configuracion/configuracion.component';
import {YouTubePlayerModule} from '@angular/youtube-player';



import { NgxDocViewerModule } from "ngx-doc-viewer";
import { MatVideoModule } from 'mat-video';

import { NgxCircularPlayerModule } from 'ngx-circular-player';
import { PassComponent } from './institucion/pass.component';
import { TrabajoComponent } from './trabajo/trabajo.component';
import { EvaluacionComponent } from './trabajo/evaluacion.component';
import { ModtareaComponent } from './trabajo/modtarea.component';
import { ModexamenComponent } from './trabajo/modexamen.component';
import { NominacomplementarioComponent } from './curso/nominacomplementario.component';
import { CursolistacomplementarioComponent } from './curso/cursolistacomplementario.component';
import { ClaseenvivoComponent } from './aula/lista-aula/claseenvivo.component';
import { NuevocomunicadoComponent } from './comunicado/nuevocomunicado.component';
import { ListacomunicadoComponent } from './aula/listacomunicado/listacomunicado.component';
import { RevisiontareaComponent } from './trabajo/revisiontarea.component';
import { CalificartareaComponent } from './trabajo/calificartarea.component';
import { RevisionevaluacionComponent } from './trabajo/revisionevaluacion.component';
import { PlananualComponent } from './lista/plananual.component';
import { PlanclaseComponent } from './lista/planclase.component';
import { GaleriaComponent } from './trabajo/galeria.component';
import { CuadernoComponent } from './trabajo/cuaderno.component';
import { TutorialesComponent } from './institucion/tutoriales/tutoriales.component';
import { VertutorialComponent } from './institucion/tutoriales/vertutorial.component';
//import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CriterioComponent } from './trabajo/criterio.component';
import { SerComponent } from './trabajo/ser.component';
import { SaberComponent } from './trabajo/saber.component';
import { HacerComponent } from './trabajo/hacer.component';
import { DecidirComponent } from './trabajo/decidir.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NuevodesarrolloComponent } from './aula/lista-aula/nuevodesarrollo.component';
import { HorarioComponent } from './lista/horario.component';
import { IngresoclaseComponent } from './lista/ingresoclase.component';
import { CargarCuadernoComponent } from './trabajo/cuadernopedagogico/cargar-cuaderno.component';
import { DataTablesModule } from 'angular-datatables';
import { EstadisticaComponent } from './lista/estadistica.component';
import { EstadisdescComponent } from './lista/estadisdesc.component';


@NgModule({
  declarations: [
    ProfesorComponent,
    ListaComponent,
    CursoComponent,
    MateriaComponent,
    ListacursoComponent,
    ListamateriaComponent,
    TutorComponent,
    MiMateriaComponent,
    ListaAulaComponent,
    DesarrolloComponent,
    ModalUploadComponent,
    MiCursotutorComponent,
    ConfiguracionComponent,
    PassComponent,
    TrabajoComponent,
    EvaluacionComponent,
    ModtareaComponent,
    ModexamenComponent,
    NominacomplementarioComponent,
    CursolistacomplementarioComponent,
    ClaseenvivoComponent,
    NuevocomunicadoComponent,
    ListacomunicadoComponent,
    RevisiontareaComponent,
    CalificartareaComponent,
    RevisionevaluacionComponent,
    PlananualComponent,
    PlanclaseComponent,
    GaleriaComponent,
    CuadernoComponent,
    TutorialesComponent,
    VertutorialComponent,
    CriterioComponent,
    SerComponent,
    SaberComponent,
    HacerComponent,
    DecidirComponent,
    NuevodesarrolloComponent,
    HorarioComponent,
    IngresoclaseComponent,
    CargarCuadernoComponent,
    EstadisticaComponent,
    EstadisdescComponent
  ],
  imports: [
    SharedModule,
    PROFESOR_ROUTES,
    
    PipesModule,
    ReactiveFormsModule,
    
    FormsModule,
    
    HttpClientModule, 
    AngularEditorModule,
    NgxDocViewerModule,
    MatVideoModule,
    NgxCircularPlayerModule,
    CommonModule,
    YouTubePlayerModule,
    //PdfViewerModule,
    MatProgressSpinnerModule,
    DragDropModule,  
    DataTablesModule,  
  ],
  exports: [
    ProfesorComponent
  ]
})
export class ProfesorModule { }

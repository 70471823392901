export class Tareapresentada {
    constructor(
        public personal: any,    
        public virtual?: string,
        public tarea?: string,
        public respuesta?:string,
        public doc?:string,
        public imagenes?:string,
        public comentario?:string,
        public updated?:string,
        public created?:string,
        public revisado?:boolean,
        public cargado?:boolean,
        public revisadoprof?:string,
        public calificacion?:number,
        public oportunidad?:boolean,
        public entregado?:boolean,
        public entregadofecha?:string,
        public _id?: string
    ){}
}


export class Criterio{
    constructor(
        public _id?:string,
        public curso?:string,
        public materia?:string,
        public criterio?:string,
        public dimension?:string,
        public trimestre?:string,
        public updated?:string,
        public created?:string
    ){}
}
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../services/service.index';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.css']
})
export class SiteComponent implements OnInit {
  //enlace = "https://us02web.zoom.us/j/5545025519?pwd=alc2UHVObmQwOWJrN0xhb1RVYmhKdz09";
  enlace = "https://drive.google.com/drive/folders/11dDi9ys75D2ejXnZlNUR9TBTRCRtuHB8?usp=sharing";
  video = "https://www.youtube.com/embed/qjvFHBs72EE";
  periodico = "https://drive.google.com/file/d/1KI4CsSKvBJUhawbtwcQik7HaLOjhPkYV/view?usp=sharing";

  constructor() { }

  ngOnInit() {
  }

}

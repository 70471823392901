import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CursoService, VirtualService } from '../../../services/service.index';
import { Calificaciontrimestral } from '../../../models/calificaciontrimestral.model';
import { Curso } from '../../../models/curso.model';

@Component({
  selector: 'app-elcurso',
  templateUrl: './elcurso.component.html',
  styles: [
  ]
})
export class ElcursoComponent implements OnInit {
  curso: Curso[]=[];
  curs : string;
  visisble : boolean;
  visisble2 : boolean;
  visisble3 : boolean;
  calificaciones: Calificaciontrimestral[] = [];
  calificaciones2: Calificaciontrimestral[] = [];
  calificaciones3: Calificaciontrimestral[] = [];
  dtOptions: DataTables.Settings = {};
  title: string;

  constructor(
    public activatedRoute: ActivatedRoute,
    public _virtualService: VirtualService,
    public _cursoService: CursoService
  ) { 
    this.title = 'CENTRALIZADOR TRIMESTRAL';
    activatedRoute.params.subscribe( params => {
      this.curs = params['curso'];
    });
    this.dtOptions = {
      pagingType: 'simple',
      pageLength: 50,
      language: {
        url: '//cdn.datatables.net/plug-ins/1.12.0/i18n/es-ES.json'
      },
      order: [[1, 'asc']]
    };
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple',
      pageLength: 50,
      language: {
        url: '//cdn.datatables.net/plug-ins/1.12.0/i18n/es-ES.json'
      },
      order: [[1, 'asc']]
    };
    this.consultarCentralizador(this.curs,1);
    this.consultarCentralizador2(this.curs,2);
    this.consultarCentralizador3(this.curs,3);
    this.cargarDatos();
  }

  cargarDatos(){
    this._cursoService.cargarId( this.curs )
        .subscribe( (resp: any) => {
          this.curso = resp;
          //console.log(this.curso);
        })
  }

  consultarCentralizador(curso: string, trimestre: number){
    this.visisble = false;
    this._virtualService.consultarCentralizadorTrimestral( curso, trimestre )
      .subscribe( (resp: any) => {
        this.calificaciones = resp;
        //console.log(this.calificaciones);
        this.visisble = true;
      })
  }

  consultarCentralizador2(curso: string, trimestre: number){
    this.visisble2 = false;
    this._virtualService.consultarCentralizadorTrimestral( curso, trimestre )
      .subscribe( (resp: any) => {
        this.calificaciones2 = resp;
        //console.log(this.calificaciones2);
        this.visisble2 = true;
      })
  }

  consultarCentralizador3(curso: string, trimestre: number){
    this.visisble3 = false;
    this._virtualService.consultarCentralizadorTrimestral( curso, trimestre )
      .subscribe( (resp: any) => {
        this.calificaciones3 = resp;
        //console.log(this.calificaciones2);
        this.visisble3 = true;
      })
  }

}

import { Component, OnInit } from '@angular/core';
import { Institucion } from '../../models/institucion.model';
import { InstitucionService, MaterialService, UserService } from '../../services/service.index';
import { User } from '../../models/user.model';
import { Comunicado } from '../../models/comunicado.model';
import { Personal } from '../../models/personal.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: []
})
export class DashboardComponent implements OnInit {
  public usuario = new User('','','','');
  public institucion =new Institucion('','','') ;
  public comunicados: Comunicado[] = [];
  personal: Personal;
  pdf : string;
  vis : string;
  //prueba : string;

  constructor(
    public _userService: UserService,
    public _materialService: MaterialService,
    public _institucionService: InstitucionService    
  ) { 
    this.usuario = this._userService.user;
    this.personal = this._userService.personal[0];
    //this.prueba = 'https://drive.google.com/file/d/1Q0w-2FbjyRGUd5aUFSQu89Q9UPzAVyo2/preview';
  }

  ngOnInit() {
    this.usuario = this._userService.user;  
    this.personal = this._userService.personal[0];  
    this.haber(this.usuario.institucion['_id']);
    this.cargarComunicados(this.personal._id);
    //this.mensaje();
  }

  haber(id){
    this._institucionService.miInstitucion( id )
        .subscribe( (resp: any) => {
          this.institucion = resp;
          //console.log(this.institucion);
        })
  }

  mensaje(){
    Swal.fire({
      title: 'Distinguido ppff',
      text: 'Le recordamos que si no tiene sus tres mensualidades canceladas en el banco BISA hasta el 17 de abril. Sus hijos o dependientes, no podrán acceder a la plataforma SAVTLE a partir del lunes 19 del  presente. Evite que sus hijos pasen por esta situación incómoda     CETF R.L.',
      icon: 'warning',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Entendido!'
    })
  }
  cargarComunicados(id:string){
    if(this.personal.role === 'PROFE_ROLE'){
      this._materialService.cargarComunicadoP(id)
        .subscribe( (resp: any) => {          
          this.comunicados=resp.comunicado;
          //console.log(this.comunicados);
        })
    }
    if(this.personal.role === 'STUDENT_ROLE'){
      if(this.personal.gestiones.length===2){
        let idc = this.personal.gestiones[1]['cursos'];
        //console.log(idc);
        this._materialService.cargarComunicadoCur(idc)
          .subscribe( (resp: any) => {          
            this.comunicados=resp.comunicado;
          })
      }else{
        let idc = this.personal.gestiones[0]['cursos'];
        //console.log(idc);
        this._materialService.cargarComunicadoCur(idc)
          .subscribe( (resp: any) => {          
            this.comunicados=resp.comunicado;
          })
      }     
    }
    
  }

}

import { Injectable } from '@angular/core';
import { UserService } from '../service.index';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  menu: any[] = [];

  /*menu: any = [
    {titulo: 'Principal', icono: 'dashboard', url: '/dashboard' },
    {titulo: 'Listas', icono: 'list', url: '/listas' },   
    {titulo: 'Personas', icono: 'people', url: '/lista-personas' },   
    {
      titulo: 'Instituciones', 
      icono: 'school',
      url: 'sub', 
      submenu: [
        { titulo: 'Nueva Institución', icono: 'school', url: '/crear-institucion' },
        { titulo: 'Listar Instituciones', icono: 'list', url: '/lista-instituciones' }
      ]
    }   
  ];*/

  constructor(
    public _usuarioService: UserService
  ) {
      
   }

   cargarMenu(){
     this.menu = this._usuarioService.menu;
   }
}

import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MateriaService, UserService } from '../../services/service.index';
import { Materia } from '../../models/materia.model';
import { User } from '../../models/user.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-materia',
  templateUrl: './materia.component.html',
  styles: []
})
export class MateriaComponent implements OnInit {

  title: string;
  public materia = new Materia('','','',false,false,false,'');
  public usuario = new User('','','','');

  constructor(
    public _materiaService: MateriaService,
    public _userService: UserService,
    public router: Router
  ) { 
    this.title = "FORMULARIO PARA CREACIÓN DE MATERIAS";
  }

  ngOnInit() {
    this.usuario = this._userService.user;
  }

  guardarMateria( materia: Materia ){
    this.materia.institucion = this.usuario.institucion['_id'];
    this.materia.nombre = materia.nombre;
    this.materia.nivel = materia.nivel;
    if(materia.nivel === 'inicial'){
      this.materia.inicial = true;
    }
    if(materia.nivel === 'primaria'){
      this.materia.primaria = true;
    }
    if(materia.nivel === 'secundaria'){
      this.materia.secundaria = true;
    }
    
    this._materiaService.crearMateria( this.materia )
      .subscribe( resp => this.router.navigate(['/institucion/lista-materias']) );
  }
}

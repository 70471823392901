import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Evaluacion } from "../../models/evaluacion.model";
import { VirtualService, UserService } from '../../services/service.index';
import { AngularEditorConfig } from '@kolkov/angular-editor';


import Swal from 'sweetalert2';
import { Virtual } from '../../models/virtual.model';
import { Programacion } from '../../models/programacion.model';
import { Respuesta } from '../../models/respuesta.model';
import { Personal } from '../../models/personal.model';

@Component({
  selector: 'app-hacerevaluacion',
  templateUrl: './hacerevaluacion.component.html',
  styles: []
})
export class HacerevaluacionComponent implements OnInit {

  identificador: string;
  virtual: string;
  evaluaciones: Respuesta;
  evaluacion: Evaluacion;
  pregunta: string;
  verifica: string;
  verifica2: Respuesta;
  preguntas = new Array();
  nuevapreguntas = new Array();
  vir: Programacion;
  title: string;

  timeLeft: number = 120;
  interval;
  personal: Personal;
  bandera: boolean;
  misrespuestas: Respuesta;
  misrespuestasenviar: Respuesta;
  intentos: boolean;

  constructor(
    public _virtualService: VirtualService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public _userService: UserService
  ) {
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
      this.virtual = params['virtual'];
    });
    this.evaluacion=new Evaluacion('','');
    this.evaluaciones=new Respuesta('','');
    this.verifica2=new Respuesta('','');
    this.misrespuestas=new Respuesta('','');
    this.misrespuestasenviar=new Respuesta('','');
    this.vir =new Programacion('');
    this.title = "Evaluación";
    this.personal = this._userService.personal[0];
    this.bandera=false;
   }
   config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '5rem',
    placeholder: 'Escriba el texto aqui...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['insertVideo']
      ],
    customClasses: [
      {
        name: "Negrita",
        class: "quote",
        tag: "strong",
      },
      {
        name: 'Mas negrita',
        class: 'redText',
        tag: "b",
      },
      {
        name: "Títulos",
        class: "titleText",
        tag: "h3",
      },
    ]
  };

  ngOnInit() {
    this.cargarEvaluacion();
    this.startTimer(); 
  }

  
  

  

  cargarEvaluacion(){
    this._virtualService.cargarpreguntas( this.identificador )
        .subscribe( (virtual: any) => {          
          this.vir = virtual[0];
          //console.log(this.vir.respuestas[0]['intentos']);
          /*if(this.vir.respuestas[0]['intentos'] < 1){
            this.intentos=true;
          }*/
          this.timeLeft = 60*this.vir.cant_tiempo;
          this.verifica = this.vir.respuestas;
          let tam=this.verifica.length;
          for(let j=0; j < tam; j++){
            if(this.verifica[j]['personal'] === this.personal._id){
              this.bandera=true;
            }
          }
          this.pregunta = this.vir.preguntas;
          for(var i=0; i<(this.pregunta).length;i++){
            this.preguntas.push(this.pregunta[i]);
          }
          let Total:Number = (this.preguntas).length;
          let aleatorio;
          for (var i=0; i<Total; i++) {            
            if(this.nuevapreguntas.length < 10 ){
              aleatorio = Math.floor(Math.random()*((this.preguntas).length));
              this.nuevapreguntas.push(this.preguntas[aleatorio]);
              this.preguntas.splice(aleatorio, 1);
            }
          }   
                
          if(!this.bandera){
            this.responderEvaluacion(this.nuevapreguntas);
          }
          
        })
  }

  responderEvaluacion( respuesta ){ 
    this.evaluaciones.pregunta1= respuesta[0].pregunta;
    this.evaluaciones.opa1= respuesta[0].opa;
    this.evaluaciones.opb1= respuesta[0].opb;
    this.evaluaciones.opc1= respuesta[0].opc;
    this.evaluaciones.opd1= respuesta[0].opd;
    this.evaluaciones.r1= respuesta[0].respuesta;
    this.evaluaciones.p1= respuesta[0].puntos;
    this.evaluaciones.pregunta2= respuesta[1].pregunta;
    this.evaluaciones.opa2= respuesta[1].opa;
    this.evaluaciones.opb2= respuesta[1].opb;
    this.evaluaciones.opc2= respuesta[1].opc;
    this.evaluaciones.opd2= respuesta[1].opd;
    this.evaluaciones.r2= respuesta[1].respuesta;
    this.evaluaciones.p2= respuesta[1].puntos;
    this.evaluaciones.pregunta3= respuesta[2].pregunta;
    this.evaluaciones.opa3= respuesta[2].opa;
    this.evaluaciones.opb3= respuesta[2].opb;
    this.evaluaciones.opc3= respuesta[2].opc;
    this.evaluaciones.opd3= respuesta[2].opd;
    this.evaluaciones.r3= respuesta[2].respuesta;
    this.evaluaciones.p3= respuesta[2].puntos;
    this.evaluaciones.pregunta4= respuesta[3].pregunta;
    this.evaluaciones.opa4= respuesta[3].opa;
    this.evaluaciones.opb4= respuesta[3].opb;
    this.evaluaciones.opc4= respuesta[3].opc;
    this.evaluaciones.opd4= respuesta[3].opd;
    this.evaluaciones.r4= respuesta[3].respuesta;
    this.evaluaciones.p4= respuesta[3].puntos;
    this.evaluaciones.pregunta5= respuesta[4].pregunta;
    this.evaluaciones.opa5= respuesta[4].opa;
    this.evaluaciones.opb5= respuesta[4].opb;
    this.evaluaciones.opc5= respuesta[4].opc;
    this.evaluaciones.opd5= respuesta[4].opd;
    this.evaluaciones.r5= respuesta[4].respuesta;
    this.evaluaciones.p5= respuesta[4].puntos;
    this.evaluaciones.pregunta6= respuesta[5].pregunta;
    this.evaluaciones.opa6= respuesta[5].opa;
    this.evaluaciones.opb6= respuesta[5].opb;
    this.evaluaciones.opc6= respuesta[5].opc;
    this.evaluaciones.opd6= respuesta[5].opd;
    this.evaluaciones.r6= respuesta[5].respuesta;
    this.evaluaciones.p6= respuesta[5].puntos;
    this.evaluaciones.pregunta7= respuesta[6].pregunta;
    this.evaluaciones.opa7= respuesta[6].opa;
    this.evaluaciones.opb7= respuesta[6].opb;
    this.evaluaciones.opc7= respuesta[6].opc;
    this.evaluaciones.opd7= respuesta[6].opd;
    this.evaluaciones.r7= respuesta[6].respuesta;
    this.evaluaciones.p7= respuesta[6].puntos;
    this.evaluaciones.pregunta8= respuesta[7].pregunta;
    this.evaluaciones.opa8= respuesta[7].opa;
    this.evaluaciones.opb8= respuesta[7].opb;
    this.evaluaciones.opc8= respuesta[7].opc;
    this.evaluaciones.opd8= respuesta[7].opd;
    this.evaluaciones.r8= respuesta[7].respuesta;
    this.evaluaciones.p8= respuesta[7].puntos;
    this.evaluaciones.pregunta9= respuesta[8].pregunta;
    this.evaluaciones.opa9= respuesta[8].opa;
    this.evaluaciones.opb9= respuesta[8].opb;
    this.evaluaciones.opc9= respuesta[8].opc;
    this.evaluaciones.opd9= respuesta[8].opd;
    this.evaluaciones.r9= respuesta[8].respuesta;
    this.evaluaciones.p9= respuesta[8].puntos;
    this.evaluaciones.pregunta10= respuesta[9].pregunta;
    this.evaluaciones.opa10= respuesta[9].opa;
    this.evaluaciones.opb10= respuesta[9].opb;
    this.evaluaciones.opc10= respuesta[9].opc;
    this.evaluaciones.opd10= respuesta[9].opd;
    this.evaluaciones.r10= respuesta[9].respuesta;
    this.evaluaciones.p10= respuesta[9].puntos;
    this.evaluaciones.personal=this.personal._id;
    this._virtualService.agregarPreguntas(this.identificador,this.evaluaciones).subscribe( resp => {   
      this.cargarEvaluacion();
    });
  }
  responderEval(res:Respuesta){
    this.misrespuestasenviar.respuesta1=res.respuesta1;
    this.misrespuestasenviar.respuesta2=res.respuesta2;
    this.misrespuestasenviar.respuesta3=res.respuesta3;
    this.misrespuestasenviar.respuesta4=res.respuesta4;
    this.misrespuestasenviar.respuesta5=res.respuesta5;
    this.misrespuestasenviar.respuesta6=res.respuesta6;
    this.misrespuestasenviar.respuesta7=res.respuesta7;
    this.misrespuestasenviar.respuesta8=res.respuesta8;
    this.misrespuestasenviar.respuesta9=res.respuesta9;
    this.misrespuestasenviar.respuesta10=res.respuesta10;
    this._virtualService.guardarRespuestas(this.identificador, this.vir.respuestas[0]['_id'],this.misrespuestasenviar).subscribe( resp => {   
      this.cargarEvaluacion();
    });
  }

  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {        
        this._virtualService.guardarIntentos(this.identificador, this.vir.respuestas[0]['_id'],this.vir.respuestas[0]['intentos']).subscribe( resp => {   
          this.cargarEvaluacion();
        });
      }
    },1000)
  }

  pauseTimer() {
    clearInterval(this.interval);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/service.index';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Reserva } from '../models/reserva.model';

@Component({
  selector: 'app-reserva',
  templateUrl: './reserva.component.html',
  styles: []
})
export class ReservaComponent implements OnInit {
  forma: FormGroup;

  constructor(
    public _userService: UserService,
    public router: Router
  ) { }

  ngOnInit() {
    this.forma = new FormGroup({
      codigob: new FormControl(null, Validators.required),
      codigouetf: new FormControl(null, Validators.required),
      nombres: new FormControl(null, Validators.required),
      paterno: new FormControl(null, Validators.required),
      materno: new FormControl(null, Validators.required),
      nivel: new FormControl(null, Validators.required),
      curso: new FormControl(null, Validators.required),
      emailh: new FormControl('ejemplo@gmail.com'),
      zona: new FormControl(null, Validators.required),
      direccion: new FormControl(null, Validators.required),
      padre: new FormControl('-'),
      cedula: new FormControl('0'),
      celular: new FormControl(0),
      email: new FormControl('ejemplo@gmail.com'),
      madre: new FormControl('-'),
      cedulam: new FormControl('0'),
      celularm: new FormControl(0),
      emailm: new FormControl('ejemplo@gmail.com'),
    });
  }

  registrarReserva(){
    if(this.forma.invalid){
      return;
    }
    let reserva = new Reserva(
      this.forma.value.codigob,
      this.forma.value.codigouetf,
      this.forma.value.nombres,
      this.forma.value.paterno,
      this.forma.value.materno,
      this.forma.value.nivel,
      this.forma.value.curso,
      this.forma.value.emailh,
      this.forma.value.zona,
      this.forma.value.direccion,
      this.forma.value.padre,
      this.forma.value.cedula,
      this.forma.value.celular,
      this.forma.value.email,
      this.forma.value.madre,
      this.forma.value.cedulam,
      this.forma.value.celularm,
      this.forma.value.emailm
    );
    this._userService.crearReserva( reserva )
    .subscribe( resp => this.router.navigate(['/index']) );
  }


}

import { Injectable } from '@angular/core';
import { Curso } from '../../models/curso.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from '../../config/config';

import { map, take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { User } from '../../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class CursoService {
  token:string;
  user: User;
  curso: Curso;

  constructor(
    public http: HttpClient,
  ) { 
    this.cargarStorage();
  }

  cargarStorage(){
    if( localStorage.getItem('token') ){
      this.token = localStorage.getItem('token');
      this.user = JSON.parse( localStorage.getItem('usuario') );
    }else{
      this.token = '';
    }
  }

  crearCurso( curso: Curso ){
    let url = URL_SERVICIOS + '/curso';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    
    return this.http.post(url, curso, httpOptions).pipe(map( (resp: any) => {
        Swal.fire('Crando Curso', 'Creaste un nuevo curso', 'success');
        return resp.curso;
      }));
  }

  cargarCursos( desde: number = 0 ){
    let institucion = this.user.institucion['_id'];
    //console.log(institucion);
    let limite = 5;
    let url = URL_SERVICIOS + '/cursos/'+institucion+'?limite='+limite+'&desde='+desde;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }
  cargarCursoSinlimite(){
    let institucion = this.user.institucion['_id'];
    let url = URL_SERVICIOS + '/Cursosin/'+institucion;
    
    return this.http.get( url )
    .pipe(map((resp:any) => {
      return resp.curso;
    }));
    
  }
  cargarCursosReg( desde: number = 0 , id: string ){
    let institucion = this.user.institucion['_id'];
    //console.log(institucion);
    let limite = 5;
    let url = URL_SERVICIOS + '/cursos/'+institucion+'?limite='+limite+'&desde='+desde;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }

  cargarCursoSin(institucion){
    let url = URL_SERVICIOS + '/Cursosin/'+institucion;
    
    return this.http.get( url )
    .pipe(map((resp:any) => {
      return resp.curso;
    }));
    
  }
  cargCursos(){
    let institucion = this.user.institucion['_id'];
    let url = URL_SERVICIOS + '/Cursosin/'+institucion;
    
    return this.http.get( url )
    .pipe(map((resp:any) => {
      return resp.curso;
    }));
    
  }

  cargarCursoSinid(){
    let institucion = this.user.institucion['_id'];
    let url = URL_SERVICIOS + '/Cursosin/'+institucion;
    
    return this.http.get( url )
    .pipe(map((resp:any) => {
      return resp.curso;
    }));
    
  }

  cargarId(id){
    let url = URL_SERVICIOS + '/Cursoid/'+id;
    
    return this.http.get( url )
    .pipe(map((resp:any) => {
      return resp.curso;
    }));
    
  }

  cargarCursoSinSin(){
    let institucion = this.user.institucion['_id'];
    let url = URL_SERVICIOS + '/Cursosin/'+institucion;
    
    return this.http.get( url )
    .pipe(map((resp:any) => {
      return resp.curso;
    }));
    
  }

  asignarTutor( id: string, persona: string ){
    let url = URL_SERVICIOS + '/asignatutor/' + id + '/' + persona;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.delete( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Excelente', 'Ya eres tutor del curso seleccionado', 'success');
      return true;
    }));
  }
}

import { Component, OnInit } from '@angular/core';
import { MaterialService, VirtualService } from '../../services/service.index';
import { Material } from '../../models/material.model';
import { Virtual } from '../../models/virtual.model';
import { ActivatedRoute, Router } from '@angular/router';
//import Swal from 'sweetalert2';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-planclase',
  templateUrl: './planclase.component.html',
  styles: []
})
export class PlanclaseComponent implements OnInit {
  title: string;
  public material= new Material('','','');
  public tvir= new Virtual('','',0);
  materiale: Material[]=[];
  virtual: Virtual[] = [];  
  identificador: string;
  tema: string;
  public vir = new Virtual('','',0);
  uno=1;
  dos=2;
  tres=3;

  constructor(
    public _materialService: MaterialService,
    public _virtualService: VirtualService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
  ) {
    this.material= new Material('','','');
    this.tvir= new Virtual('','',0);
    this.title = "Plan de Desarrollo Curricular";
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
      this.tema = params['virtual'];
    });
   }

  ngOnInit() {
    this.cargarMaterial();
    this.cargarVirtual();
  }
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '5rem',
    placeholder: 'Escriba el texto aqui...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['insertVideo'],
      ['insertImage'],
      ['backgroundColor'],
      ['toggleEditorMode']
    ],
    customClasses: [
      {
        name: "Negrita",
        class: "quote",
        tag: "strong",
      },
      {
        name: 'Mas negrita',
        class: 'redText',
        tag: "b",
      },
      {
        name: "Títulos",
        class: "titleText",
        tag: "h3",
      },
    ]
  };  
  cargarVirtual(){
    this._virtualService.cargarTemaVirtual( this.tema )
        .subscribe( (resp: any) => {
          this.virtual = resp[0];    
          this.tvir = resp[0];      
        })
  }
  cargarMaterial(){
    this._materialService.cargarAulaId( this.identificador )
    .subscribe( (resp: any) => {
      this.material = resp.material;
      this.materiale = resp.material;
    })
  }
  guardarMaterial( material: Virtual){
    this._virtualService.modificarVirtual( this.tema, material )
      .subscribe( resp => {
        this.cargarVirtual();
      });
  } 
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { APP_ROUTES } from './app.routes';

//modulos
import { PagesModule } from "./pages/pages.module";
import { UsuarioModule } from './usuario/usuario.module';
import { ProfesorModule } from './profesor/profesor.module';
import { PpffModule } from './ppff/ppff.module';
import { EstudianteModule } from './estudiante/estudiante.module';

import { registerLocaleData } from '@angular/common';
//servicios
import { ServiceModule } from './services/service.module';



import { AppComponent } from './app.component';
import { SiteComponent } from './login/site.component';

import { RegisterComponent } from './login/register.component';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule} from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {YouTubePlayerModule} from '@angular/youtube-player';
import { MatVideoModule } from 'mat-video';

import { NgxCircularPlayerModule } from 'ngx-circular-player';
import { ReservaComponent } from './login/reserva.component';
//import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
//import { SiteeComponent } from './login/sitee.component';
import { PipesModule } from './pipes/pipes.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DataTablesModule } from 'angular-datatables';
import { Ng2ImgMaxModule } from 'ng2-img-max';


import localeEsBo from '@angular/common/locales/es-BO';

registerLocaleData(localeEsBo, 'es-Bo');
import { ToastrModule } from 'ngx-toastr';



@NgModule({
  declarations: [
    AppComponent,
    SiteComponent, 
    LoginComponent,    
    RegisterComponent, ReservaComponent
  ],
  imports: [
    BrowserModule,
    APP_ROUTES,
    PagesModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ServiceModule,
    UsuarioModule,
    ProfesorModule,
    PpffModule,
    HttpClientModule, 
    AngularEditorModule,
    
    MatVideoModule,
    NgxCircularPlayerModule,
    EstudianteModule,
    //CKEditorModule,
    YouTubePlayerModule,
    PipesModule,
    PdfViewerModule,
    DataTablesModule,
    Ng2ImgMaxModule,
    ToastrModule.forRoot(), // ToastrModule added
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'es-Bo' }],
  bootstrap: [AppComponent]
})
export class AppModule { }

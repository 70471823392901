import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {HttpClient, HttpEventType, HttpHeaders} from '@angular/common/http';

import { Preguntarea } from '../../models/preguntarea.model';
import { MaterialService, MateriaService, SubirArchivoService, UserService, VirtualService } from '../../services/service.index';
import { ModalUploadService } from '../../components/modal-upload/modal-upload.service';
import { URL_SERVICIOS } from '../../config/config';
import { Tareaemitida } from '../../models/tareaemitida.model';
import { Material } from '../../models/material.model';
import { Criterio } from '../../models/criterio.model';
import { AngularEditorConfig } from '@kolkov/angular-editor';

import Swal from 'sweetalert2';
import { Ng2ImgMaxService } from 'ng2-img-max';

@Component({
  selector: 'app-modtarea',
  templateUrl: './modtarea.component.html',
  styles: []
})
export class ModtareaComponent implements OnInit {
  title:string;
  identificador: string;
  identificador2: string;
  preguntas: Preguntarea;
  pregunta: Preguntarea;
  tarea = new Tareaemitida('','');
  tare = new Tareaemitida('','');
  direccion: string;
  public documentoSubir: File;
  public isDisabled = true;
  bandera:boolean=true;
  public imagenSubir: File;
  public imagenTemp: any;
  plantilla : number;
  material: Material[]=[];
  public criterio2 = [];
  public criterio : Criterio[] = [];
  bimestre:string;

  /*page: number = 1;
  totalPages: number;
  isLoaded: boolean = false;*/
  load : boolean;
  im :  boolean;
  load2 : boolean;
  progress: number = 0;
  visualizador : string = "https://docs.google.com/gview?url=%URL%&embedded=true";

  constructor(
    private http:HttpClient,
    public _virtualService: VirtualService,
    public activatedRoute: ActivatedRoute,
    public _subirArchivoService: SubirArchivoService,
    public _modalUploadService: ModalUploadService,
    public _userService: UserService,
    public _materiaService: MateriaService,
    public _materialService: MaterialService,
    private ng2ImgMax: Ng2ImgMaxService
  ) { 
    this.title = "Creación de Tareas y Configuración";
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
      this.bimestre = params['bim'];
      this.identificador2 = params['clase'];
    });

    this.preguntas=new Preguntarea();
    this.pregunta=new Preguntarea('');
    this.documentoSubir=null;
    this.tarea = new Tareaemitida('','');
    this.tare = new Tareaemitida('','');
  }
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '5rem',
    placeholder: 'Escriba el texto aqui...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['insertVideo'],
      ['insertImage'],
      ['backgroundColor'],
      ['toggleEditorMode']
    ],
    customClasses: [
      {
        name: "Negrita",
        class: "quote",
        tag: "strong",
      },
      {
        name: 'Mas negrita',
        class: 'redText',
        tag: "b",
      },
      {
        name: "Títulos",
        class: "titleText",
        tag: "h3",
      },
    ]
  };     

  ngOnInit() {
    this.cargarTarea(this.identificador);
    this.cargarDatos(this.identificador2);    
  }

  /*afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }*/


  cargarDatos(id: string){
    this._materialService.cargarAulaId( id )
        .subscribe( (resp: any) => {
          this.material = resp.material;
          this.cargarCriterios();         
        })
  }

  seleccionImage( archivo: File ){
    if(!archivo){
      this.imagenSubir = null;
      return;
    }
    if( archivo.type.indexOf('image') < 0 ){
      Swal.fire('Solo imagenes', 'El archivo seleccionado no es una imagen', 'error');
      this.imagenSubir = null;
      return;
    }

    /*this.imagenSubir = archivo;

    let reader = new FileReader();
    let urlImagenTemp = reader.readAsDataURL( archivo );

    reader.onloadend = () => this.imagenTemp = reader.result;*/
    //this.ng2ImgMax.resizeImage(archivo, 100, 100).subscribe(
    this.ng2ImgMax.compressImage(archivo, 0.075).subscribe(
      result => {
        this.imagenSubir = result;
        let reader = new FileReader();
        let urlImagenTemp = reader.readAsDataURL( result );
        reader.onloadend = () => this.imagenTemp = reader.result;
      },
      error => {
        console.log('Oh no!', error);
      }
    )  
  }

  cargarTarea( id: string ){
    this.im = true;
    this._virtualService.cargarLaTarea( id )
    .subscribe( (resp: any) => {
      this.tarea = resp.tarea[0];
      //console.log(this.tarea);
      this.plantilla = parseInt(this.tarea.plantilla[0]);
      if(this.tarea.doc === ''){
        this.direccion = ' ';
        this.load = false;
        this.im = false;
        this.load2 = false;
      }else{
        this.direccion = this.tarea.url4;
        this.load = false;
        this.im = false;
        this.load2 = false;
      }
      
    })
  }
  
  
  seleccionDocumento( archivo: File ){
    if(!archivo){
      this.documentoSubir = null;
      return;
    }
    if( archivo.type.indexOf('image') === 0 || archivo.type !== 'application/pdf'){
      Swal.fire('Error en la selección de archivo', 'El archivo a subir no de debe ser una imagen y solo archivos PDF', 'error');
      this.documentoSubir = null;
      return;
    }

    this.documentoSubir = archivo;
  }
  cargarCriterios(){
    this._materiaService.cargarCriterio( this.material['curso']['_id'], this.material['materia']['_id'] )
      .subscribe( (resp: any) => {
        this.criterio = resp;
      })
  }

  evento(e){
    if(e.indexOf('class="image"') >= 0 || e.indexOf('class="media"') >= 0){
      Swal.fire('Ups!', 'Se ha detectado que esta intentando subir una imagen de manera inapropiada, por favor revise el tutorial', 'error');
    }
  }

  actualizaTarea( pregunta: Preguntarea ){
    this.bandera=false;
    this.pregunta.preg = pregunta.preg;
    this.pregunta.url = pregunta.url;
    if(this.pregunta.preg.indexOf('<img') >= 0 || this.pregunta.preg.indexOf('class="media"') >= 0){
      Swal.fire('No se ha podido guardar', 'Imagenes cargadas inapropiadamente, borre la imagen y por favor revise el tutorial', 'error');
      this.bandera=true;
    }else{
      this._virtualService.agregarPregTarea(this.identificador, this.pregunta).subscribe( resp => {
        this.cargarTarea(this.identificador);
        this.bandera=true;
      });
    }
  }
  guardarCambios(tarea: Tareaemitida){
    this.tare.titulo = tarea.titulo;
    this.tare.presentacion = tarea.presentacion+' '+tarea.hora;
    this.tare.criterio = tarea.criterio;
    this.tare.minimo  = tarea.minimo;
    this._virtualService.ModTarea(this.identificador, this.tare).subscribe( resp => {
      this.cargarTarea(this.identificador);
      this.bandera=true;
    });
  }

  seleccion(id:string){
    var obj = new Object();
    this.criterio2 = [];
    this.tarea.criterio='';
    for(let i=0;i<this.criterio.length;i++){
      if(this.criterio[i].dimension[0]==id && this.criterio[i].trimestre[0]==this.bimestre ){
        obj = {
          _id : this.criterio[i]._id,
          criterio : this.criterio[i].criterio,
          dimension : this.criterio[i].dimension[0],
          trimestre : this.criterio[i].trimestre[0]
        };
        this.criterio2.push(obj);
      }
    }
  }
  /*subirDocumento(){
    this.load2 = true;
    let valor = '2';
    let vacio='vacio';
    this._subirArchivoService.subirArchivoSubtitulo(this.documentoSubir, 'tarea', this.tarea._id,valor,vacio)
      .then( resp => {
        this._modalUploadService.notificacion.emit(resp);
        this.cargarTarea(this.identificador);
        this.bandera=true;
      })
      .catch( err => {
        this.load2 = true;
        console.log('Error en la carga... ');
      });
  }*/
  async subirDocumento(){
    this.load2 = true;
    this.progress=0;
    try{
      const url = URL_SERVICIOS + '/uploadSubtituloDoc/' + this.tarea._id;  
      const formData =  new FormData();
      formData.append( 'archivo', this.documentoSubir, this.documentoSubir.name );
      await this.http.put(url,formData, {
        reportProgress: true,
        observe: 'events'
      }).subscribe(event => {
        if(event.type === HttpEventType.UploadProgress){
          this.progress=Math.round(event.loaded / event.total * 100);
        }else if(event.type === HttpEventType.Response){
          if(this.progress === 100){
            this.progress=0;            
            this.tiempo2();
          }          
        }
      });
      
    } catch(error){
      console.log(error);
      this.load2 = true;
      return false;
    }
  }
  /*subirImagen(){
    this.load = true;
    this.im = true;
    let valor = '2';
    let vacio='vacio';
    this._subirArchivoService.subirArchivoSubtitulo(this.imagenSubir, 'tarea', this.tarea._id,valor,vacio)
      .then( resp => {
        //console.log(resp);
        this._modalUploadService.notificacion.emit(resp);
        this.cargarTarea(this.identificador);
        this.imagenTemp='';
        this.imagenSubir = null;
      })
      .catch( err => {
        this.load = true;
        console.log(err);
        console.log('Error en la carga... ');
      });
  }*/
  async subirImagen(){
    this.load = true;
    this.progress=0;
    try{
      const url = URL_SERVICIOS + '/uploadSubtitulo/' + this.tarea._id ;  
      const formData =  new FormData();
      formData.append( 'archivo', this.imagenSubir, this.imagenSubir.name );
      await this.http.put(url,formData, {
        reportProgress: true,
        observe: 'events'
      }).subscribe(event => {
        if(event.type === HttpEventType.UploadProgress){
          this.progress=Math.round(event.loaded / event.total * 100);
        }else if(event.type === HttpEventType.Response){
          if(this.progress === 100){
            this.progress=0;
            this.tiempo();
          }
          
        }
      });
      
    } catch(error){
      console.log(error);
      this.load = true;
      return false;
    }
  }
  tiempo(){
    setTimeout(() => {
      this.cargarTarea(this.identificador);
      this.imagenSubir = null;
      this.imagenTemp = '';
      Swal.fire('Imagen Cargada', 'Se ha subido la imagen a la tarea', 'success');    
    },100);
  }
  tiempo2(){
    setTimeout(() => {
      this.cargarTarea(this.identificador);
      this.documentoSubir = null;
      this.bandera=true;
      Swal.fire('Documento Cargado', 'Se ha subido el documento a la tarea', 'success');    
    },100);
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VentanillaService } from '../../../services/service.index';

import Swal from 'sweetalert2';
import { Ventanilla } from '../../../models/ventanilla.model';

@Component({
  selector: 'app-lista-ventanilla',
  templateUrl: './lista-ventanilla.component.html',
  styles: [
  ]
})
export class ListaVentanillaComponent implements OnInit {
  title: string;
  dtOptions: DataTables.Settings = {};
  limite: number = 0;
  desde: number = 0;
  totalRegistros: number = 0;
  ventana : Ventanilla[] = [];

  constructor(
    public _ventanillaService: VentanillaService,
    public router: Router
  ) { 
    this.title = 'VENTANILLA ÚNICA';
    this.dtOptions = {
      pagingType: 'simple',
      pageLength: 10,
      language: {
        url: '//cdn.datatables.net/plug-ins/1.12.0/i18n/es-ES.json'
      },
      order: [[0, 'asc']]
    };
  }

  ngOnInit(): void {
    this.cargarCorrespondencia();
  }

  cambiarDesde( valor: number ){
    let desde = this.desde + valor;
    if( desde >= this.totalRegistros ){
      return;
    }
    if( desde < 0 ){
      return;
    }
    this.desde += valor;
    //this.cargarUsuarios();
  }

  newVentanilla(){
    Swal.fire({
      title: '¿Vas a crear un nuevo Registro?',
      text: 'Estas a punto de crear un nuevo registro en Ventanilla Unica',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        
        this._ventanillaService.crearVentanilla2()
          .subscribe( resp => {
            this.router.navigate(['/plataforma/correspondencia-modificar', resp._id]);
          });
      }
    })
  }

  cargarCorrespondencia(){
    this._ventanillaService.verVentanilla()
        .subscribe( (resp: any) => {
          //this.totalRegistros = resp.cuantos;
          this.ventana = resp.ventanilla;
          //console.log(this.totalRegistros);
          //this.cargando = false;
          console.log(this.ventana);
        })
  }

}

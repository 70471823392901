import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { User } from '../../../models/user.model';
import { Curso } from '../../../models/curso.model';
import { UserService, PersonalService, CursoService } from '../../../services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import { Reserva } from '../../../models/reserva.model';
import { Personal } from '../../../models/personal.model';


@Component({
  selector: 'app-correccion',
  templateUrl: './correccion.component.html',
  styles: []
})
export class CorreccionComponent implements OnInit {
  public hijo: User = new User('','','','');
  public usuario = new User('','','','');
  public cursos: Curso[];
  public reserva = new Reserva('','','','','','','','','','','','',0,'','','',0,'');
  public alumno: Personal = new Personal('','','','');
  public id: string;
  public estudiante: User;

  constructor(
    public _userService: UserService,
    public _cursoService: CursoService,
    public _personalService: PersonalService,
    public activatedRoute: ActivatedRoute,
    private location: Location
  ) { 
    activatedRoute.params.subscribe( params => {
      this.id = params['id'];
    });
  }

  ngOnInit() {
    this.usuario = this._userService.user;
    this.cargarReserva();
    this.cargarCurso();
  }
  cargarCurso(){
    this._cursoService.cargarCursoSinid()
        .subscribe( (resp: any) => {
          this.cursos = resp;
          //console.log(this.cursos);
        })
  }
  cargarReserva(){
    this._userService.cargarUnaReserva(this.id)
        .subscribe( (resp: any) => {
          this.reserva = resp.personal;
          //console.log(this.reserva);
        })
  }

  guardarDatos(hijo){
    //console.log(hijo);
    let a='si'
    let wpp = false;
    let wmd = false
    if(hijo.wp === '1'){
      wpp = true;
    }
    if(hijo.wm === '1'){
      wmd = true;
    }
    this.hijo.nombres = hijo.nombres;
    this.hijo.paterno = hijo.paterno;
    this.hijo.materno = hijo.materno;
    //this.hijo.cedula = hijo.cedula;
    //this.hijo.celular = hijo.celular;
    this.hijo.zona = hijo.zona;
    this.hijo.calle = hijo.direccion;
    this.hijo.sexo = hijo.sexo;
    //this.hijo.becado = hijo.becado;
    //this.hijo.porcentaje = hijo.porcentaje;
    //this.hijo.rude = hijo.rude;
    //this.hijo.origen = hijo.origen;
    this.hijo.aprobo = a;
    this.hijo.lugarnac = hijo.lugarnac;
    //this.hijo.nacimiento = hijo.nacimiento;
    this.hijo.email = hijo.email;
    this.hijo.password = hijo.password;
    this.hijo.institucion = this.usuario.institucion['_id'];  
    this.hijo.padre = hijo.padre;
    this.hijo.cipadre = hijo.cedula;
    this.hijo.celpadre = hijo.celular;
    this.hijo.wpadre = wpp;
    this.hijo.madre = hijo.madre;
    this.hijo.cimadre = hijo.cedulam;
    this.hijo.celmadre = hijo.celularm;
    this.hijo.wmadre = wmd;
    this.hijo.tutor = hijo.tutor;
    let codigoba=hijo.codigob;
    this._userService.crearUserInstitucion( this.hijo ).subscribe( resp => {    
      this.estudiante = resp;
      //console.log(resp);
      this.alumno.user = this.estudiante._id;
      this.alumno.institucion = this.usuario.institucion;
      this.alumno.role = '2';
      this.alumno.gestiones = hijo.paralelo;
      this.alumno.cosas1 = codigoba;
      //this.alumno.cosas2 = hijo.pension;
      //console.log(this.alumno);
      this._personalService.crearAlumnno( this.alumno )
      .subscribe( resp => {
        this._userService.verficar( this.id )
        .subscribe( resp => {
          //console.log(resp);
          this.location.back();
          //location.reload();
        });

      });


      /*this._userService.agregarHijoDependiente( this.estudiante._id, this.id_personal )
      .subscribe(resp => {
        this.alumno.user = this.estudiante._id;
        this.alumno.institucion = this.usuario.institucion;
        this.alumno.role = '2';
        this.alumno.gestiones = hijo.paralelo;
        this.alumno.cosas1 = hijo.codigo;
        this.alumno.cosas2 = hijo.pension;
        console.log(resp);
        this._personalService.crearAlumnno( this.alumno )
        .subscribe( resp => {
          console.log(resp);
          //this.location.back();
          location.reload();
        });
        //
      })*/
    });
  }

}

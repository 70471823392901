import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";

import Swal from 'sweetalert2';
import { UserService } from '../services/service.index';
import { User } from '../models/user.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styles: []
})
export class RegisterComponent implements OnInit {

  forma: FormGroup;

  constructor(
    public _userService: UserService,
    public router: Router
  ) { }

  sonIguales(campo1: string, campo2: string){
    return (group: FormGroup ) => {
      let pass1 = group.controls[campo1].value;
      let pass2 = group.controls[campo2].value;

      if(pass1 === pass2){
        return null;
      }

      return {
        sonIguales: true
      };
    };
  }

  ngOnInit() {
    this.forma = new FormGroup({
      nombres: new FormControl(null, Validators.required),
      paterno: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, Validators.required),
      password2: new FormControl(null, Validators.required),
      condiciones: new FormControl(false)
    },{ validators: this.sonIguales('password','password2') });
  }

  registrarUsuario(){
    if(this.forma.invalid){
      return;
    }
    let user = new User(
      this.forma.value.nombres,
      this.forma.value.paterno,
      this.forma.value.email,
      this.forma.value.password
    );
    this._userService.crearUser( user )
    .subscribe( resp => this.router.navigate(['/login']) );
  }

}

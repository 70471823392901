import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CursoService, UserService } from '../../services/service.index';
import { Curso } from '../../models/curso.model';
import { User } from '../../models/user.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-curso',
  templateUrl: './curso.component.html',
  styles: []
})
export class CursoComponent implements OnInit {
  title: string;
  public curso = new Curso('','',0,'',0,'','');
  public usuario = new User('','','','');

  constructor(
    public _cursoService: CursoService,
    public _userService: UserService,
    public router: Router
  ) { 
    this.title = "FORMULARIO PARA CREACIÓN DE CURSOS";
  }

  ngOnInit() {
    this.usuario = this._userService.user;
  }

  guardarCurso( curso: Curso ){
    this.curso.institucion = this.usuario.institucion['_id'];
    this.curso.nombre = curso.nombre;
    this.curso.turno = curso.turno;
    this.curso.paralelo = curso.paralelo;
    this.curso.nivel = curso.nivel;
    this._cursoService.crearCurso( this.curso )
      .subscribe( resp => this.router.navigate(['/institucion/lista-cursos']) );
  }

}

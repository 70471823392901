import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModalUploadService } from '../components/modal-upload/modal-upload.service';
import { 
  SidebarService,
  UserService,
  LoginGuardGuard,
  InstitucionService,
  SubirArchivoService,
  MaterialService,
  XsegundoService
} from "./service.index";





@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    SidebarService,
    UserService,
    LoginGuardGuard,
    InstitucionService,
    SubirArchivoService,
    MaterialService,
    ModalUploadService,
    XsegundoService
  ]
})
export class ServiceModule { }

import { Component, OnInit } from '@angular/core';
import { Curso } from '../../../models/curso.model';
import { Personal } from '../../../models/personal.model';
import { User } from '../../../models/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CursoService, PersonalService, UserService } from '../../../services/service.index';

import { Location } from '@angular/common';
import { Pago } from '../../../models/pago.model';

@Component({
  selector: 'app-cursoshabilitados',
  templateUrl: './cursoshabilitados.component.html',
  styles: []
})
export class CursoshabilitadosComponent implements OnInit {

  identificador: string;
  public cursos: Curso[];
  limite: number = 0;
  desde: number = 0;
  totalRegistros: number = 0;
  public persona = new Personal('', '', '', '');
  public boton: boolean = false;
  public alumno: Personal = new Personal('','','','');
  public usuario = new User('','','','');
  public pago = new Pago();
  

  constructor(
    public activatedRoute: ActivatedRoute,
    public _cursoService: CursoService,
    public _personalService: PersonalService,
    public _userService: UserService,
    public router: Router,
    private location: Location
  ) {
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
    });
   }

  ngOnInit() {
    this.usuario = this._userService.user;
    this.cargarPersona(this.identificador);
  }

  cargarCurso(){
    this._cursoService.cargarCursos( this.desde )
        .subscribe( (resp: any) => {
          this.totalRegistros = resp.cuantos;
          this.cursos = resp.curso;
        })
  }

  cargarPersona( id: string ){
    this._personalService.cargarEstudiante( id )
        .subscribe( (resp: any) => {
          let t = (resp.personal).length;          
          if( t === 0){
            this.boton = true;
            this.cargarCurso();
          }else{
            this.persona = resp.personal[0].gestiones[0];
            if(this.persona.gestion === "2021"){
              this.boton = false;
            }else{
              this.boton = true;
              this.cargarCurso();
            }
            
          }
        })
  }

  guardarDatos(pago){
    console.log(pago);
  }

  registroCurso(id){
    this.alumno.user = this.identificador;
    this.alumno.institucion = this.usuario.institucion;
    this.alumno.role = '2';
    this.alumno.gestiones = {'cursos':id};
    //console.log(this.alumno);
    this._personalService.crearAlumnno( this.alumno )
      .subscribe( resp => {
        console.log(resp);
        this.location.back();
      });
  }

  /*actualizaTema( subtitulo: Subtitulo ){
    this.subtitulo.subtitulo = subtitulo.subtitulo;
    this.subtitulo.concepto = subtitulo.concepto;
    this.subtitulo.url = subtitulo.url;
    this._virtualService.agregarSubtitulo(this.identificador, this.subtitulo).subscribe( resp => {
      this.cargarVirtual();
    });
  }*/


  cambiarDesde( valor: number ){
    let desde = this.desde + valor;
    if( desde >= this.totalRegistros ){
      return;
    }
    if( desde < 0 ){
      return;
    }
    this.desde += valor;
    this.cargarCurso();
  }

}

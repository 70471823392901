import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpEventType, HttpHeaders} from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import readXlsxFile from 'read-excel-file'
import { MaterialService, VirtualService } from '../../../services/service.index';
import Swal from 'sweetalert2';
import { Notastrimestrales } from '../../../models/notastrimestrales.model';
import { URL_SERVICIOS } from '../../../config/config';
import { Material } from '../../../models/material.model';


@Component({
  selector: 'app-cargar-cuaderno',
  templateUrl: './cargar-cuaderno.component.html',
  styles: [
  ]
})
export class CargarCuadernoComponent implements OnInit {
  materiale: Material[]=[];
  StudentRecords : Notastrimestrales[] = [];
  StudentRecordsST : Notastrimestrales[] = [];
  StudentRecordsTT : Notastrimestrales[] = [];
  notasEstudiantes : Notastrimestrales[] = [];
  notasEstudiantes2 : Notastrimestrales[] = [];
  notasEstudiantes3 : Notastrimestrales[] = [];
  //StudentRecords2 : any = [];  
  //StudentRecords3 : any = []; 
  settings : any = {};
  public tabla : boolean = false;
  public tabla2 : boolean = false;
  public tabla3 : boolean = false;
  public curso : string;
  public materia : string;
  public material : string;
  carga : boolean;
  carga2 : boolean;
  carga3 : boolean;
  generado : boolean;
  generado2 : boolean;
  generado3 : boolean;
  public documentoSubir: File;
  progress: number = 0;
  trimestre: number;

  dtOptions: DataTables.Settings = {};

  title: string;

  constructor(
    public activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    public _virtualService: VirtualService,
    public _materialService: MaterialService,
    private http:HttpClient
  ) {
    this.title = "CUADERNO PEDAGÓGICO";
    activatedRoute.params.subscribe( params => {
      this.curso = params['curso'];
      this.materia = params['materia'];
      this.material = params['material'];
    });
  }

  ngOnInit(): void { 
    this.cargarDatos();
    this.dtOptions = {
      pagingType: 'simple',
      pageLength: 50,
      language: {
        url: '//cdn.datatables.net/plug-ins/1.12.0/i18n/es-ES.json'
      },
      order: [[1, 'asc']]
    };  
    this.consultarGuardado(this.curso,this.materia);
    this.consultarGuardado2(this.curso,this.materia);
    this.consultarGuardado3(this.curso,this.materia);
  }
  
  cargarDatos(){
    this._materialService.cargarAulaIdd( this.material )
        .subscribe( (resp: any) => {
          this.materiale = resp.material[0];
        })
  }
  excelRead(e){
    if(!e){
      e = null;
      return;
    }
    if( e.target.files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
      Swal.fire('Error en la selección de archivo', 'El archivo que tiene que subir a plataforma, debe ser un archivo excel', 'error');
      e = null;
      return;
    }
    this.documentoSubir = e.target.files[0];
    let fileReaded: any;
    fileReaded = e.target.files[0];
    let type = e.target.files[0].name.split('.').pop();
    this.StudentRecords = [];
    readXlsxFile(e.target.files[0], { sheet: 'VAL 1 TRIM'}).then(( data ) => {
      if(data){
        var c = 0;
        var cn = 0;
        var v ='';
        var t = 1;
        for(let i of data){
          if(c > 10){
            if(i[1] == 0 || i[1] ==null){              
              break;
            }
            if( i[29] < 35 ){
              cn = cn + 1;              
            } 
            this.StudentRecords.push({
              //'personal': v,
              'estudiante': i[2].toString(),
              'codigo': i[1].toString(),
              'curso': this.curso,
              'materia': this.materia,
              'trimestre': t,
              'ser': i[6],
              'saber': i[14],
              'hacer': i[22],
              'decidir': i[26],
              'autoser': i[27],
              'promedio': i[29]
            });           
            /*const obj = Object.assign({},i);
            this.StudentRecords3.push(obj);*/
          }
          c = c + 1;
        }
        if( cn > 0 ){
          this.toastr.error('¡UPS, Ten cuidado!', `Existen ${cn} promedios inferiores a 35`, {
            timeOut: 10000,
          });
        }
      }
      setTimeout(() => {        
        this.tabla = true;
      },200);
    });
    //this.excelRead2(e);
  }

  excelReadST(e){
    if(!e){
      e = null;
      return;
    }
    if( e.target.files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
      Swal.fire('Error en la selección de archivo', 'El archivo que tiene que subir a plataforma, debe ser un archivo excel', 'error');
      e = null;
      return;
    }
    this.documentoSubir = e.target.files[0];
    let fileReaded: any;
    fileReaded = e.target.files[0];
    let type = e.target.files[0].name.split('.').pop();
    this.StudentRecordsST = [];
    readXlsxFile(e.target.files[0], { sheet: 'VAL 2 TRIM'}).then(( data ) => {
      if(data){
        var c = 0;
        var cn = 0;
        var v ='';
        var t = 2;
        for(let i of data){
          if(c > 10){
            if(i[1] == 0 || i[1] ==null){              
              break;
            }
            if( i[29] < 35 ){
              cn = cn + 1;              
            } 
            this.StudentRecordsST.push({
              //'personal': v,
              'estudiante': i[2].toString(),
              'codigo': i[1].toString(),
              'curso': this.curso,
              'materia': this.materia,
              'trimestre': t,
              'ser': i[6],
              'saber': i[14],
              'hacer': i[22],
              'decidir': i[26],
              'autoser': i[27],
              'promedio': i[29]
            });           
            /*const obj = Object.assign({},i);
            this.StudentRecords3.push(obj);*/
          }
          c = c + 1;
        }
        if( cn > 0 ){
          this.toastr.error('¡UPS, Ten cuidado!', `Existen ${cn} promedios inferiores a 35`, {
            timeOut: 10000,
          });
        }
      }
      setTimeout(() => {        
        this.tabla2 = true;
      },200);
    });
    //this.excelRead2(e);
  }

  excelReadTT(e){
    if(!e){
      e = null;
      return;
    }
    if( e.target.files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
      Swal.fire('Error en la selección de archivo', 'El archivo que tiene que subir a plataforma, debe ser un archivo excel', 'error');
      e = null;
      return;
    }
    this.documentoSubir = e.target.files[0];
    let fileReaded: any;
    fileReaded = e.target.files[0];
    let type = e.target.files[0].name.split('.').pop();
    this.StudentRecordsTT = [];
    readXlsxFile(e.target.files[0], { sheet: 'VAL 3 TRIM'}).then(( data ) => {
      if(data){
        var c = 0;
        var cn = 0;
        var v ='';
        var t = 3;
        for(let i of data){
          if(c > 10){
            if(i[1] == 0 || i[1] ==null){              
              break;
            }
            if( i[31] < 35 ){
              cn = cn + 1;              
            } 
            this.StudentRecordsTT.push({
              //'personal': v,
              'estudiante': i[2].toString(),
              'codigo': i[1].toString(),
              'curso': this.curso,
              'materia': this.materia,
              'trimestre': t,
              'ser': i[6],
              'saber': i[14],
              'hacer': i[22],
              'decidir': i[26],
              'autoser': i[27],
              'promedio': i[29]
            });           
            /*const obj = Object.assign({},i);
            this.StudentRecords3.push(obj);*/
          }
          c = c + 1;
        }
        if( cn > 0 ){
          this.toastr.error('¡UPS, Ten cuidado!', `Existen ${cn} promedios inferiores a 35`, {
            timeOut: 10000,
          });
        }
      }
      setTimeout(() => {        
        this.tabla3 = true;
      },200);
    });
    //this.excelRead2(e);
  }
  /*excelRead2(e){
    let fileReaded: any;
    fileReaded = e.target.files[0];
    let type = e.target.files[0].name.split('.').pop();
    this.StudentRecords2 = [];

    
    readXlsxFile(e.target.files[0], { sheet: 'ASIS 1 TRIM'}).then(( data ) => {
      if(data){
        var c = 0;
        for(let i of data){
          if(c > 6){
            this.StudentRecords2.push(i);
          }
          c = c + 1;
        }
      }
      //console.log(this.StudentRecords2);      
    });    
  }*/

  resguardar(){
    this.carga = true;
    Swal.fire({
      title: '¿Estas Seguro?',
      html: '<p>Estas a punto validar tus calificaciones</p>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._virtualService.guardarCalificaciones(this.StudentRecords).subscribe( resp => {
          this.subirDocumento();
        });
      }else{
        this.carga = false;
      }
    })
  }
  resguardarST(){
    this.carga2 = true;
    Swal.fire({
      title: '¿Estas Seguro?',
      html: '<p>Estas a punto validar tus calificaciones</p>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        //console.log(this.StudentRecordsST);
        this._virtualService.guardarCalificaciones(this.StudentRecordsST).subscribe( resp => {
          this.subirDocumento();
        });
      }else{
        this.carga2 = false;
      }
    })
  }

  resguardarTT(){
    this.carga3 = true;
    Swal.fire({
      title: '¿Estas Seguro?',
      html: '<p>Estas a punto validar tus calificaciones</p>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        //console.log(this.StudentRecordsST);
        this._virtualService.guardarCalificaciones(this.StudentRecordsTT).subscribe( resp => {
          this.subirDocumento();
        });
      }else{
        this.carga3 = false;
      }
    })
  }
  async subirDocumento(){
    this.progress=0;
    this.trimestre = 2; 
    try{
      const url = URL_SERVICIOS + '/uploadCuadernoPedagogico/' + this.material+'/'+this.trimestre;  
      const formData =  new FormData();
      formData.append( 'archivo', this.documentoSubir, this.documentoSubir.name );
      await this.http.put(url,formData, {
        reportProgress: true,
        observe: 'events'
      }).subscribe(event => {
        if(event.type === HttpEventType.UploadProgress){
          this.progress=Math.round(event.loaded / event.total * 100);
        }else if(event.type === HttpEventType.Response){
          if(this.progress === 100){
            this.progress=0;            
            Swal.fire('Documento Cargado', 'Se ha subido el documento', 'success'); 
            //this.consultarGuardado(this.curso,this.materia);
            //this.consultarGuardado2(this.curso,this.materia);
            this.consultarGuardado3(this.curso,this.materia);
            this.carga = false;
          }          
        }
      });
      
    } catch(error){
      console.log(error);
      return false;
    }
  }

  consultarGuardado(curso: string, materia: string){
    let trimestre = 1;
    this._virtualService.consultarCalificaciones2( curso, materia, trimestre )
      .subscribe( (resp: any) => {  
        //console.log(resp);        
        if(resp.length===0){
          this.generado = false;
        }else{
          this.generado = true;
          this.notasEstudiantes = resp;
          console.log(this.notasEstudiantes);          
        }
      })
  }

  consultarGuardado2(curso: string, materia: string){
    let trimestre = 2;
    this._virtualService.consultarCalificaciones2( curso, materia, trimestre )
      .subscribe( (resp: any) => {          
        if(resp.length===0){
          this.generado2 = false;
        }else{
          this.generado2 = true;
          this.notasEstudiantes2 = resp;
          //console.log(this.notasEstudiantes2);
        }
      })
  }
  consultarGuardado3(curso: string, materia: string){
    let trimestre = 3;
    this._virtualService.consultarCalificaciones2( curso, materia, trimestre )
      .subscribe( (resp: any) => {          
        if(resp.length===0){
          this.generado3 = false;
        }else{
          this.generado3 = true;
          this.notasEstudiantes3 = resp;
          //console.log(this.notasEstudiantes2);
        }
      })
  }

  

}

import { NgModule } from "@angular/core";
import { PAGES_ROUTES } from './pages.routes';

import { SharedModule } from '../shared/shared.module';

import { PagesComponent } from './pages.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { ListaComponent } from './lista/lista.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PipesModule } from '../pipes/pipes.module';
import { CrearComponent } from './institucion/crear/crear.component';

import { ListaInstitucionesComponent } from './institucion/lista-instituciones/lista-instituciones.component';
import { CommonModule } from '@angular/common';
import { PrincipalComponent } from './institucion/principal/principal.component';
import { PersonaComponent } from './institucion/persona/persona.component';
import { ModificarComponent } from './institucion/modificar/modificar.component';
import { ListappffComponent } from './institucion/secretaria/listappff.component';
import { PadrehijoComponent } from './institucion/secretaria/padrehijo.component';
import { CursoshabilitadosComponent } from './institucion/secretaria/cursoshabilitados.component';
import { NuevopadreComponent } from './institucion/secretaria/nuevopadre.component';
import { CursoscomplementarioComponent } from './institucion/secretaria/cursoscomplementario.component';
import { ListaestudiantesporcursoComponent } from './institucion/secretaria/listaestudiantesporcurso.component';
import { ZoomComponent } from './lista/zoom.component';
import { CorreccionComponent } from './institucion/secretaria/correccion.component';
import { CursosComponent } from './institucion/cuadernoPedagogico/cursos.component';
import { DataTablesModule } from 'angular-datatables';
import { ElcursoComponent } from './institucion/cuadernoPedagogico/elcurso.component';
import { BoletinComponent } from './institucion/secretaria/boletin.component';
import { RegistroOficialComponent } from './institucion/secretaria/registro-oficial.component';
import { VerCursoComponent } from './institucion/secretaria/ver-curso.component';
import { ListaVentanillaComponent } from './institucion/ventanilla/lista-ventanilla.component';
import { ConfigurarComponent } from './institucion/ventanilla/configurar.component';
import { MateriaDocenteComponent } from './institucion/cuadernoPedagogico/materia-docente.component';
import { ModificarCuadernoComponent } from './institucion/cuadernoPedagogico/modificar-cuaderno.component';



@NgModule({
    declarations: [
        PagesComponent,
        DashboardComponent,
        ListaComponent,
        CrearComponent,
        ListaInstitucionesComponent,
        PrincipalComponent,
        PersonaComponent,
        ModificarComponent,
        ListappffComponent,
        PadrehijoComponent,
        CursoshabilitadosComponent,
        NuevopadreComponent,
        CursoscomplementarioComponent,
        ListaestudiantesporcursoComponent,
        ZoomComponent,
        CorreccionComponent,
        CursosComponent,
        ElcursoComponent,
        BoletinComponent,
        RegistroOficialComponent,
        VerCursoComponent,
        ListaVentanillaComponent,
        ConfigurarComponent,
        MateriaDocenteComponent,
        ModificarCuadernoComponent
        
    ],
    exports: [
        PagesComponent,
        DashboardComponent
        
    ],
    imports: [
        SharedModule,
        PAGES_ROUTES,
        PipesModule,
        ReactiveFormsModule,
        CommonModule,
        FormsModule,
        DataTablesModule,
    ]
})

export class PagesModule {}
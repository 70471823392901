export class Evaluacion {
    constructor(
        public programacion: string,       
        public estimulo: string,
        public url?:  string,
        public imagen?: string,   
        public pregunta?: string,
        public opa?: string,
        public opb?: string,
        public opc?: string,
        public opd?: string,
        public respuesta?: string,
        public puntos?: number,
        public tipo?: string,
        public _id?: string
    ){}
}


import { Component, OnInit } from '@angular/core';
import { MateriaService } from '../../services/service.index';
import { Router } from '@angular/router';
import { Materia } from '../../models/materia.model';

@Component({
  selector: 'app-listamateria',
  templateUrl: './listamateria.component.html',
  styles: []
})
export class ListamateriaComponent implements OnInit {

  title: string;
  limite: number = 0;
  desde: number = 0;
  totalRegistros: number = 0;
  cargando: boolean = true;
  materias: Materia[] = [];

  constructor(
    public router: Router,
    public _materiaService: MateriaService
  ) { 
    this.title = "LISTA DE CURSOS";
  }

  ngOnInit() {
    this.cargarMaterias();
    
  }
  cargarMaterias(){
    this._materiaService.cargarMaterias( this.desde )
        .subscribe( (resp: any) => {
          this.totalRegistros = resp.cuantos;
          this.materias = resp.materia;
          this.cargando = false;
        })
  }
  cambiarDesde( valor: number ){
    let desde = this.desde + valor;
    if( desde >= this.totalRegistros ){
      return;
    }
    if( desde < 0 ){
      return;
    }
    this.desde += valor;
    this.cargarMaterias();
  }
}

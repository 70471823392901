import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modificar',
  templateUrl: './modificar.component.html',
  styles: []
})
export class ModificarComponent implements OnInit {
  title: string;

  constructor() { 
    this.title = "Modificar Datos";
  }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { Evaluacion } from "../../models/evaluacion.model";
import { MaterialService, SubirArchivoService, VirtualService } from '../../services/service.index';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ModalUploadService } from '../../components/modal-upload/modal-upload.service';
import { Material } from '../../models/material.model';


import Swal from 'sweetalert2';
//import { Virtual } from '../../models/virtual.model';
import { Programacion } from '../../models/programacion.model';
import { Preguntas } from '../../models/preguntas.model';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { Ng2ImgMaxService } from 'ng2-img-max';


@Component({
  selector: 'app-modexamen',
  templateUrl: './modexamen.component.html',
  styles: []
})
export class ModexamenComponent implements OnInit {
  identificador: string;
  identificador2: string;
  virtual: string;
  evaluaciones: Evaluacion;
  evaluacion: Evaluacion;
  vir: Programacion;
  direccion: string;
  public imagenSubir: File;
  public imagenTemp: any;
  public documentoSubir: File;
  programaciones2: Programacion[] = [];
  material: Material[]=[];
  numero : number;
  preguntas: Preguntas[] = [];
  todo: Preguntas[] = [];
  done: Preguntas[] = [];
  review: Preguntas[] = [];
  val = ['a','c','s','v','o','ag'];
  uploadResponse: any;

  constructor(
    public _virtualService: VirtualService,
    private activatedRoute: ActivatedRoute,
    public _subirArchivoService: SubirArchivoService,
    public _modalUploadService: ModalUploadService,
    public _materialService: MaterialService,
    private ng2ImgMax: Ng2ImgMaxService
  ) {
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
      this.virtual = params['virtual'];
      this.identificador2 = params['id2'];
    });
    this.evaluacion=new Evaluacion('','');
    this.evaluaciones=new Evaluacion('','','','','');
    this.vir = new Programacion('');
  }
  
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '5rem',
    placeholder: 'Escriba el texto aqui...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['insertVideo'],
      ['insertImage'],
      ['backgroundColor'],
      ['toggleEditorMode'],
      ['redo'],
      ['undo']
    ],
    customClasses: [
      {
        name: "Negrita",
        class: "quote",
        tag: "strong",
      },
      {
        name: 'Mas negrita',
        class: 'redText',
        tag: "b",
      },
      {
        name: "Títulos",
        class: "titleText",
        tag: "h3",
      },
    ]
  };   
  config2: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '5rem',
    placeholder: 'Escriba el texto aqui...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['insertVideo'],
      ['insertImage'],
      ['backgroundColor'],
      ['toggleEditorMode'],
      ['redo'],
      ['undo'],
      ['textColor'],
      ['orderedList'],
      ['unordered'],
      ['outdent'],
      ['indent'],
      ['justifyFull'],
      ['justifyRight'],
      ['justifyCenter'],
      ['justifyLeft'],
      ['link'],
      ['unlink']
    ],
  }; 
  ngOnInit() {
    this.cargarEvaluacion();
    this.cargarDatos(this.identificador2);

  }

  cargarDatos(id: string){
    this._materialService.cargarAulaId( id )
        .subscribe( (resp: any) => {
          this.material = resp.material; 
        })
  }

  evento(e){
    if(e.indexOf('<img') >= 0){
      Swal.fire('Ups!', 'Se ha detectado que esta intentando subir una imagen de manera inapropiada, por favor revise el tutorial', 'error');
    }
  }

  cargarEvaluacion(){
    this._virtualService.cargarpreguntas( this.identificador )
        .subscribe( (virtual: any) => {
          
            this.vir = virtual[0];
            this.numero = (this.vir.preguntas).length;
            for(let i=0; i<this.numero; i++){
              if(this.vir.preguntas[i]['tipo'][0] === 'o'){
                this.preguntas.push({
                  'doc': this.vir.preguntas[i]['doc'],
                  'opa': (this.vir.preguntas[i]['opa']).split(','), 
                  'opb': this.vir.preguntas[i]['opb'], 
                  'opc': this.vir.preguntas[i]['opc'], 
                  'opd' : this.vir.preguntas[i]['opd'], 
                  'pregunta' : this.vir.preguntas[i]['pregunta'], 
                  'puntos' : this.vir.preguntas[i]['puntos'], 
                  'respuesta' : this.vir.preguntas[i]['respuesta'], 
                  'url' : this.vir.preguntas[i]['url'], 
                  '_id' : this.vir.preguntas[i]['_id'],            
                  'tipo' : this.vir.preguntas[i]['tipo'][0]
                });
              }else{
                this.preguntas.push({
                  'doc': this.vir.preguntas[i]['doc'],
                  'opa': this.vir.preguntas[i]['opa'], 
                  'opb': this.vir.preguntas[i]['opb'], 
                  'opc': this.vir.preguntas[i]['opc'], 
                  'opd' : this.vir.preguntas[i]['opd'], 
                  'pregunta' : this.vir.preguntas[i]['pregunta'], 
                  'puntos' : this.vir.preguntas[i]['puntos'], 
                  'respuesta' : this.vir.preguntas[i]['respuesta'], 
                  'url' : this.vir.preguntas[i]['url'], 
                  '_id' : this.vir.preguntas[i]['_id'],            
                  'tipo' : this.vir.preguntas[i]['tipo'][0]
                });
              }
              
            }
            //this.preguntas = this.vir.preguntas;
            //console.log(this.preguntas);
            //this.preguntas =  this.preguntas.sort(function(){return Math.random() - 0.5});
        })
  }
  onDropped(event:CdkDragDrop<any>,array:any[]){
    const anterior = event.previousIndex;
    const actual = event.currentIndex;
    moveItemInArray(array, anterior, actual);
    console.log(array);
  }
  /*drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }*/

  guardarEvaluacion(preguntas:Evaluacion){
    this.evaluacion.pregunta = preguntas.pregunta;
    this.evaluacion.opa = preguntas.opa;
    this.evaluacion.opb = preguntas.opb;
    this.evaluacion.opc = preguntas.opc;
    this.evaluacion.opd = preguntas.opd;
    this.evaluacion.puntos = preguntas.puntos;
    this.evaluacion.respuesta = preguntas.respuesta;
    //this.evaluacion.programacion = this.identificador;
    if(this.evaluacion.pregunta.indexOf('<img') >= 0){
      Swal.fire('No se ha podido guardar', 'Imagenes cargadas inapropiadamente, borre la imagen y por favor revise el tutorial', 'error');
      //this.bandera=true;
    }else{
      this._virtualService.agregarPregunta(this.identificador, this.evaluacion).subscribe( resp => {
        this.cargarEvaluacion();
        this.evaluaciones=new Evaluacion('','','','','');
      });
    }    
  }
  guardarEvaluacionImagen(preguntas:Evaluacion, n:number){
    this.evaluacion.pregunta = preguntas.pregunta;
    this.evaluacion.opa = preguntas.opa;
    this.evaluacion.opb = preguntas.opb;
    this.evaluacion.opc = preguntas.opc;
    this.evaluacion.opd = preguntas.opd;
    this.evaluacion.puntos = preguntas.puntos;
    this.evaluacion.respuesta = preguntas.respuesta;
    this.evaluacion.tipo = this.val[n];
    if(this.imagenSubir){
      if(preguntas.pregunta===''){
        Swal.fire('PREGUNTA VACIA', 'No ha encontrado ninguna pregunta', 'error');
      }else{
        if(this.evaluacion.pregunta.indexOf('<img') >= 0){
          Swal.fire('No se ha podido guardar', 'Imagenes cargadas inapropiadamente, borre la imagen y por favor revise el tutorial', 'error');
        }else{

          this._virtualService.agregarPregunta(this.identificador, this.evaluacion).subscribe( resp => {
            Swal.fire('UN MOMENTO POR FAVOR', 'Se esta cargando la imagen', 'info');
            try{
              const formData =  new FormData();
              formData.append( 'archivo', this.imagenSubir, this.imagenSubir.name );
              this._subirArchivoService.subirArchivo3( formData, this.identificador,resp._id).subscribe( 
                (resp:any) => {
                  this.uploadResponse = resp;
                  if(typeof(this.uploadResponse)!=='number' && this.uploadResponse!==undefined){
                    Swal.fire('Muy bien', 'Se ha logrado agregar la pregunta sin inconveniente', 'success');
                    this.cargarEvaluacion();
                    this.evaluaciones=new Evaluacion('','','','','');
                    this.imagenSubir = null;
                    this.imagenTemp = null;
                  }     
                }
              );
            }
            catch(error){
              console.log(error);
            }

            /*this._subirArchivoService.subirArchivo(this.imagenSubir, this.identificador,resp._id)
              .then( resp => {
                console.log(resp);
                this._modalUploadService.notificacion.emit(resp);
                this.cargarEvaluacion();
                this.evaluaciones=new Evaluacion('','','','','');
                this.imagenSubir = null;
                this.imagenTemp = null;
              })
              .catch( err => {
                console.log('Error en la carga... ');
              });*/
                
          });
        }   
      }
    }else{
      //Swal.fire('FALTA LA IMAGEN', 'No ha seleccionado ninguna imagen para ser agregada en la pregunta', 'error');
      if(this.evaluacion.pregunta.indexOf('<img') >= 0){
        Swal.fire('No se ha podido guardar', 'Imagenes cargadas inapropiadamente, borre la imagen y por favor revise el tutorial', 'error');
        //this.bandera=true;
      }else{
        this._virtualService.agregarPregunta(this.identificador, this.evaluacion).subscribe( resp => {
          this.cargarEvaluacion();
          this.evaluaciones=new Evaluacion('','','','','');
        });
      }  
    }
  }

  seleccionImage( archivo: File ){
    if(!archivo){
      this.imagenSubir = null;
      return;
    }
    if( archivo.type.indexOf('image') < 0 ){
      Swal.fire('Solo imagenes', 'El archivo seleccionado no es una imagen', 'error');
      this.imagenSubir = null;
      return;
    }
    
    this.ng2ImgMax.resizeImage(archivo, 300, 300).subscribe(
    //this.ng2ImgMax.compressImage(archivo, 0.075).subscribe(
      result => {
        this.imagenSubir = result;
        let reader = new FileReader();
        let urlImagenTemp = reader.readAsDataURL( result );
        reader.onloadend = () => this.imagenTemp = reader.result;
      },
      error => {
        console.log('Oh no!', error);
      }
    ) 
  }

  /*subirImagen(){
    let valor = '2';
    let vacio='vacio';
    this._subirArchivoService.subirArchivoSubtitulo(this.imagenSubir, 'evaluacion', this.identificador,valor,vacio)
      .then( resp => {
        this._modalUploadService.notificacion.emit(resp);
        this.cargarEvaluacion();
      })
      .catch( err => {
        console.log('Error en la carga... ');
      });
  }*/

}

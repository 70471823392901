import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CursoService } from '../../../services/service.index';
import { Curso } from '../../../models/curso.model';


@Component({
  selector: 'app-registro-oficial',
  templateUrl: './registro-oficial.component.html',
  styles: [
  ]
})
export class RegistroOficialComponent implements OnInit {
  title: string;
  dtOptions: DataTables.Settings = {};
  cursos: Curso[] = [];
  cargando: boolean = true;

  constructor(
    public _cursoService: CursoService,
    public router: Router
  ) {
    this.title = "Listas Gestión 2022";
    this.dtOptions = {
      pagingType: 'simple',
      pageLength: 10,
      language: {
        url: '//cdn.datatables.net/plug-ins/1.12.0/i18n/es-ES.json'
      },
      order: [[0, 'asc']]
    };
   }

  ngOnInit(): void {
    this.cargarCurso();
  }
  cargarCurso(){
    this._cursoService.cargarCursoSinlimite(  )
      .subscribe( (resp: any) => {
        this.cursos = resp;
        this.cargando = false;
      })
  }

  verLista(id: string){
    this.router.navigate(['/plataforma/ver-curso', id]);    
  }

}

import { Component, OnInit } from '@angular/core';
import { Virtual } from '../../models/virtual.model';
import { VirtualService } from '../../services/service.index';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-materia-tema',
  templateUrl: './materia-tema.component.html',
  styles: []
})
export class MateriaTemaComponent implements OnInit {
  title: string;
  identificador: string;
  virtual: Virtual[] = [];  

  constructor(
    public _virtualService: VirtualService,
    public activatedRoute: ActivatedRoute,
    public router: Router
  ) { 
    this.title = "Mis temas";
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
    });
  }

  ngOnInit() {
    this.cargarVirtual();
  }

  cargarVirtual(){
    this._virtualService.cargarVirtualSinStud( this.identificador )
        .subscribe( (resp: any) => {
          this.virtual = resp;
        })
  }
  aulaTema( id: string ){
    this.router.navigate(['/estudiante/tema-virtual', id]);
  }

}

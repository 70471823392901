import { RouterModule, Routes } from "@angular/router";
import { Input } from '@angular/core';

import { Institucion } from "../models/institucion.model";

import { PpffComponent } from './ppff.component';

import { MisHijosComponent } from './mis-hijos/mis-hijos.component';
import { CursoHijoComponent } from './curso-hijo/curso-hijo.component';

import { LoginGuardGuard } from '../services/service.index';




const ppffRoutes: Routes = [
    {
        path: '', 
        component: PpffComponent,  
        canActivate: [ LoginGuardGuard ],  
        canLoad: [ LoginGuardGuard ],                             
        children: [
            //{path: ':nombre', component: PrincipalComponent}, 
            {path: 'lista-hijos', component: MisHijosComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            {path: 'registro-hijo/:id', component: CursoHijoComponent,canActivate: [ LoginGuardGuard ], canLoad: [LoginGuardGuard]},
            /*{path: 'useruarioperfil', component: ProfileComponent},
            {path: '', redirectTo: '/user-perfil', pathMatch: 'full'}*/
            
        ]
    }
];

export const PPFF_ROUTES = RouterModule.forChild( ppffRoutes );
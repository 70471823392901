import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reversa'
})
export class ReversaPipe implements PipeTransform {

    transform(value) {
        return value.slice().reverse();
    }

}
import { Injectable } from '@angular/core';
import { Ventanilla } from '../../models/ventanilla.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from '../../config/config';
import { User } from '../../models/user.model';
import { Personal } from '../../models/personal.model';

import { map, take } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class VentanillaService {
  token: string;
  ventanilla: Ventanilla;
  user: User;
  persona: Personal;

  constructor(
    public http: HttpClient,
  ) { 
    this.cargarStorage();
  }

  cargarStorage(){
    if( localStorage.getItem('token') ){
      this.token = localStorage.getItem('token');
      this.user = JSON.parse( localStorage.getItem('usuario') );
      this.persona = JSON.parse( localStorage.getItem('personal') );
    }else{
      this.token = '';
    }
  }

  crearVentanilla(){
    
    let institucion = this.user.institucion['_id'];
    let personal = this.persona[0]['_id'];
    let url = URL_SERVICIOS + '/ventanilla/'+institucion+'/'+personal;
    console.log(url);
  console.log(this.token);
  console.log(institucion);
  console.log(personal);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    
    return this.http.post(url, httpOptions).pipe(map( (resp: any) => {
        Swal.fire('Muy bien', 'Se ha creado la correspondencia', 'success');
        return resp.ventanilla;
      }));
  }

  crearVentanilla2(){
    let url = URL_SERVICIOS + '/ventanillaCrear';
    let correspondencia = new Ventanilla(
      this.user.institucion['_id'],
      this.persona[0]['_id']
    );
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    
    return this.http.post(url, correspondencia, httpOptions).pipe(map( (resp: any) => {
        Swal.fire('¡Muy bien!', 'Has registrado un estudiante', 'success');
        return resp.ventanilla;
      }));
  }

  verVentanilla(){
    let institucion = this.user.institucion['_id'];
    let url = URL_SERVICIOS + '/ventanilla/'+institucion;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }

}

export class Tareaemitida {
    constructor(
        public titulo?: string,       
        public virtual?: string,
        public preg?: string,       
        public url?: string,
        public url2?: string,
        public url3?: string,
        public url4?: string,
        public url5?: string,
        public url6?: string,
        //public preguntas?: string,   
        public disponible?: boolean,
        public presentacion?:string,
        public hora?:string,
        public calificacion?:string,
        public plantilla?:number,
        public criterio?:string,
        public minimo?:number,
        public doc?:string,
        public _id?: string
    ){}
}


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PersonalService } from '../../services/service.index';
import { Personal } from '../../models/personal.model';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-mi-cursotutor',
  templateUrl: './mi-cursotutor.component.html',
  styles: []
})
export class MiCursotutorComponent implements OnInit {
  identificador: string;
  title: string;
  personas: Personal[] = []; 
  cargando: boolean = true;

  constructor(
    public activatedRoute: ActivatedRoute,
    public _personalService: PersonalService
  ) { 
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
    });
    this.title = "Mis estudiantes";
  }

  ngOnInit() {
    this.cargarEstudiantesCurso();
  }

  cargarEstudiantesCurso(){
    this._personalService.cargarEstudianteCurso( this.identificador )
        .subscribe( (resp: any) => {
          this.personas = resp.personal;
          this.cargando = false;
        })
  }

  bajaPersonal( personal: Personal ){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de dar de baja a: '+personal.user['nombres'],
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._personalService.bajaPersonal( personal._id )
              .subscribe( personal => {
                this.cargarEstudiantesCurso();
              });
      }
    })
  }

  altaPersonal( personal: Personal ){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de dar de alta a: '+personal.user['nombres'],
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._personalService.altaPersonal( personal._id )
              .subscribe( personal => {
                this.cargarEstudiantesCurso();
              });
      }
    })
  }



}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';

//import { FormGroup, FormControl } from '@angular/forms';
//import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Preguntarea } from '../../models/preguntarea.model';
import { SubirArchivoService, UserService, VirtualService } from '../../services/service.index';
import { Tareaemitida } from '../../models/tareaemitida.model';
import { AngularEditorConfig } from '@kolkov/angular-editor';
//import MathType from '@wiris/mathtype-ckeditor5/src/plugin';
import { URL_SERVICIOS } from '../../config/config';

//import Swal from 'sweetalert2';
import { Tareapresentada } from '../../models/tareapresentada.model';
import { Personal } from '../../models/personal.model';
//import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-calificartarea',
  templateUrl: './calificartarea.component.html',
  styles: []
})
export class CalificartareaComponent implements OnInit {

  identificador: string;
  clase:string;
  idrev:string;
  pregunta: Preguntarea;
  respuesta: Tareapresentada;
  tarea:Tareaemitida;
  public documentoSubir: File;
  public isDisabled = true;
  personal: Personal;
  tareaentregada:Tareapresentada;
  bandera:boolean;
  direccion: string;

  page: number = 1;
  totalPages: number;
  isLoaded: boolean = false;


  constructor(
    public _virtualService: VirtualService,
    public activatedRoute: ActivatedRoute,
    public _subirArchivoService: SubirArchivoService,
    public _userService: UserService,
    private _location: Location
  ) { 
    activatedRoute.params.subscribe( params => {
      this.idrev = params['idrev'];
      this.identificador = params['id'];
      this.clase = params['virtual'];
    });

    this.pregunta=new Preguntarea();
    this.tarea=new Tareaemitida('','');
    this.respuesta=new Tareapresentada('','','','','');
    this.personal = this._userService.personal[0];
  }
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '5rem',
    placeholder: 'Escriba el texto aqui...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['insertVideo'],
      ['insertImage'],
      ['backgroundColor'],
      ['toggleEditorMode']
    ],
    customClasses: [
      {
        name: "Negrita",
        class: "quote",
        tag: "strong",
      },
      {
        name: 'Mas negrita',
        class: 'redText',
        tag: "b",
      },
      {
        name: "Títulos",
        class: "titleText",
        tag: "h3",
      },
    ]
  };   

  ngOnInit() {
    this.cargarTarea(this.identificador);
    this.cargarTareaPresentada(this.idrev);
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }

  calificarTarea( tarea: Tareapresentada ){
    this.respuesta.comentario = tarea.comentario;
    this.respuesta.calificacion = tarea.calificacion;
    this._virtualService.agregarCalificacion(this.respuesta._id,tarea).subscribe( resp => {
      this.cargarTareaPresentada(this.idrev);
      this.backClicked();
    });
  }

  cargarTarea( id: string ){
    this._virtualService.cargarLaTarea( id )
    .subscribe( (resp: any) => {
      this.tarea = resp.tarea[0];      
    })
  }
  cargarTareaPresentada( id: string ){
    this._virtualService.unaTareaPresentada( id )
    .subscribe( (resp: any) => {
      this.respuesta= resp.tarea;  
      if(this.respuesta.doc === ''){
        this.direccion = ' ';
      }else{
        this.direccion = URL_SERVICIOS+'/imagen/estudiante/'+this.respuesta.doc;
      }
    })    
  }
  backClicked() {
    this._location.back();
}
  

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from '../../config/config';
import { Galeria } from "../../models/galeria.model";

import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Personal } from '../../models/personal.model';

@Injectable({
  providedIn: 'root'
})
export class GaleriaService {
  token:string;
  personal: Personal;
  galeria: Galeria;

  constructor(
    public http: HttpClient,
  ) { 
    this.cargarStorage();
  }

  cargarStorage(){
    if( localStorage.getItem('token') ){
      this.token = localStorage.getItem('token');
      this.personal = JSON.parse( localStorage.getItem('personal') );
    }else{
      this.token = '';
    }
  }
  crearGaleria(){
    let personal = this.personal[0]._id;
    let vacio = '';
    let url = URL_SERVICIOS + '/galeria/' + personal;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.post(url, vacio, httpOptions).pipe(map( (resp: any) => {
      //let usuarioDB: User = resp.usuario;
      Swal.fire('Muy Bien', 'Has creado una galeria de imagenes para tu cuenta', 'success');
      return resp.galeria;
    }));
  }

  cargarGaleria(){
    let personal = this.personal[0]._id;
    let url = URL_SERVICIOS + '/lagaleria/'+personal;    
    return this.http.get( url )
    .pipe(map((resp:any) => {
      return resp.galeria;
    }));
    
  }
}

import { Component, OnInit } from '@angular/core';
import { User } from '../../../models/user.model';
import { Personal } from '../../../models/personal.model';
import { Curso } from '../../../models/curso.model';
import { UserService, PersonalService, CursoService } from '../../../services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import { Pago } from '../../../models/pago.model';
import { Location } from '@angular/common';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-padrehijo',
  templateUrl: './padrehijo.component.html',
  styles: []
})
export class PadrehijoComponent implements OnInit {

  title: string;
  public generador: string;
  public hijo: User = new User('','','','');
  public usuario = new User('','','','');
  public per = new Personal('','','','');
  public persona = new Personal('', '', '', '');
  public estudiante: User;
  id_user: string;
  id_personal: string;
  public cursos: Curso[];
  public pago = new Pago();
  public alumno: Personal = new Personal('','','','');

  constructor(
    public _userService: UserService,
    public _personalService: PersonalService,
    public _cursoService: CursoService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private location: Location
  ) {
    this.title = "NOMINA ESTUDIANTES PARA EL PADRE";
    activatedRoute.params.subscribe( params => {
      this.id_personal = params['id'];
      this.id_user = params['user'];
    });
    this.generador = Math.random().toString(36).substr(2, 6);
    this.hijo = new User('','',this.generador+'@tomasfrias.com',this.generador);
   }

  ngOnInit() {
    this.usuario = this._userService.user;
    //this.per =  this._userService.personal[0];
    this.cargarPersona(this.id_personal);
    this.cargarCurso();
  }
  cargarCurso(){
    this._cursoService.cargarCursoSinid()
        .subscribe( (resp: any) => {
          this.cursos = resp;
          //console.log(this.cursos);
        })
  }

  guardarHijos(hijo){
    //console.log(hijo);
    this.hijo.nombres = hijo.nombres;
    this.hijo.paterno = hijo.paterno;
    this.hijo.materno = hijo.materno;
    this.hijo.cedula = hijo.cedula;
    this.hijo.celular = hijo.celular;
    this.hijo.zona = hijo.zona;
    this.hijo.calle = hijo.calle;
    this.hijo.sexo = hijo.sexo;
    this.hijo.becado = hijo.becado;
    this.hijo.porcentaje = hijo.porcentaje;
    this.hijo.rude = hijo.rude;
    this.hijo.origen = hijo.origen;
    this.hijo.aprobo = hijo.aprobo;
    this.hijo.lugarnac = hijo.lugarnac;
    this.hijo.nacimiento = hijo.nacimiento;
    this.hijo.email = hijo.email;
    this.hijo.password = hijo.password;
    this.hijo.institucion = this.usuario.institucion['_id'];  
    
    this._userService.crearUserInstitucion( this.hijo ).subscribe( resp => {    
      this.estudiante = resp;
      this._userService.agregarHijoDependiente( this.estudiante._id, this.id_personal )
      .subscribe(resp => {
        this.alumno.user = this.estudiante._id;
        this.alumno.institucion = this.usuario.institucion;
        this.alumno.role = '2';
        this.alumno.gestiones = hijo.curso;
        this.alumno.cosas1 = hijo.codigo;
        this.alumno.cosas2 = hijo.pension;
        console.log(resp);
        this._personalService.crearAlumnno( this.alumno )
        .subscribe( resp => {
          console.log(resp);
          //this.location.back();
          location.reload();
        });
        //
      })
    });
  }
  /*registroCurso(id){
    this.alumno.user = this.estudiante._id;
    this.alumno.institucion = this.usuario.institucion;
    this.alumno.role = '2';
    this.alumno.gestiones = {'cursos':id};
    //console.log(this.alumno);
    this._personalService.crearAlumnno( this.alumno )
      .subscribe( resp => {
        console.log(resp);
        this.location.back();
      });
  }*/

  cargarPersona( id: string ){
    this._personalService.cargarPersona( id )
        .subscribe( (resp: any) => {
          this.persona = resp.personal;
        })
  }
  miHijo( id: string ){
    this.router.navigate(['/plataforma/registro-curso-estudiante', id]);
  }

  borrarHijo( id: string, hijo: string ){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de borrar un registro',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._personalService.borrarHijo( this.id_personal, id, hijo )
              .subscribe( material => {
                this.cargarPersona(this.id_personal);
              });
      }
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

//import { FormGroup, FormControl } from '@angular/forms';
//import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Preguntarea } from '../../models/preguntarea.model';
import { SubirArchivoService, UserService, VirtualService } from '../../services/service.index';
import { Tareaemitida } from '../../models/tareaemitida.model';
import { AngularEditorConfig } from '@kolkov/angular-editor';

import { ModalUploadService } from '../../components/modal-upload/modal-upload.service';

import Swal from 'sweetalert2';
import { Tareapresentada } from '../../models/tareapresentada.model';
import { Personal } from '../../models/personal.model';
import { URL_SERVICIOS } from '../../config/config';
import {HttpClient, HttpEventType, HttpHeaders, HttpResponse} from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { Ng2ImgMaxService } from 'ng2-img-max';
@Component({
  selector: 'app-hacertarea',
  templateUrl: './hacertarea.component.html',
  styles: []
})
export class HacertareaComponent implements OnInit {

  
  identificador: string;
  clase:string;
  pregunta: Preguntarea;
  respuesta: Tareapresentada;
  respuestaaa: Tareapresentada;
  respuesta2 = new Tareapresentada('','');
  tarea:Tareaemitida;
  public documentoSubir: File;
  public isDisabled = true;
  personal: Personal;
  tareaentregada:Tareapresentada;
  bandera:boolean;
  bandera2:boolean=false;
  banderita:boolean=false;
  nbandera:boolean=false;
  banderacarga:boolean=true;
  mensaje:boolean=false;
  
  progress: number = 0;
  loadbot: boolean;
  loadbot2: boolean;
  //desde aqui 
  selectedFiles=[];
  imagenestemporales = [];
  progressInfo = [];
  message = '';
  filename= '';
  fileInfos : Observable<any>;
  parab : File;
  procesando : boolean;
  visualizador : string = "https://docs.google.com/gview?url=%URL%&embedded=true";


  constructor(
    public _virtualService: VirtualService,
    public activatedRoute: ActivatedRoute,
    public _subirArchivoService: SubirArchivoService,
    public _userService: UserService,
    public _modalUploadService: ModalUploadService,
    private http:HttpClient,
    private ng2ImgMax: Ng2ImgMaxService
  ) { 
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
      this.clase = params['virtual'];
      this.documentoSubir=null;
    });

    this.pregunta=new Preguntarea();
    this.respuesta=new Tareapresentada('','','','','');
    this.respuestaaa=new Tareapresentada('','','','','');
    this.personal = this._userService.personal[0];
    this.bandera2=false;
    this.nbandera=false;
  }
  
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '5rem',
    placeholder: 'Escriba el texto aqui...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['insertVideo'],
      ['insertImage'],
      ['backgroundColor'],
      ['toggleEditorMode']
    ],
    customClasses: [
      {
        name: "Negrita",
        class: "quote",
        tag: "strong",
      },
      {
        name: 'Mas negrita',
        class: 'redText',
        tag: "b",
      },
      {
        name: "Títulos",
        class: "titleText",
        tag: "h3",
      },
    ]
  }; 

  ngOnInit() {
    this.cargarTarea(this.identificador);
    this.cargarTareaPresentadarapida(this.personal._id, this.identificador);
    this.generarTareaPresentada();
    //console.log(this.documentoSubir);
  }

  generarTareaPresentada(){
    this.respuestaaa.personal= this.personal._id;
    this.respuestaaa.virtual=this.clase;
    this.respuestaaa.tarea=this.identificador;
    this._virtualService.agregarRespuesta(this.respuestaaa).subscribe( resp => {
       
      this.respuesta2 = resp; 
      //console.log(this.respuesta2); 
    });
  }
// DIFERENCIAR LA LLEGADA DE DATOS PARA SUBIR, SI ES IMAGEN O SI ES DOCUMENTO PDF
  responderTarea( respuesta: Tareapresentada ){
    this.message = '';
    this.banderacarga=false;
    this.respuesta.personal= this.personal._id;
    this.respuesta.virtual=this.clase;
    this.respuesta.tarea=this.identificador;
    this.respuesta.respuesta = respuesta.respuesta;
    Swal.fire({
      title: 'Guardar Tarea',
      text: 'La Tarea se guardara en plataforma',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        if(this.selectedFiles!==null){
          for( let i=0; i < this.selectedFiles.length; i++){            
            if(i === (this.selectedFiles.length-1)){
              let a = true;
              this.sube(i, this.selectedFiles[i],this.respuesta2._id, a);
            }else{
              let a = false;
              this.sube(i, this.selectedFiles[i],this.respuesta2._id, a);
            }
          }
        }if(this.documentoSubir!==null){
          this.sube2(this.documentoSubir,this.respuesta2._id);
        }
      }else{
        this.banderacarga=true;
        this.loadbot = false;  
      }
    })
  }
  sube(index, file, id, a){
    this.progressInfo[index] = {value:0, filename: file.name};
    this._subirArchivoService.subirImagenesTarea(file,id).subscribe(event => {
      if(event.type === HttpEventType.UploadProgress){
        this.progressInfo[index].value = Math.round(100 * event.loaded / event.total);
      }else if(event instanceof HttpResponse){
        //this.fileInfos = this.fileInfoServi
        //console.log('subido');
        if(a){
          this.tiempo();
        }
      }
    });
  }
  sube2(file, id){
    this.progress=0;
    this._subirArchivoService.subirDocTarea(file,id).subscribe(event => {
      if(event.type === HttpEventType.UploadProgress){
        this.progress = Math.round(100 * event.loaded / event.total);
      }else if(event instanceof HttpResponse){
        //this.fileInfos = this.fileInfoServi
        //console.log('subido');
        this.tiempo();
      }
    });
  }
  eliminaSeleccion(){
    this.selectedFiles = [];
    this.imagenestemporales = [];
  }

  entregarTarea(id:string){
    Swal.fire({
      title: 'Presentación de la Tarea',
      text: 'La Tarea se presentara al docente y no se podra realizar modificación',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._virtualService.presentarTarea(id).subscribe( resp => {       
          this.cargarTareaPresentadarapida(this.personal._id, this.identificador);
          this.mensaje = false;
        })   
      }else{
        this.banderacarga=true;
      }
    });
  }
  a/*sync subirDocTarea(archivo: File, tipo: string, virtual: string ,id: string , mas: string){
    this.progress=0;
    try{
      const url = URL_SERVICIOS + '/uploadsubtitulo/' + tipo + '/' + virtual + '/' + id + '/' + mas;
      const formData =  new FormData();
      formData.append( 'archivo', archivo, archivo.name );
      await this.http.put(url,formData, {
        reportProgress: true,
        observe: 'events'
      }).subscribe(event => {
        //console.log(event);
        if(event.type === HttpEventType.UploadProgress){
          //console.log(Math.round(event.loaded / event.total * 100));
          this.progress=Math.round(event.loaded / event.total * 100);
        }else if(event.type === HttpEventType.Response){
          if(this.progress === 100){
            this.progress=0;
            this.tiempo();
          }          
        }
      });
      
    } catch(error){
      console.log(error);
      this.loadbot = false; 
      return false;
    }
  }*/
  tiempo(){
    setTimeout(() => {
      
      //this._modalUploadService.notificacion.emit(resp);
      this.cargarTarea(this.identificador);
      //this.cargarTareaPresentada(this.personal._id, this.identificador);
      this.cargarTareaPresentadarapida(this.personal._id, this.identificador);
      Swal.fire('Muy bien', 'Se ha guardado la tarea', 'success');  
      this.loadbot = false;  
      this.banderacarga=true; 
      this.selectedFiles=[];
      this.documentoSubir = null;
      this.progressInfo = [];
      this.progress=0;
      this.imagenestemporales = [];
      this.loadbot2 = false; 
    },1000);
  }
  

  /*modificarTarea( respuesta: Tareapresentada ){
    this.loadbot = true; 
    this.banderacarga=false;
    this.respuesta.personal= this.personal._id;
    this.respuesta.virtual=this.clase;
    this.respuesta.tarea=this.identificador;
    this.respuesta.respuesta = respuesta.respuesta;
    if(this.respuesta.respuesta.indexOf('<img') >= 0 ){
      Swal.fire('Ups!', 'Se ha detectado que esta intentando subir una imagen o video de manera inapropiada, por favor revise el tutorial', 'error');
      this.banderacarga=true;
    }else{
      Swal.fire({
        title: 'Modificar Tarea',
        text: 'Se guardará la modificación de la Tarea en plataforma',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '¡Si, estoy seguro!'
      }).then((result) => {
        if (result.value) {       
          this._virtualService.modificarRespuesta(this.respuesta,this.respuesta._id).subscribe( resp => {
            if(this.documentoSubir!==null){
              let valor = this.personal._id;
              this.subirDocTarea(this.documentoSubir, 'estudiante', resp._id,valor,this.clase);              
            }else{
              Swal.fire('Tarea Guardada', 'Se ha guardado las modificaciones en la tarea con exito', 'success');
              this.cargarTarea(this.identificador);
              this.cargarTareaPresentada(this.personal._id, this.identificador);
              this.banderacarga=true;    
              this.loadbot = false;          
            }    
          });
        }else{
          this.banderacarga=true;
          this.loadbot = false;  
        }
      })
    }
  }*/

  async cargarTarea( id: string ){    
    this._virtualService.cargarLaTarea( id )
    .subscribe( (resp: any) => {
      this.tarea = resp.tarea[0];      
    })
  }
  cargarTareaPresentada( personal: string, tarea: string ){
    this._virtualService.cargarTareaPresentada( personal, tarea )
    .subscribe( (resp: any) => {
      if(resp.tarea.length > 0){
        if(resp.tarea[0].cargado){
          if(!resp.tarea[0].entregado){  
            this.bandera=true;
            this.bandera2=true;
            this.mensaje = true;
            this.respuesta = resp.tarea[0]; 
            if(this.respuesta.doc !== ''){
              this.banderita=false;
            }
            if(this.respuesta.oportunidad){
              this.nbandera = true;
            }
          }else{
            this.bandera=false;
            this.bandera2=true;
            this.respuesta = resp.tarea[0]; 
            if(this.respuesta.doc !== ''){
              this.banderita=false;
            }
          }
        }else{
          if(!resp.tarea[0].entregado){  
            this.bandera=true;
            this.bandera2=true;
            this.mensaje = false;
            this.respuesta = resp.tarea[0];
            if(this.respuesta.doc !== ''){
              this.banderita=false;
            }
            if(this.respuesta.oportunidad){
              this.nbandera = true;
            }
          }else{
            this.bandera=false;
            this.bandera2=true;
            this.respuesta = resp.tarea[0]; 
            if(this.respuesta.doc !== ''){
              this.banderita=false;
            }
          }
        }
      }else{
        this.bandera=true;
        this.bandera2=false;
      }
    })    
  }
  cargarTareaPresentadarapida( personal: string, tarea: string ){
    this._virtualService.cargarTareaPresentada( personal, tarea )
    .subscribe( (resp: any) => {     
      if(resp.tarea.length > 0){  
        this.respuesta2 = resp.tarea[0];   
        if(!resp.tarea[0].entregado){
          this.bandera2=false;
          this.cargarTareaPresentada(personal,tarea);
        }else{
          this.bandera2=true;
          this.bandera=false;
          this.cargarTareaPresentada(personal,tarea);
        }
      }else{
        this.bandera2=true;
        this.bandera=true;
      }
    })    
  }
  optimiza(arc:File,n){    
    try {
      this.ng2ImgMax.compressImage(arc,0.075).subscribe(
        result => {
          //this.selectedFiles[n] = new File([result],result.name);
          this.selectedFiles[n] = result;
          let reader = new FileReader();
          let urlImagenTemp = reader.readAsDataURL( result );
          reader.onloadend = () => this.imagenestemporales[n] = reader.result;
          //console.log(this.imagenestemporales);
        },error => {
          console.log(error);
        }
      );
      
    }catch(err) {
      console.log(err);
    }
  };
  selectFiles(event){
    this.selectedFiles = [];
    this.imagenestemporales = [];
    try {
      this.progressInfo = [];
      event.target.files.length == 1 ? this.filename = event.target.files[0] : this.filename = event.target.files.length+'archivo';
      //this.selectedFiles = event.target.files;
      const arr = new Array(event.target.files.length);
      if(event.target.files.length > 0){    
        this.loadbot2 = true; 
        this.procesando = true;
        //console.log(this.procesando);
        for(let i=0; i < event.target.files.length;i++ ){
          this.parab = event.target.files[i];
          this.optimiza(this.parab,i);         
        }
      }
      setTimeout(() =>this.procesando = false,5000);
    }catch(e){
      console.log(e);
    }
  }
  seleccionDocumento( archivo: File ){
    if(!archivo){
      this.documentoSubir = null;
      return;
    }
    if( archivo.type.indexOf('image') === 0 ){
      Swal.fire('Solo documentos', 'El archivo seleccionado es una imagen', 'error');
      this.documentoSubir = null;
      return;
    }else{
      if( archivo.size < 14680064 ){
        this.documentoSubir = archivo;
        this.loadbot2 = true; 
        return;
      }else{
        Swal.fire('El documento es demasiado grande', 'El documento  no debe exceder de 13 Mb', 'error');
        this.documentoSubir = null;
        return;
      }
    }
  }
  
  subirDocumento(){
    let valor = this.personal._id;

    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de entregar la tarea ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._subirArchivoService.subirArchivoSubtitulo(this.documentoSubir, 'estudiante', this.tarea._id,valor,this.clase)        
      .then( resp => {
        this.documentoSubir = null;
        this.banderita=true;
        //console.log(resp);
        this._modalUploadService.notificacion.emit(resp);
        this.cargarTarea(this.identificador);
        this.cargarTareaPresentada(this.personal._id, this.identificador);
      })
      .catch( err => {
        console.log('Error en la carga... ');
      });
      }
    })    
  }
  //////borrar
  downscaleImage(dataUrl, newWidth, imageType, imageArguments) {
    var image, oldWidth, oldHeight, newHeight, canvas, ctx, newDataUrl;

    // Provide default values
    imageType = imageType || "image/jpeg";
    imageArguments = imageArguments || 0.7;

    // Create a temporary image so that we can compute the height of the downscaled image.
    image = new Image();
    image.src = dataUrl;
    oldWidth = image.width;
    oldHeight = image.height;
    newHeight = Math.floor(oldHeight / oldWidth * newWidth)

    // Create a temporary canvas to draw the downscaled image on.
    canvas = document.createElement("canvas");
    canvas.width = newWidth;
    canvas.height = newHeight;

    // Draw the downscaled image on the canvas and return the new data URL.
    ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0, newWidth, newHeight);
    newDataUrl = canvas.toDataURL(imageType, imageArguments);
    return newDataUrl;
}

}

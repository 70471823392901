import { Component, OnInit } from '@angular/core';
import { Personal } from '../../../models/personal.model';
import { Listas } from '../../../models/listas.model';
import { ActivatedRoute } from '@angular/router';
import { CursoService, PersonalService } from '../../../services/service.index';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Curso } from '../../../models/curso.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-listaestudiantesporcurso',
  templateUrl: './listaestudiantesporcurso.component.html',
  styles: []
})
export class ListaestudiantesporcursoComponent implements OnInit {
  forma2: FormGroup;

  identificador: string;
  title: string;
  personas: Personal[] = []; 
  cargando: boolean = true;
  miLista: Listas[] = [];
  miLista2: Listas[] = [];
  public cursos: Curso[];

  constructor(
    public activatedRoute: ActivatedRoute,
    public _personalService: PersonalService,
    public _cursoService: CursoService,
  ) { 
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
    });
    this.title = "Nominas";
  }

  ngOnInit() {
    this.cargarEstudiantesCursoa("2023");
    this.cargarEstudiantesCurso("2024");
    this.forma2 = new FormGroup({
      curso: new FormControl(null, Validators.required)
    });
    this.cargarCurso();
  }

  cargarCurso(){
    this._cursoService.cargarCursoSinid()
        .subscribe( (resp: any) => {
          this.cursos = resp;
          //console.log(this.cursos);
        })
  }

  registraTodos(){
    let gestion = '2023';
    this._personalService.CursoSuperior( this.identificador, gestion,this.forma2.value.curso)
              .subscribe( ok => {
                //console.log(ok);
                //location.reload();
              });

  }

  bajaTodos(gestion: string){
    this._personalService.darBajaTodos( this.identificador, gestion)
              .subscribe( ok => {
                //console.log(ok);
                location.reload();
              });
  }

  cargarEstudiantesCursoa(gestion:string){
    this._personalService.cargarEstudianteCursoComplementariolistas( this.identificador, gestion )
        .subscribe( (resp: any) => {
          this.personas = resp.personal;
          //console.log( this.personas);
          for(let i=0;i<this.personas.length;i++){            
            //if(this.personas[i].gestiones.length===3){
              for(let j=0;j<this.personas[i].gestiones.length;j++){
                if(this.personas[i].gestiones[j].cursos===this.identificador && this.personas[i].gestiones[j].gestion==='2023'){
                  this.miLista.push({
                    'user' : this.personas[i].user['_id'],
                    'personal': this.personas[i]._id,
                    'paterno' : this.personas[i].user['paterno'],
                    'materno' : this.personas[i].user['materno'],
                    'nombres' : this.personas[i].user['nombres'],
                    'email' : this.personas[i].user['email'],
                    'cedula' : this.personas[i].user['cedula'],
                    'zona' : this.personas[i].user['zona'],
                    'calle' : this.personas[i].user['calle'],
                    'padre' : this.personas[i].user['padre'],
                    'celpadre' : this.personas[i].user['celpadre'],
                    'madre' : this.personas[i].user['madre'],
                    'celmadre' : this.personas[i].user['celmadre'],
                    'estado' : this.personas[i].estado
                  });
                }
              }
              /* if(this.personas[i].gestiones[2].cursos===this.identificador){
                this.miLista.push({
                  'user' : this.personas[i].user['_id'],
                  'personal': this.personas[i]._id,
                  'paterno' : this.personas[i].user['paterno'],
                  'materno' : this.personas[i].user['materno'],
                  'nombres' : this.personas[i].user['nombres'],
                  'email' : this.personas[i].user['email'],
                  'cedula' : this.personas[i].user['cedula'],
                  'zona' : this.personas[i].user['zona'],
                  'calle' : this.personas[i].user['calle'],
                  'padre' : this.personas[i].user['padre'],
                  'celpadre' : this.personas[i].user['celpadre'],
                  'madre' : this.personas[i].user['madre'],
                  'celmadre' : this.personas[i].user['celmadre'],
                  'estado' : this.personas[i].estado
                });
              }              
            }
            else if(this.personas[i].gestiones.length===2){
              if(this.personas[i].gestiones[1].cursos===this.identificador){
                this.miLista.push({
                  'user' : this.personas[i].user['_id'],
                  'personal': this.personas[i]._id,
                  'paterno' : this.personas[i].user['paterno'],
                  'materno' : this.personas[i].user['materno'],
                  'nombres' : this.personas[i].user['nombres'],
                  'email' : this.personas[i].user['email'],
                  'cedula' : this.personas[i].user['cedula'],
                  'zona' : this.personas[i].user['zona'],
                  'calle' : this.personas[i].user['calle'],
                  'padre' : this.personas[i].user['padre'],
                  'celpadre' : this.personas[i].user['celpadre'],
                  'madre' : this.personas[i].user['madre'],
                  'celmadre' : this.personas[i].user['celmadre'],
                  'estado' : this.personas[i].estado
                });
              }              
            }    
            else{
              if(this.personas[i].gestiones[0].cursos===this.identificador){
                this.miLista.push({
                  'user' : this.personas[i].user['_id'],
                  'personal': this.personas[i]._id,
                  'paterno' : this.personas[i].user['paterno'],
                  'materno' : this.personas[i].user['materno'],
                  'nombres' : this.personas[i].user['nombres'],
                  'email' : this.personas[i].user['email'],
                  'cedula' : this.personas[i].user['cedula'],
                  'zona' : this.personas[i].user['zona'],
                  'calle' : this.personas[i].user['calle'],
                  'padre' : this.personas[i].user['padre'],
                  'celpadre' : this.personas[i].user['celpadre'],
                  'madre' : this.personas[i].user['madre'],
                  'celmadre' : this.personas[i].user['celmadre'],
                  'estado' : this.personas[i].estado
                });
              } 
            } */          
          }
          this.cargando = false;
        })
  }
  cargarEstudiantesCurso(gestion:string){
    this._personalService.cargarEstudianteCursoComplementariolistas( this.identificador, gestion )
        .subscribe( (resp: any) => {
          this.personas = resp.personal;       
          console.log( this.personas);   
          for(let i=0;i<this.personas.length;i++){            
            //if(this.personas[i].gestiones.length===3){
              for(let j=0;j<this.personas[i].gestiones.length;j++){
                if(this.personas[i].gestiones[j].cursos===this.identificador && this.personas[i].gestiones[j].gestion==='2024'){
                  this.miLista2.push({
                  'user' : this.personas[i].user['_id'],
                  'personal': this.personas[i]._id,
                  'paterno' : this.personas[i].user['paterno'],
                  'materno' : this.personas[i].user['materno'],
                  'nombres' : this.personas[i].user['nombres'],
                  'email' : this.personas[i].user['email'],
                  'cedula' : this.personas[i].user['cedula'],
                  'zona' : this.personas[i].user['zona'],
                  'calle' : this.personas[i].user['calle'],
                  'padre' : this.personas[i].user['padre'],
                  'celpadre' : this.personas[i].user['celpadre'],
                  'madre' : this.personas[i].user['madre'],
                  'celmadre' : this.personas[i].user['celmadre'],
                  'estado' : this.personas[i].estado
                });
              }              
            }
            
           /* else  if(this.personas[i].gestiones.length===2){
              if(this.personas[i].gestiones[1].cursos===this.identificador){
                this.miLista2.push({
                  'user' : this.personas[i].user['_id'],
                  'personal': this.personas[i]._id,
                  'paterno' : this.personas[i].user['paterno'],
                  'materno' : this.personas[i].user['materno'],
                  'nombres' : this.personas[i].user['nombres'],
                  'email' : this.personas[i].user['email'],
                  'cedula' : this.personas[i].user['cedula'],
                  'zona' : this.personas[i].user['zona'],
                  'calle' : this.personas[i].user['calle'],
                  'padre' : this.personas[i].user['padre'],
                  'celpadre' : this.personas[i].user['celpadre'],
                  'madre' : this.personas[i].user['madre'],
                  'celmadre' : this.personas[i].user['celmadre'],
                  'estado' : this.personas[i].estado
                });
              }              
            }
            else{
              if(this.personas[i].gestiones[0].cursos===this.identificador){
                this.miLista2.push({
                  'user' : this.personas[i].user['_id'],
                  'personal': this.personas[i]._id,
                  'paterno' : this.personas[i].user['paterno'],
                  'materno' : this.personas[i].user['materno'],
                  'nombres' : this.personas[i].user['nombres'],
                  'email' : this.personas[i].user['email'],
                  'cedula' : this.personas[i].user['cedula'],
                  'zona' : this.personas[i].user['zona'],
                  'calle' : this.personas[i].user['calle'],
                  'padre' : this.personas[i].user['padre'],
                  'celpadre' : this.personas[i].user['celpadre'],
                  'madre' : this.personas[i].user['madre'],
                  'celmadre' : this.personas[i].user['celmadre'],
                  'estado' : this.personas[i].estado
                });
              } 
            }*/         
          }
          this.cargando = false;
        })
  }

  bajaPersonal( personal: Personal ){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de dar de baja a: '+personal['nombres'],
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._personalService.bajaPersonal( personal['personal'] )
              .subscribe( personal => {
                location.reload();
              });
      }
    })
  }

  altaPersonal( personal: Personal ){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de dar de alta a: '+personal['nombres'],
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._personalService.altaPersonal( personal['personal'] )
              .subscribe( personal => {
                location.reload();
              });
      }
    })
  }

}

export class User {
    constructor(
        public nombres: string,
        public paterno: string,
        public email:string,
        public password:string,
        
        public sexo?: string,
        public rude?: string,
        public nacimiento?:string,
        public cedula?: string,
        public celular?:number,
        public displayName?: string,
        public username?:string,
        public materno?: string,
        public estadocivil?:string,
        public idioma?:string,
        public zona?:string,
        public calle?:string,
        public ocupacion?:string,
        public instruccion?: string,
        public profileImageURL?:string,
        public roles?: string,
        public role?: string,
        public latitud?: number,
        public longitud?:number,
        public gsanguineo?: string,
        public pais?: string,
        public departamento?:string,
        public institucion?:string,
        public alergia?: string,
        public enfermedad?:string,
        public tipodeficit?:string,
        public caracdeficit?:string,
        public obsmedica?: string,
        public fallecimientopadre?: string,
        public fallecimientomadre?:string,
        public conviven?: string,
        public obsfam?:string,
        public becado?:string,
        public porcentaje?:number,
        public categoriabeca?:string,
        public caracaprendz?: string,
        public dificultadaprendz?:string,
        public medidaseduc?: string,
        public necedudiag?: string,
        public intervencionedc?: string,
        public parentezco?: string,
        public lugarnac?: string,
        public origen?: string,
        public aprobo?: string,
        public fecharetirado?: string,
        public padre?:string,
        public cipadre?: string,
        public celpadre?: number,
        public wpadre?: boolean,
        public madre?:string,
        public cimadre?: string,
        public celmadre?: number,
        public wmadre?: boolean,
        public tutor?: string,
        public _id?: string
    ){}
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { User } from '../../models/user.model';
import { UserService } from '../../services/service.index';


@Component({
  selector: 'app-pass',
  templateUrl: './pass.component.html',
  styles: []
})
export class PassComponent implements OnInit {
  identificador : string;
  public usuario = new User('','','','');

  constructor(
    public activatedRoute: ActivatedRoute,
    public _userService: UserService
  ) { 
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
    });
  }

  ngOnInit() {
    this.cargarUsuario();
  }

  cargarUsuario(){
    this._userService.cargarUsuario( this.identificador )
        .subscribe( (resp: any) => {
          this.usuario = resp.usuario;
          console.log(this.usuario);
        })
  }

  actualizaPass( usuario: User ){
    this.usuario.password = usuario.password;
    this._userService.actualizarUserPass( this.usuario )
      .subscribe();
  }

}

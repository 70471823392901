import { Component, OnInit } from '@angular/core';
import { VirtualService, UserService, CursoService } from '../../services/service.index';
import { ActivatedRoute, Router } from '@angular/router';
import { Virtual } from '../../models/virtual.model';

import { URL_SERVICIOS } from '../../config/config';


import { Tareaemitida } from '../../models/tareaemitida.model';
import { Programacion } from '../../models/programacion.model';
import { User } from '../../models/user.model';
import { Personal } from 'src/app/models/personal.model';
import { Curso } from 'src/app/models/curso.model';
import { Consulta } from "../../models/consulta.model";

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Subtitulo } from "../../models/subtitulo.model";

/*import '../../../vendor/jitsi/external_api.js';
declare var JitsiMeetExternalAPI: any;*/

@Component({
  selector: 'app-pasar-tema',
  templateUrl: './pasar-tema.component.html',
  styles: []
})
export class PasarTemaComponent implements OnInit {
  virtual: Virtual;  
  direccion1: string;
  direccion2: string;
  direccion3: string;
  direccion4: string;
  identificador: string;
  msbapAudioUrl: string;
  title: string;
  uno:boolean=false;
  dos:boolean=false;
  tres:boolean=false;
  consulta:Consulta;

  public isDisabled = true;

  /*public domain:string = "meet.jit.si";
  	public options: any;
    public api: any;*/
    tareas: Tareaemitida[]=[];
    limite: number = 0;
  desde: number = 0;
  totalRegistros: number = 0;
  programaciones: Programacion;
  public usuario = new User('','','','');
  public personal = new Personal('','','','');
  public curso = new Curso('', '', 0);
  pruebita:string;

  page: number = 1;
  totalPages: number;
  isLoaded: boolean = false;
  respuesta: boolean = false;
  sub : Subtitulo[] = [];
  public dispo : boolean = false;
  

  constructor(
    public _virtualService: VirtualService,
    public activatedRoute: ActivatedRoute,
    public _userService: UserService,
    public _cursoService: CursoService,
    public router: Router
  ) { 
    this.title = "Tema Virtual";
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
    });
    this.virtual=new Virtual('','',0);
    //this.tareas=new Tareaemitida('','','');
    this.usuario = this._userService.user;
    this.personal = this._userService.personal[0];
    this.pruebita = this.personal.gestiones[0].cursos;
    this.consulta = new Consulta('','','','','','');
  }

  config2: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '5rem',
    placeholder: 'Escriba el texto aqui...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['insertVideo'],
      ['insertImage'],
      ['backgroundColor'],
      ['toggleEditorMode'],
      ['undo'],
      ['redo']
    ]
  };


  ngAfterViewInit(){
    this.activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
    });
     
		
    }

  ngOnInit() {
    this.cargarVirtual();
    this.cargarTareas(this.identificador);
    this.cargarEvaluacion();
    this.elCurso();
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }
  verImagen(e){
    if(e===null){return true;}
    if(e.indexOf('http') >= 0 ){
      return true;
    }else{
      return false;
    }
  }
  elCurso(){
    this._cursoService.cargarId(this.pruebita)
    .subscribe((resp:any) => {
      this.curso = resp;
    })
  }
  cargarVirtual(){
    this._virtualService.cargarTemaVirtual( this.identificador )
        .subscribe( (resp: any) => {
          this.virtual = resp[0];
          //console.log(this.virtual);
          for(let i = 0; i < this.virtual.contenido.length; i++){
            this.sub.push({
              'url' : this.virtual.contenido[i]['url'],
              'img1' : this.virtual.contenido[i]['img1'],
              'img3' : 'https://drive.google.com/file/d/'+this.virtual.contenido[i]['img3']+'/preview',
              'img5' : this.virtual.contenido[i]['img5'],
              'subtitulo' : this.virtual.contenido[i]['subtitulo'],
              'concepto' : this.virtual.contenido[i]['concepto'],
              'plantilla' : this.virtual.contenido[i]['plantilla'],
              '_id' : this.virtual.contenido[i]['_id']
            });
          }  
          this.direccion1 = URL_SERVICIOS+'/imagen/tema/'+this.virtual.doc1;
          this.direccion2 = URL_SERVICIOS+'/imagen/tema/'+this.virtual.doc2;
          this.direccion3 = URL_SERVICIOS+'/imagen/tema/'+this.virtual.doc3;
          this.direccion4 = URL_SERVICIOS+'/imagen/tema/'+this.virtual.video;
          this.msbapAudioUrl = URL_SERVICIOS+'/imagen/tema/'+this.virtual.audio;
          if(this.virtual.grabacion1!==''){
            this.uno=true;
          }
          if(this.virtual.grabacion2!==''){
            this.dos=true;
          }
          if(this.virtual.grabacion3!==''){
            this.tres=true;
          }
        })
  }

  cargarTareas( id: string ){
    this._virtualService.cargarTareas( this.desde, id )
    .subscribe( (resp: any) => {
      this.totalRegistros = resp.cuantos;
      this.tareas = resp.tarea;
      for(let i = 0; i < this.tareas.length; i++){
        if(Date.parse(resp.hoy) <= Date.parse(this.tareas[i].presentacion)){
          if(this.tareas[i].disponible===false){
            this.dispo = true;
          }else{
            this.dispo = false;
          }
          
        }else{
          this.dispo = true;
          this.tareaOffline(this.tareas[i]._id);          
        }
      }
    })
  }
  tareaOffline(tarea: string){
    this._virtualService.offlineTareaaa( tarea )
          .subscribe( tarea => {
            this.cargarTareas(this.identificador);
          });
  }

  cargarEvaluacion(){
    this._virtualService.cargarEvaluacionVirtual( this.identificador )
        .subscribe( (resp: any) => {
          this.programaciones = resp;
        })
  }
  cambiarDesde( valor: number ){
    let desde = this.desde + valor;
    if( desde >= this.totalRegistros ){
      return;
    }
    if( desde < 0 ){
      return;
    }
    this.desde += valor;
    this.cargarTareas( this.identificador);
  }
  hacerTarea( id : string ){
    this.router.navigate(['/estudiante/realizar-tarea', id, this.identificador]);
  }
  hacerEvaluacion( id : string ){
    this.router.navigate(['/estudiante/realizar-evaluacion', id, this.identificador]);
  }

  /*cargarAulaVivo( sala: number, clave: number ){
    let url= "https://tomasfrias.com/#/estudiante/clase-zoom/"+sala+"/"+clave;
    window.open(url, "nuevo", "directories=no, location=no, menubar=no, scrollbars=yes, statusbar=no, tittlebar=no");
  }*/
  agreagaConsulta( consulta: Consulta, id: string ){
    this.consulta.descripcion = consulta.descripcion;
    this.consulta.personal = this.personal._id;
    this._virtualService.agregarConsulta(id,this.consulta)
      .subscribe( (resp: any) => {
        this.cargarVirtual();
      });
  }

  respuestA(){
    this.respuesta ? this.respuesta = false : this.respuesta = true;
  }
  agreagaRespuesta( consulta: Consulta, id: string, foro: string){
    console.log(consulta);
    console.log(id);
    console.log(foro);
  }

}

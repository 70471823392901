import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VirtualService } from '../../services/service.index';
import { Virtual } from '../../models/virtual.model';


@Component({
  selector: 'app-estadisdesc',
  templateUrl: './estadisdesc.component.html',
  styles: [
  ]
})
export class EstadisdescComponent implements OnInit {
  identificador : string;
  virtual: Virtual[] = [];  

  constructor(
    public activatedRoute: ActivatedRoute,
    public _virtualService: VirtualService,
  ) {
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
    });
   }

  ngOnInit(): void {
    this.cargarVirtual();
  }
  cargarVirtual(){
    this._virtualService.cargarVirtualSin( this.identificador )
        .subscribe( (resp: any) => {
          this.virtual = resp;  
          console.log(this.virtual);     
        })
  }

}

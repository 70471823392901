import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_SERVICIOS } from '../../config/config';
import { Materia } from "../../models/materia.model";
import { Criterio } from "../../models/criterio.model";

import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { User } from '../../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class MateriaService {

  token:string;
  user: User;
  materia: Materia;

  constructor(
    public http: HttpClient,
  ) { 
    this.cargarStorage();
  }

  cargarStorage(){
    if( localStorage.getItem('token') ){
      this.token = localStorage.getItem('token');
      this.user = JSON.parse( localStorage.getItem('usuario') );
    }else{
      this.token = '';
    }
  }

  crearMateria( materia: Materia ){
    let url = URL_SERVICIOS + '/materia';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    
    return this.http.post(url, materia, httpOptions).pipe(map( (resp: any) => {
        Swal.fire('Creando Materia', 'Creaste una nueva materia', 'success');
        return resp.materia;
      }));
  }

  cargarMaterias( desde: number = 0 ){
    let institucion = this.user.institucion['_id'];
    let limite = 5;
    let url = URL_SERVICIOS + '/materias/'+institucion+'?limite='+limite+'&desde='+desde;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }

  cargarMateriaSin(institucion){
    let url = URL_SERVICIOS + '/materiaSin/'+institucion;
    
    return this.http.get( url )
    .pipe(map((resp:any) => {
      return resp.materia;
    }));    
  }

  cargarMateriaId(id){
    let url = URL_SERVICIOS + '/materiaId/'+id;
    
    return this.http.get( url )
    .pipe(map((resp:any) => {
      return resp.materia;
    }));    
  }

  crearCriterio( criterio: Criterio ){
    let url = URL_SERVICIOS + '/criterio';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    
    return this.http.post(url, criterio, httpOptions).pipe(map( (resp: any) => {
        Swal.fire('Creando Criterio', 'Creaste un nuevo criterio', 'success');
        return resp.criterio;
      }));
  }

  cargarCriterio(curso, materia){
    let url = URL_SERVICIOS + '/criterio/'+curso+'/'+materia;
    
    return this.http.get( url )
    .pipe(map((resp:any) => {
      return resp.criterio;
    }));    
  }

  borrarCriterio( id: string ){
    let url = URL_SERVICIOS + '/criterio/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.delete( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Se ha eliminado', 'El criterio seleccionado', 'success');
      return true;
    }));
  }
}

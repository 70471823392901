import { RouterModule, Routes } from "@angular/router";

//import { PagesComponent } from './pages/pages.component';


import { SiteComponent } from './login/site.component';
import { LoginGuardGuard } from './services/service.index';

import { RegisterComponent } from './login/register.component';
import { LoginComponent } from './login/login.component';
import { NopagefoundComponent } from './shared/nopagefound/nopagefound.component';
import { ReservaComponent } from './login/reserva.component';


const appRoutes: Routes = [  
    {path: 'index', component: SiteComponent}, 
    {path: 'login', component: LoginComponent},  
    {path: 'register', component: RegisterComponent},    
    {path: 'curso-complementario', component: ReservaComponent},  
    {
        path: 'plataforma',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
        canActivate: [LoginGuardGuard],
        canLoad: [ LoginGuardGuard ] 
    },
    {
        path: 'estudiante',
        loadChildren: () => import('./estudiante/estudiante.module').then(m => m.EstudianteModule),
        canActivate: [LoginGuardGuard],
        canLoad: [ LoginGuardGuard ]
    },
    {
        path: 'ppff',
        loadChildren: () => import('./ppff/ppff.module').then(m => m.PpffModule),
        canActivate: [LoginGuardGuard],
        canLoad: [ LoginGuardGuard ] 
    },
    {
        path: 'institucion',
        loadChildren: () => import('./profesor/profesor.module').then(m => m.ProfesorModule),
        canActivate: [LoginGuardGuard],
        canLoad: [ LoginGuardGuard ] 
    },
    {
        path: 'user',
        loadChildren: () => import('./usuario/usuario.module').then(m => m.UsuarioModule),
        canActivate: [LoginGuardGuard],
        canLoad: [ LoginGuardGuard ]
    },
    {path: '', redirectTo: '/index', pathMatch: 'full'},
    {path: '**', component: NopagefoundComponent}
];

export const APP_ROUTES = RouterModule.forRoot( appRoutes, { useHash: true, anchorScrolling: 'enabled' });
import { Component, OnInit } from '@angular/core';
import { UserService, GaleriaService, SubirArchivoService } from '../../services/service.index';
import { User } from '../../models/user.model';
import { Personal } from '../../models/personal.model';
import { Enlace } from '../../models/enlace.model';
import { Router } from '@angular/router';
import {HttpClient, HttpEventType, HttpHeaders} from '@angular/common/http';
import { URL_SERVICIOS } from '../../config/config';

import Swal from 'sweetalert2';
import { Galeria } from '../../models/galeria.model';
import { Ng2ImgMaxService } from 'ng2-img-max';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styles: []
})
export class GaleriaComponent implements OnInit {
  title: string;
  public usuario = new User('','','','');
  public galeria= new Galeria();
  public personal = new Personal('','','','');
  public enlace = new Enlace();

  public imagenSubir: File;
  public imagenTemp: any;

  public bandera: boolean = false;
  public bandera2: boolean = false;

  progress: number = 0;
  progress2: number = 0;

  

  constructor(
    public _userService: UserService,
    public _galeriaService: GaleriaService,
    public _subirArchivoService: SubirArchivoService,
    private http:HttpClient,
    public router: Router,
    private ng2ImgMax: Ng2ImgMaxService
  ) { 
    this.title = "Mi Galeria";
  }

  ngOnInit() {
    this.personal = this._userService.personal;
    this.cargarGaleria();
  }

  seleccionImage( archivo: File ){
    if(!archivo){
      this.imagenSubir = null;
      return;
    }
    if( archivo.type.indexOf('image') < 0 ){
      Swal.fire('Solo imagenes', 'El archivo seleccionado no es una imagen', 'error');
      this.imagenSubir = null;
      return;
    }
    
    //this.ng2ImgMax.resizeImage(archivo, 100, 100).subscribe(
    this.ng2ImgMax.compressImage(archivo, 0.075).subscribe(
      result => {
        this.imagenSubir = result;
        let reader = new FileReader();
        let urlImagenTemp = reader.readAsDataURL( result );
        reader.onloadend = () => this.imagenTemp = reader.result;
      },
      error => {
        console.log('Oh no!', error);
      }
    )
  }
  cargarGaleria(){
    this._galeriaService.cargarGaleria()    
    .subscribe( (resp: any) => {
      if(resp.length===0){
        this.bandera = false;
      }else{
        this.bandera = true;
        this.galeria = resp[0];
        this.bandera2 = true;
      }
    })
  }
  crearGaleria(){
    this._galeriaService.crearGaleria()
      .subscribe( resp => {
        this.galeria = resp;        
        this.cargarGaleria();
      });
  }

  async subirImagen(archivo: File, id: string){
    this.progress=0;
    try{
      const url = URL_SERVICIOS + '/uploadgaleriamul/' + id;  
      const formData =  new FormData();
      formData.append( 'archivo', archivo, archivo.name );
      await this.http.put(url,formData, {
        reportProgress: true,
        observe: 'events'
      }).subscribe(event => {
        if(event.type === HttpEventType.UploadProgress){
          this.progress=Math.round(event.loaded / event.total * 100);
        }else if(event.type === HttpEventType.Response){
          if(this.progress === 100){
            this.progress=0;
            this.tiempo();
          }
          
        }
      });
      
    } catch(error){
      console.log(error);
      return false;
    }
  }
  
  /*async guardarContenido(enlace:Enlace){
    this.progress2=0;
    try{
      const url = URL_SERVICIOS + '/enlaceDocSubir/' + this.galeria._id;  
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };
      await this.http.put(url,enlace, {
        reportProgress: true,
        observe: 'events'
      }).subscribe(event => {
        //console.log(event);
        if(event.type === HttpEventType.UploadProgress){
          //console.log(Math.round(event.loaded / event.total * 100));
          this.progress2=Math.round(event.loaded / event.total * 100);
        }else if(event.type === HttpEventType.Response){
          //console.log(event);
          //console.log(HttpEventType.Response);
          if(this.progress2 === 100){
            this.progress2=0;
            this.tiempo();
            this.enlace=new Enlace();
          }
          
        }
      });
      
    } catch(error){
      console.log(error);
      return false;
    }
  }*/

  SubirGaleria(){
    this.subirImagen( this.imagenSubir, this.galeria._id );
  }



  tiempo(){
    setTimeout(() => {
      this.cargarGaleria();
      this.imagenSubir = null;
      this.imagenTemp = '';
      Swal.fire('Imagen Cargada', 'Se ha subido la imagen a tu galeria', 'success');    
    },100);
  }
  
}

import { Component, OnInit } from '@angular/core';
import { MaterialService, MateriaService } from '../../services/service.index';
import { ActivatedRoute } from '@angular/router';

import { Material } from '../../models/material.model';
import { Criterio } from '../../models/criterio.model';
//import { Personal } from '../../models/personal.model';
//import { Listas } from '../../models/listas.model';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-criterio',
  templateUrl: './criterio.component.html',
  styles: []
})
export class CriterioComponent implements OnInit {
  title: string;
  identificador: string;
  materiale: Material[]=[];
  public material= new Material('','','');
  //personas: Personal[] = []; 
  //miLista: Listas[] = [];
  //cargando: boolean = true;
  //ser : boolean = false;
  public criterio=new Criterio('','','');
  public crit=new Criterio('','','');
  public criterio2 : Criterio[] = [];
  

  constructor(
    public _materialService: MaterialService,
    //public router: Router,
    public activatedRoute: ActivatedRoute,
    //public _personalService: PersonalService,
    public _materiaService: MateriaService,
    //public _virtualService: VirtualService
  ) { 
    this.material= new Material('','','');
    this.title = "Criterios de Calificación";
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
    });
    this.criterio=new Criterio('','','');
    this.crit=new Criterio('','','');
  }

  ngOnInit() {
    this.cargarMaterial();
  }

  cargarMaterial(){
    this._materialService.cargarAulaId( this.identificador )
    .subscribe( (resp: any) => {
      this.material = resp.material;
      this.materiale = resp.material;
      //this.cargarEstudiantesCurso(resp.material['curso']['_id']);
      this.cargarCriterios();
    })
  }

  /*serFormulario(){
    this.ser = this.ser === false ? true : false;
  }*/

  /**cargarEstudiantesCurso(iden){
    this._personalService.cargarEstudianteCursoComplementario( iden )
        .subscribe( (resp: any) => {
          this.personas = resp.personal;
          for(let i=0;i<this.personas.length;i++){
            this.miLista.push({
              'user' : this.personas[i].user['_id'],
              'personal': this.personas[i]._id,
              'paterno' : this.personas[i].user['paterno'],
              'materno' : this.personas[i].user['materno'],
              'nombres' : this.personas[i].user['nombres'],              
              'estado' : this.personas[i].estado
            });
          }
          this.cargando = false;
        })
  }*/

  guardarCriterio( criterio: Criterio ){
    this.criterio.curso = this.material['curso']['_id'];
    this.criterio.materia = this.material['materia']['_id'];
    this.criterio.criterio = criterio.criterio;
    this.criterio.dimension = criterio.dimension;
    this.criterio.trimestre = criterio.trimestre;
    this._materiaService.crearCriterio( this.criterio ).subscribe( criterio => {  
      this.cargarCriterios();
    });
  }

  cargarCriterios(){
    this._materiaService.cargarCriterio( this.material['curso']['_id'], this.material['materia']['_id'] )
      .subscribe( (resp: any) => {
        this.criterio2 = resp;
      })
  }
  /*cargarNotas(id:string,dimension:string,trimestre:number){
    this.elemento =[];
    this.criteriocalificacion = [];
    console.log(id);
    console.log(trimestre);
    console.log(dimension);
    console.time();
    this.cargando2 = false;
    if(this.memoria !== id ){
      this._virtualService.consultarGeneradoNota( id, trimestre, dimension)
      .subscribe( (resp: any) => {
        console.log(resp);
        if(resp.length > 0){
          this.criteriocalificacion = resp;
          this.elemento = resp[0].tareatitulo;
          this.memoria = id;
          this.cargando2 = true;
          console.timeEnd();
        }else{
          this.elemento =[];
          this.criteriocalificacion = [];
          this.memoria = id;
          this.cargando2 = true;
        }        
      })
    }
    this.cargando2 = true;
  }*/

  eliminarCriterio(criterio: string){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de eliminar un Criterio de Evaluación. Podrias eliminar todo un grupo de notas evaluadas',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._materiaService.borrarCriterio( criterio )
          .subscribe( criterio => {
            this.cargarCriterios(); 
          });
      }
    })
  }

}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Curso } from '../../../models/curso.model';
import { CursoService } from '../../../services/service.index';

@Component({
  selector: 'app-cursos',
  templateUrl: './cursos.component.html',
  styles: [
  ]
})
export class CursosComponent implements OnInit {
  cursos: Curso[] = [];
  dtOptions: DataTables.Settings = {};
  visible : boolean;
  

  constructor(
    
    public _cursoService: CursoService,
    public router: Router
  ) {
    this.dtOptions = {
      pagingType: 'simple',
      pageLength: 10,
      language: {
        url: '//cdn.datatables.net/plug-ins/1.12.0/i18n/es-ES.json'
      },
      order: [[1, 'asc']]
    };
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple',
      pageLength: 10,
      language: {
        url: '//cdn.datatables.net/plug-ins/1.12.0/i18n/es-ES.json'
      },
      order: [[1, 'asc']]
    };
    this.cargarCurso();
  }
  cargarCurso(){
    this.visible = false;
    this._cursoService.cargCursos()
      .subscribe( (resp: any) => {
        this.cursos = resp;
        this.visible = true;
      })
  }
  
  verCentralizador(curso: string){
    this.router.navigate(['/plataforma/curso', curso]); 
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Curso } from '../../models/curso.model';
import { CursoService, PersonalService, UserService } from '../../services/service.index';
import { Personal } from '../../models/personal.model';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-curso-hijo',
  templateUrl: './curso-hijo.component.html',
  styles: []
})
export class CursoHijoComponent implements OnInit {
  identificador: string;
  public cursos: Curso[];
  limite: number = 0;
  desde: number = 0;
  totalRegistros: number = 0;
  public persona = new Personal('', '', '', '');
  public boton: boolean = false;
  public alumno: Personal = new Personal('','','','');
  public usuario = new User('','','','');
  

  constructor(
    public activatedRoute: ActivatedRoute,
    public _cursoService: CursoService,
    public _personalService: PersonalService,
    public _userService: UserService,
    public router: Router
  ) {
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
    });
   }

  ngOnInit() {
    this.usuario = this._userService.user;
    this.cargarPersona(this.identificador);
  }

  cargarCurso(){
    this._cursoService.cargarCursos( this.desde )
        .subscribe( (resp: any) => {
          this.totalRegistros = resp.cuantos;
          this.cursos = resp.curso;
        })
  }

  cargarPersona( id: string ){
    this._personalService.cargarEstudiante( id )
        .subscribe( (resp: any) => {
          let t = (resp.personal).length;          
          if( t === 0){
            this.boton = true;
            this.cargarCurso();
          }else{
            this.persona = resp.personal[0];
            this.boton = false;
          }
        })
  }

  registroCurso(id){
    this.alumno.user = this.identificador;
    this.alumno.institucion = this.usuario.institucion;
    this.alumno.role = '2';
    this.alumno.gestiones = {'cursos':id};
    this._personalService.crearAlumnno( this.alumno )
      .subscribe( resp => {
        this.router.navigate(['/ppff/lista-hijos']);
      });
  }
  cambiarDesde( valor: number ){
    let desde = this.desde + valor;
    if( desde >= this.totalRegistros ){
      return;
    }
    if( desde < 0 ){
      return;
    }
    this.desde += valor;
    this.cargarCurso();
  }
  
}

import { Component, OnInit } from '@angular/core';


import Swal from 'sweetalert2';
import { UserService, InstitucionService } from '../../../services/service.index';
import { User } from '../../../models/user.model';
import { Institucion } from '../../../models/institucion.model';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styles: []
})
export class ConfiguracionComponent implements OnInit {
  public imagenSubir: File;
  public imagenTemp: any;
  public usuario = new User('','','','');
  public institucion: Institucion[];

  constructor(
    public _userService: UserService,
    public _institucionService: InstitucionService,
  ) { }

  ngOnInit() {
    this.usuario = this._userService.user;
    console.log(this.usuario);
  }

  seleccionImage( archivo: File ){
    if(!archivo){
      this.imagenSubir = null;
      return;
    }
    if( archivo.type.indexOf('image') < 0 ){
      Swal.fire('Solo imagenes', 'El archivo seleccionado no es una imagen', 'error');
      this.imagenSubir = null;
      return;
    }

    this.imagenSubir = archivo;

    let reader = new FileReader();
    let urlImagenTemp = reader.readAsDataURL( archivo );

    reader.onloadend = () => this.imagenTemp = reader.result;
    /*reader.onloadend = function(){
      this.imagenTemp = reader.result;
    }*/
  }
  //corregir esta funcion
  cambiarImagen(){
    //this._userService.CambiarImagen( this.imagenSubir, this.usuario._id );
  }

}

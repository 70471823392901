import { Component, OnInit } from '@angular/core';
import { MaterialService, PersonalService, VirtualService } from '../../services/service.index';
import { Material } from '../../models/material.model';
import { Calificaciontarea } from '../../models/calificaciontarea.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Personal } from '../../models/personal.model';
import { Tareapresentada } from '../../models/tareapresentada.model';
import { Listas } from '../../models/listas.model';
import { Nota } from '../../models/nota.model';
import { Tareaemitida } from '../../models/tareaemitida.model';
import Swal from 'sweetalert2';
import { URL_SERVICIOS } from '../../config/config';
import {Location} from '@angular/common';

@Component({
  selector: 'app-revisiontarea',
  templateUrl: './revisiontarea.component.html',
  styles: []
})
export class RevisiontareaComponent implements OnInit {
  title:string;
  identificador: string;
  material: Material[]=[];
  personas: Personal[] = [];
  clase: string;
  tarea: string;
  respuestas: Tareapresentada[]=[];
  bandera:boolean=false;
  cero: number =  0;
  vacio: string = '';
  vac: boolean = false;
  lleno : boolean = false;
  latarea = new Tareaemitida('','');
  generado : boolean;
  carga : boolean;

  miLista: Listas[] = [];
  miLista2: Listas[] = [];
  miLista3: Listas[] = [];
  cargando: boolean = true;
  nota = new Nota();
  relleno : Listas[] = [];

  previsual: boolean = false;
  respuesta: Tareapresentada;
  direccion: string;
  page: number = 1;
  totalPages: number;
  isLoaded: boolean = false;
  contador : number = 0;
  blanco : boolean = false;
  nm: number;
  calificacion : Calificaciontarea[] = [];

  constructor(
    public _materialService: MaterialService,
    public activatedRoute: ActivatedRoute,
    public _personalService: PersonalService,
    public _virtualService: VirtualService,
    public router: Router,
    private _location: Location
  ) { 
    this.title = "Nomina";
    activatedRoute.params.subscribe( params => {
      this.identificador = params['id'];
      this.clase = params['virtual'];
      this.tarea = params['tarea'];
      this.nm = params['nota'];
      this.bandera=false;
    });
    this.latarea = new Tareaemitida('','');
  }

  ngOnInit() {
    this.consultarGenerado(this.tarea);
    this.cargarTarea(this.tarea);
    this.cargarTareaPresentada( this.clase, this.tarea );
    this.cargarDatos(this.identificador);    
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }

  cargarTarea( id: string ){
    this._virtualService.cargarLaTarea( id )
    .subscribe( (resp: any) => {
      this.latarea = resp.tarea[0];
    })
  }
  cargarDatos(id: string){
    this._materialService.cargarAulaId( id )
        .subscribe( (resp: any) => {
          this.material = resp.material;                
        })
  }
  consultarGenerado(tarea: string){
    this._virtualService.consultarGenerado( tarea )
        .subscribe( (resp: any) => {
          if(resp.length===0){
            this.generado = false;
          }else{
            this.generado = true;
          }
        })
  }

  mostrarPrevisual(id:string,contador:number){
    this.previsual === false ? this.previsual=true : this.previsual=false;
    this.cargarTareaPresentadaId(id);
    this.contador = contador;
    //console.log(this.contador);
  }
  siguienteEst(){
    this.contador = this.contador + 1;
    this.cargarTareaPresentadaId(this.miLista[this.contador]['tarea']);
    
    //console.log(this.contador);
  }

  atrasEst(){
    this.contador = this.contador - 1;
    this.cargarTareaPresentadaId(this.miLista[this.contador]['tarea']);
  }

  cargarTareaPresentadaId( id: string ){
    //console.log(id);
    if(id===''){
      this.blanco = false;
    }else{
      this._virtualService.unaTareaPresentada( id )
      .subscribe( (resp: any) => {
        this.respuesta= resp.tarea;  
        //console.log(this.respuesta);
        if(this.respuesta.entregado){
          if(this.respuesta.doc === ''){
            this.direccion = ' ';
            this.blanco = true;
          }else{
            this.direccion = URL_SERVICIOS+'/imagen/estudiante/'+this.respuesta.doc;
            this.blanco = true;
          }
        }else{
          this.blanco = false;
        }
        
      })
    }

        
  }
  calificarTareaId( tarea: Tareapresentada ){
    //this.cargando = true;
    //this.bandera = false;
    this.respuesta.comentario = tarea.comentario;
    this.respuesta.calificacion = tarea.calificacion;
    this._virtualService.agregarCalificacion(this.respuesta._id,tarea).subscribe( resp => {
    this.cargarTareaPresentada( this.clase, this.tarea );
      //this.backClicked();
    });
    //console.log(this.contador);
  }
 
  cargarEstudiantesCurso(id:string){
    this.miLista = [];
    this._personalService.cargarEstudianteCursoComplementario( id )
        .subscribe( (resp: any) => {
          this.personas = resp.personal;
          if(this.respuestas.length!==0){
            for(let i=0;i<this.personas.length;i++){
              this.lleno = false;
              for(let j=0;j<this.respuestas.length;j++){
                if(this.personas[i]._id === this.respuestas[j].personal['_id']){
                  this.lleno = true;               
                  this.miLista.push({
                    'user' : this.personas[i].user['_id'],
                    'personal': this.personas[i]._id,
                    'paterno' : this.personas[i].user['paterno'],
                    'materno' : this.personas[i].user['materno'],
                    'nombres' : this.personas[i].user['nombres'],              
                    'estado' : this.personas[i].estado,
                    'tarea'  : this.respuestas[j]._id,
                    'calificacion' : this.respuestas[j].calificacion,
                    'fecha' : this.respuestas[j].entregadofecha,
                    'doc' : this.respuestas[j].doc,
                    'oportunidad' :  this.respuestas[j].oportunidad,
                    'respuesta' : this.respuestas[j].respuesta,
                    'revisado' : this.respuestas[j].revisado,
                    'entregado' : this.respuestas[j].entregado
                  });
                }
              }
              if(this.lleno === false){ 
                this.miLista.push({
                  'user' : this.personas[i].user['_id'],
                  'personal': this.personas[i]._id,
                  'paterno' : this.personas[i].user['paterno'],
                  'materno' : this.personas[i].user['materno'],
                  'nombres' : this.personas[i].user['nombres'],              
                  'estado' : this.personas[i].estado,
                  'tarea'  : this.vacio,
                  'calificacion' : this.latarea.minimo,
                  'fecha' : this.vacio,
                  'doc' : this.vacio,
                  'oportunidad' :  this.vac,
                  'respuesta' : this.vacio,
                  'revisado' : this.vac,
                  'entregado' : this.vac
                });
              }          
            }
          }
          this.cargando = false;    
        })
  }

  cargarTareaPresentada( virtual: string, tarea: string ){
    this.miLista2 = [];
    this._virtualService.verTareapresentada( virtual, tarea )
    .subscribe( (resp: any) => {
      this.respuestas=resp.tarea;
      for(let i=0;i<this.respuestas.length;i++){
        this.miLista2.push({
          'user' : this.respuestas[i].personal['user']['_id'],
          'personal': this.respuestas[i].personal['_id'],
          'paterno' : this.respuestas[i].personal['user']['paterno'],
          'materno' : this.respuestas[i].personal['user']['materno'],
          'nombres' : this.respuestas[i].personal['user']['nombres'],              
          'estado' : this.respuestas[i].personal['estado'],
          'tarea'  : this.respuestas[i]._id,
          'calificacion' : this.respuestas[i].calificacion,
          'fecha' : this.respuestas[i].entregadofecha,
          'doc' : this.respuestas[i].doc,
          'oportunidad' :  this.respuestas[i].oportunidad,
          'respuesta' : this.respuestas[i].respuesta,
          'revisado' : this.respuestas[i].revisado,
          'entregado' : this.respuestas[i].entregado
        });
      }  
      this.cargarEstudiantesCurso(this.material['curso']._id);    
      this.bandera=true;
      this.duplicados();
    })    
  }

  proceso(){
    if(this.latarea['criterio']){
      this.carga = true;
      Swal.fire('Listo!', 'Se ha procedido con la petición', 'success');
      for(let i=0;i<this.personas.length;i++){
        this.calificacion.push({
          'personal': this.miLista[i].personal,
          'paterno': this.miLista[i].paterno,
          'materno': this.miLista[i].materno,
          'nombres': this.miLista[i].nombres,
          'tarea' : this.tarea,
          'tareatitulo' : this.latarea.titulo,
          'dimension' : this.latarea.criterio['dimension'][0],
          'trimestre' : this.latarea.criterio['trimestre'][0],
          'criterio' : this.latarea.criterio['_id'],
          'materia' : this.material['materia']._id,              
          'curso' : this.material['curso']._id,
          'calificacion' : this.miLista[i].calificacion,
        });
      }
      this._virtualService.generarCalificacion(this.calificacion).subscribe( resp => {
        this.carga = false;
        this.consultarGenerado(this.tarea);
      });
      this.carga = false;
    }else{
      Swal.fire('Ups!', '<p>No se puede generar la calificación.</p> <p>La tarea no tiene asignado un criterio de evaluación. Es necesario agregar la configuración requerida</p>', 'warning');
      this.backClicked();
    }
    
  }

  duplicados(){
    this.miLista3 = [];
    const busqueda = this.miLista2.reduce((acc, persona) => {
      acc[persona.personal] = ++acc[persona.personal] || 0;
      return acc;
    }, {});
    
    this.miLista3 = this.miLista2.filter( (persona) => {
      return busqueda[persona.personal];
    });
  }


  calificarTarea( idrev:string ){
    this.router.navigate(['/institucion/calificar-tarea', idrev, this.tarea, this.clase]);
  }
  resguardar(){
    Swal.fire({
      title: '¿Estas Seguro?',
      html: '<p>Estas a punto generar las calificaciones que se encuentran en blanco</p>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this.proceso();
      }
    })
  }
  

  unaOportunidad(tarea: string){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de brindar una oportunidad al estudiante',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._virtualService.unaOportunidad( tarea )
          .subscribe( tarea => {
            this.cargarTareaPresentada( this.clase, this.tarea );            
          });
      }
    })
  }

  eliminarTarea(tarea: string){
    Swal.fire({
      title: '¿Estas Seguro?',
      text: 'Estas a punto de eliminar la tarea seleccionada',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Si, estoy seguro!'
    }).then((result) => {
      if (result.value) {
        this._virtualService.borrarTareaEnviada( tarea )
          .subscribe( tarea => {
            this.cargarTareaPresentada( this.clase, this.tarea ); 
            this.cargarEstudiantesCurso(this.material['curso']._id); 
          });
      }
    })
  }

  backClicked() {
    this._location.back();
  }

}

import { Injectable } from '@angular/core';
import { Personal } from '../../models/personal.model';
import { HttpClient, HttpHeaders, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { URL_SERVICIOS } from '../../config/config';

import { map, take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { User } from '../../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class PersonalService {
  personal: Personal;
  token: string;
  user: User;
  persona: Personal;
  constructor(
    public http: HttpClient,
  ) { 
    this.cargarStorage();
    
  }

  cargarStorage(){
    if( localStorage.getItem('token') ){
      this.token = localStorage.getItem('token');
      this.user = JSON.parse( localStorage.getItem('usuario') );
      this.persona = JSON.parse( localStorage.getItem('personal') );
    }else{
      this.token = '';
    }
  }

  crearPersonal( personal: Personal){
    let url = URL_SERVICIOS + '/personal';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    
    return this.http.post(url, personal, httpOptions).pipe(map( (resp: any) => {
        Swal.fire('Excelente', 'Se ha registrado al tutor', 'success');
        return resp.personal;
      }));
  }

  crearAlumnno( personal: Personal){
    let url = URL_SERVICIOS + '/personalStudent';

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    
    return this.http.post(url, personal, httpOptions).pipe(map( (resp: any) => {
        Swal.fire('¡Muy bien!', 'Has registrado un estudiante', 'success');
        return resp.personal;
      }));
  }

  /*agregarSubtitulo( id:string, subtitulo : Subtitulo ){
    let url = URL_SERVICIOS + '/agregaSubtitulo/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.put(url, subtitulo, httpOptions).pipe(map( (resp: any) => {
      let usuarioDB: User = resp.usuario;
      Swal.fire('Subtitulo Agregado', subtitulo.subtitulo, 'success');
      return resp.virtual;
    }));
  }*/

  cargarPersonal( desde: number = 0 ){
    let institucion = this.user.institucion['_id'];
    let limite = 5;
    let url = URL_SERVICIOS + '/personal/'+institucion+'?limite='+limite+'&desde='+desde;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }

  cargarPersonalppff( desde: number = 0 ){
    let institucion = this.user.institucion['_id'];
    let limite = 5;
    let url = URL_SERVICIOS + '/personalppff/'+institucion+'?limite='+limite+'&desde='+desde;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }

  cargarEstudianteCurso( id: string ){
    let url = URL_SERVICIOS + '/personalEstudianteCurso/'+id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }

  cargarEstudianteCursoComplementario( id: string){
    let url = URL_SERVICIOS + '/personalEstudianteCursoComplementario/'+id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }
  cargarEstudianteCursoComplementariolistas( id: string, gestion: string ){
    let url = URL_SERVICIOS + '/personalEstudianteCursoComplementariolistas/'+id+'/'+gestion;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }

  darBajaTodos( id: string, gestion: string ){
    let url = URL_SERVICIOS + '/darBajaTodos/' + id + '/' +gestion;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.put( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Datos actualizados', 'Se ha dado de baja a todos los estudiantes del curso', 'success');
      return resp.ok;
    }));
  }

  CursoSuperior( id: string, gestion: string, curso: string){
    let url = URL_SERVICIOS + '/asignarCursoSuperior/' + id + '/' +gestion + '/' +curso;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.put( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Datos actualizados', 'Se ha dado de baja a todos los estudiantes del curso', 'success');
      return resp.ok;
    }));
  }

  cargarPersona( id: string ){
    let url = URL_SERVICIOS + '/personalPadre/'+id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }
  cargarProfe( id: string ){
    let url = URL_SERVICIOS + '/personalProfesor/'+id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }
  cargarPersonadesdeuser( id: string ){
    let url = URL_SERVICIOS + '/personalPadreuser/'+id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }
  cargarEstudiante( id: string ){
    let url = URL_SERVICIOS + '/personalEstudiante/'+id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };

    return this.http.get( url, httpOptions );
  }

  bajaPersonal( id: string ){
    //console.log(id);
    let url = URL_SERVICIOS + '/personalja/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.delete( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Personal dado de baja', 'La persona ha sido dada de baja', 'success');
      return true;
    }));
  }

  altaPersonal( id: string ){
    let url = URL_SERVICIOS + '/personalta/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.delete( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Personal dado de alta', 'La persona ha sido dada de alta', 'success');
      return true;
    }));
  }

  /*agregarHijo( hijo: string ){
    console.log(this.persona);
    let url = URL_SERVICIOS + '/agregaHijo/' + this.persona[0]._id + '/' + hijo;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.put( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Hij@ cread@', 'Agregaste hij@ a tu registro', 'success');
      return resp.personal;
    }));
  }*/

  borrarHijo( persona:string, id: string, hijo: string ){
    let url = URL_SERVICIOS + '/borrarHijo/' + persona + '/' + id + '/' + hijo;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    return this.http.delete( url, httpOptions ).pipe(map( (resp: any) => {
      Swal.fire('Se ha eliminado', 'Registro seleccionado eliminado', 'success');
      return true;
    }));
  }
  modificarImagen( id: string, datos: any ){
    let url = URL_SERVICIOS + '/uploadusruario2/' + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.token
      })
    };
    
    return this.http.put( url, datos, {
      reportProgress: true, 
      observe: 'events'
    }).pipe(map( (resp) => {
      switch(resp.type){
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * resp.loaded / resp.total);
          return progress;
        case HttpEventType.Response:
          return resp.body;
        default: `se ha producido un evento: ${resp.type}`;
      }  
    }));
  }
}

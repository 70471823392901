import { Component, OnInit } from '@angular/core';

declare function customInitFunction();
declare function init_plugins();

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styles: []
})
export class UsuarioComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    customInitFunction();
    init_plugins();
  }

}
